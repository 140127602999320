// src/pages/ISearch/index.js

import React, { useRef } from "react";
import SearchBar from "../../components/iSearchBar.jsx";
import FrequentlySearched from "../../components/FrequentlySearched.jsx";

function ISearch() {
  const searchBarRef = useRef(null);
  
  return (
    <div className="min-h-screen bg-[#fff9f7] text-gray-800">
      {/* Hero Section */}
      <section className="bg-emerald-100 py-16 flex items-center justify-center relative">
      
      {/* Disclaimer Section */}
      <div className="bg-yellow-200 text-yellow-800 text-center py-2 px-4">
        <p className="text-sm font-semibold">
          Information on this webpage is at pilot stage and not officially approved. We are actively working to secure necessary approvals.
        </p>
      </div>
{/** Disclaimer Stops here */}
        <div className="container mx-auto px-6 absolute bottom-4 text-center">
          <h1 className="text-3xl font-bold text-green-600">
            Job Search Made Easy
          </h1>
        </div>
      </section>

        {/* Main Content */}
        <div className="flex flex-col items-center p-5 min-h-screen">
        <div className="w-full max-w-7xl">
      <div className="flex flex-col lg:flex-row items-start justify-between gap-6">
        {/* Search Bar Section */}
        <div className="w-full lg:w-3/4">
          <SearchBar ref={searchBarRef} />
        </div>

        {/* Frequently Searched Section */}
        <div className="w-full lg:w-1/4 p-4 border-2 border-gray-200 rounded-lg">
          <FrequentlySearched
            triggerSearch={(keyword) =>
              searchBarRef.current?.handleTriggerSearch(keyword)
            }
          />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ISearch;