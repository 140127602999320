// src/pages/talhunt/ManualSearch.jsx

// import React, { useState } from 'react';
import React from 'react';

const ManualSearch = ({ 
  searchParams, 
  setSearchParams, 
  handleSearch, 
  isAdvancedSearch, 
  toggleSearchMode,
  setFilteredProfiles,
  originalProfiles,
}) => {

  // const [showTooltip, setShowTooltip] = useState(false);

  // Handle changes in search parameters
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className='p-4 border rounded-lg'>
      {/* Parent Grid */}
      <div className='grid grid-cols-1 gap-4 md:grid-cols-11 lg:grid-cols-11'>
        {/* Container 1 */}
        <div
          className={`p-4 border rounded bg-gray-100 ${
            isAdvancedSearch 
            ? 'md:col-span-5 lg:col-span-5' 
            : 'md:col-span-10 lg:col-span-10'
          }`}
        >
          {/* First Row */}
          <div className='mb-4 relative'>
            <label className='block text-sm font-medium text-gray-700 mb-1'>
              Keyword Search
{/*              
              // Tooltip
                <span
                  className='ml-2 text-blue-500 cursor-pointer'
                  onClick={() => setShowTooltip(true)}
                  >
                  ❓
                </span>
*/}
                </label>
                <input
                  type='text'
                  name='keyword'
                  value={searchParams.keyword}
                  onChange={handleChange}
                  placeholder='Enter keywords'
                  className='p-1 border rounded w-full md:w-3/4 lg:w-full'
                  style={{ fontSize: '0.875rem' }}
                />
{/*
                {showTooltip && (
                  <div className='absolute top-12 left-0 z-10 w-full md:w-[400px] p-4 bg-white border border-gray-300 rounded shadow-lg max-h-64 overflow-auto'>
                    <button
                      className='absolute top-1 right-2 text-gray-500 hover:text-red-500'
                      onClick={() => setShowTooltip(false)}
                    >
                      ✖
                    </button>
                  <p className='font-bold'>How to Use Keyword Search:</p>
                  <ul className='list-disc pl-4 mt-2 text-sm text-gray-700'>
                    <li>
                      Use <span className='font-bold'>commas (,)</span> to 
                      separate multiple generic keywords.<br />
                      <span className='text-blue-600'>Example:</span>{' '}
                      manager, developer, node.js
                    </li>
                    <li className='mt-2'>
                      Use <span className='font-bold'>double quotes (" ")</span>{' '} 
                      for exact keyword matches.
                      <br />
                      <span className='text-blue-600'>Example:</span> "manager", "react"
                    </li>
                    <li className='mt-2'>
                      Use <span className='font-bold'>colons (:)</span> for hierarchical
                      searches.<br />
                      <span className='text-blue-600'>Example:</span> "manager" : "website" : 
                      react, node, vue
                    </li>
                  </ul>
                  <p className='mt-3 font-bold'>Example Use Case:</p>
                  <p className='text-sm text-gray-700'>
                    To find candidates who is currently a <span className='font-bold'>"manager"</span>
                    (exact match), have expertise in <span className='font-bold'>"website"</span> development
                    (exact match), has used frameworks such as <span className='font-bold'>"react"</span> 
                    (exact match), and optionally worked with <span className='font-bold'>node.js</span> or <span className='font-bold'>vue.js</span>:
                  </p>
                  <p className='mt-2 text-blue-600 text-sm'>
                    Input: "manager" : "website" : "react", node, vue
                  </p>
                  <p className='mt-2 text-green-600 text-sm'>
                    Results: Shows candidates meeting all specified conditions.
                  </p>
                </div>
              )}
*/}
          </div>

          {/* Second Row */}
          {/* Overall Experience */}
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Overall Experience
              </label>
              <div className='flex gap-2'>
                <input
                  type='number'
                  name='minOverallExp'
                  value={searchParams.minOverallExp}
                  onChange={handleChange}
                  placeholder='Min Yrs'
                  min='0'
                  className='p-1 border rounded w-full'
                  style={{ fontSize: '0.875rem' }}
                />
                <input
                  type='number'
                  name='maxOverallExp'
                  value={searchParams.maxOverallExp}
                  onChange={handleChange}
                  placeholder='Max Yrs'
                  min='0'
                  className='p-1 border rounded w-full'
                  style={{ fontSize: '0.875rem' }}
                />
              </div>
            </div>

            {/* Notice Period */}
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Notice Period
              </label>
              <input
                type='number'
                name='maxNoticePeriod'
                value={searchParams.maxNoticePeriod}
                onChange={handleChange}
                placeholder='Max Days'
                min='0'
                className='p-1 border rounded w-full'
                style={{ fontSize: '0.875rem' }}
              />
            </div>

            {/* Location */}
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Location
              </label>
              <input
                type='text'
                name='location'
                value={searchParams.location}
                onChange={handleChange}
                placeholder='Enter location'
                className='p-1 border rounded w-full'
                style={{ fontSize: '0.875rem' }}
              />
            </div>
          </div>
        </div>

        {/* Container 2 (Visible only in Advanced Search) */}
        {isAdvancedSearch && (
          <div className='p-4 border rounded bg-gray-100 md:col-span-5 lg:col-span-5'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Custom Role Search
              </label>
              <div className='grid grid-cols-4 gap-2'>
                <input
                  type='text'
                  name='customRole'
                  value={searchParams.customRole}
                  onChange={handleChange}
                  placeholder='Search custom role'
                  className='p-1 border rounded w-full col-span-2'
                  style={{ fontSize: '0.875rem' }}
                />
                <input
                  type='number'
                  name='minCustomExp'
                  value={searchParams.minCustomExp}
                  onChange={handleChange}
                  placeholder='Min Yrs'
                  min='0'
                  className='p-1 border rounded w-full col-span-1'
                  style={{ fontSize: '0.875rem' }}
                />
                <input
                  type='number'
                  name='maxCustomExp'
                  value={searchParams.maxCustomExp}
                  onChange={handleChange}
                  placeholder='Max Yrs'
                  min='0'
                  className='p-1 border rounded w-full col-span-1'
                  style={{ fontSize: '0.875rem' }}
                />
              </div>
            </div>

            {/* Second Row */}
            <div className='grid grid-cols-1 sm:grid-cols-12 gap-4 mt-4'>

              {/* Education */}
              <div className='sm:col-span-4'>
                <label className='block text-sm font-medium text-gray-700 mb-1'>
                  Education
                </label>
                <input
                  type='text'
                  name='education'
                  value={searchParams.education}
                  onChange={handleChange}
                  placeholder='Enter education'
                  className='p-1 border rounded w-full'
                  style={{ fontSize: '0.875rem' }}
                />
              </div>

              {/* Graduation Year */}
              <div className='sm:col-span-2'>
                <label className='block text-sm font-medium text-gray-700 mb-1'>
                  Cutoff
                </label>
                <input
                  type='number'
                  name='cutoffGradYear'
                  value={searchParams.cutoffGradYear}
                  onChange={handleChange}
                  placeholder='Year'
                  min='2000'
                  className='p-1 border rounded w-full'
                  style={{ fontSize: '0.875rem' }}
                />
              </div>

              {/* Language */}
              <div className='sm:col-span-3'>
                <label className='block text-sm font-medium text-gray-700 mb-1'>
                  Language
                </label>
                <input
                  type='text'
                  name='language'
                  value={searchParams.language}
                  onChange={handleChange}
                  placeholder='Enter language'
                  className='p-1 border rounded w-full'
                  style={{ fontSize: '0.875rem' }}
                />
              </div>

              {/* Shift Options */}
              <div className='sm:col-span-3'>
                <label className='block text-sm font-medium text-gray-700 mb-1'>
                  Shift
                </label>
                <select
                  name='shiftPreference'
                  value={searchParams.shiftPreference}
                  onChange={handleChange}
                  className='p-1 border rounded w-full'
                >
                  <option value=''>Select</option>
                  <option value='Day Shift'>Day</option>
                  <option value='Night Shift'>Night</option>
                  <option value='Rotational Shift'>Rotation</option>
                  <option value='Flexible Timings'>Flexible</option>
                  <option value='Any Shift'>Any</option>
                </select>
              </div>
            </div>
          </div>
        )}

        {/* Container 3 */}
        <div className='md:col-span-1 lg:col-span-1 p-0 rounded flex flex-col items-center justify-between'>
          {/* Toggle Switch */}
          <div className='flex flex-col items-center mb-0'>
            <span className='text-sm mr-0'>
              {isAdvancedSearch ? 'Advanced' : 'Basic'}
            </span>
            <div
              className={`relative w-12 h-6 rounded-full transition duration-300 ${
                isAdvancedSearch ? 'bg-blue-500' : 'bg-gray-300'
              }`}
              onClick={toggleSearchMode}
            >
              <div
                className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full shadow-md transition duration-300 ${
                  isAdvancedSearch ? 'translate-x-6' : ''
                }`}
              ></div>
            </div>
          </div>

          {/* Clear Button */}
          <button
            onClick={() => {
              setSearchParams({
                keyword: '',
                customRole: '',
                minCustomExp: '',
                maxCustomExp: '',
                location: '',
                minOverallExp: '',
                maxOverallExp: '',
                maxNoticePeriod: '',
                shiftPreference: '',
                education: '',
                cutoffGradYear: '',
                language: '',
              });
              setFilteredProfiles(originalProfiles);
            }}
            className='mt-2 px-4 py-1 bg-red-500 text-white rounded hover:bg-red-600'
          >
            Clear
          </button>

          {/* Search Button */}
          <button
            onClick={handleSearch}
            className='mt-2 px-4 py-1 bg-emerald-500 text-white rounded hover:bg-emerald-600'
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualSearch;