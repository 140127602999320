// src/pages/Landingpage/StatsPanel.jsx

import React, { useEffect, useState } from "react";
import Calendar3 from "../../Images/30D.png";
import Calendar1 from "../../Images/3D.png";
import Calendar2 from "../../Images/7D.png";
import Megaphone from "../../Images/Commercial.png";
import Permanent from "../../Images/Permanent Job.png";

const StatsPanel = () => {
  const [dashboardData, setDashboardData] = useState({
    stats: {
      totalCompanies: 0,
      totalJobs: 0,
      totalJobs_3D: 0,
      totalJobs_7D: 0,
      totalJobs_15D: 0,
      totalJobs_30D: 0,
    },
    jobsByLocation: [],
    jobsByExperience: [],
    jobsByIndustry: [],
    jobsByFunction: [],
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState("totalJobs"); // Default time range

  useEffect(() => {
    // Fetch the dashboard data from the backend
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jobs/dashboard`);
        const data = await response.json();
        setDashboardData(data || {});
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  const stats = [
    {
      icon: (
        <img
          src={Permanent}
          alt="Permanent Job"
          className="w-15 h-15 text-#049c64"
        />
      ),
      value: dashboardData.stats.totalCompanies,
      label: "Companies Listed",
    },
    {
      icon: (
        <img
          src={Megaphone}
          alt="Job Ads"
          className="w-15 h-15 text-#049c64"
        />
      ),
      value: dashboardData.stats.totalJobs,
      label: "Jobs Listed: All",
    },
    {
      icon: (
        <img
          src={Calendar1}
          alt="Job Ads (3 Days)"
          className="w-15 h-15 text-#049c64"
        />
      ),
      value: dashboardData.stats.totalJobs_3D,
      label: "Jobs Listed: 3 Days",
    },
    {
      icon: (
        <img
          src={Calendar2}
          alt="Job Ads (7 Days)"
          className="w-15 h-15 text-#049c64"
        />
      ),
      value: dashboardData.stats.totalJobs_7D,
      label: "Jobs Listed: 7 Days",
    },
    {
      icon: (
        <img
          src={Calendar3}
          alt="Job Ads (30 Days)"
          className="w-10 h-3.5 md:w-12 md:h-6 lg:w-12 lg:h-8"
        />
      ),
      value: dashboardData.stats.totalJobs_30D,
      label: "Jobs Listed: 30 Days",
    },
  ];

  const jobsByLocation = dashboardData.jobsByLocation?.map((job) => ({
    location: job.location || "Unknown",
    count: job[selectedTimeRange] || 0,
  })) || [];

  const jobsByExperience = dashboardData.jobsByExperience?.map((job) => ({
    exp: job.experienceRange || "Unknown",
    count: job[selectedTimeRange] || 0,
  })) || [];

  const jobsByIndustry = dashboardData.jobsByIndustry?.map((job) => ({
    industry: job.industry || "Unknown",
    count: job[selectedTimeRange] || 0,
  })) || [];

  const jobsByFunction = dashboardData.jobsByFunction?.map((job) => ({
    function: job.function || "Unknown",
    count: job[selectedTimeRange] || 0,
  })) || [];

  return (
    <div>
      {" "}
      <div className="relative z-40 w-full bg-[#fff9f7] pb-5">
        <div className="flex overflow-x-auto sm:grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 border-b border-gray-300 shadow-lg py-3">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-40 sm:w-auto bg-[#fff9f7] bg-opacity-90 backdrop-blur-sm p-3 sm:p-4 md:p-5 lg:p-6 flex flex-col items-center justify-center hover:shadow-xl transition-shadow duration-300"
            >
              <div className="mb-2 sm:mb-3 md:mb-4 lg:mb-4 text-xs sm:text-base md:text-lg lg:text-xl">
                <div className="w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 lg:w-12 lg:h-8">
                  {stat.icon}
                </div>
              </div>
              <div className="text-[#049c64] text-sm font-bold mb-1 sm:mb-2 md:mb-3 lg:mb-4">
                {stat.value}
              </div>
              <div className="text-[#979797] text-xs text-center font-medium">
                {stat.label}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full mx-auto pt-5 px-4 bg-[#fff9f7] z-30">
        {/* Stats cards overlaid on waves */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-5 gap-4 bg-[#fff9f7] z-30 shadow-sm border border-gray-300 py-4 px-2 shadow-xl rounded-lg">
          {/* Left Column - 2 tables */}
          <div className="space-y-4">
            {/* Jobs by Location */}
            <div className="flex flex-col bg-[#fff9f7] rounded-lg shadow-sm border border-gray-300 hover:shadow-md transition-shadow duration-200">
              <div className="flex items-center justify-between p-4">
                <h2 className="text-xs font-medium">Number of Jobs by Location</h2>
                <select
                  className="border border-gray-300 rounded-md px-2 py-1 text-xxxs text-gray-700 bg-[#fff9f7]"
                  onChange={(e) => setSelectedTimeRange(e.target.value)}
                >
                  <option value="totalJobs">All</option>
                  <option value="totalJobs_3D">3 Days</option>
                  <option value="totalJobs_7D">7 Days</option>
                  <option value="totalJobs_15D">15 Days</option>
                  <option value="totalJobs_30D">30 Days</option>
                </select>
              </div>
              <div className="grid grid-cols-4 gap-4 px-2 pb-4 bg-[#fff9f7]">
                {jobsByLocation.map((job, index) => (
                  <div
                    key={index}
                    className={`flex flex-col items-center justify-center p-1 border-l-2 border-black ${
                      (index + 1) % 4 === 0 ? "border-r-2" : "" // Adds right border to the last item in each row
                    }`}
                  >
                    <span className="text-xxxs lg:text-xxxs font-medium">
                      {job.location}: {job.count}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Jobs by Experience */}
            <div className="flex flex-col bg-[#fff9f7] rounded-lg  border border-gray-300 hover:shadow-md transition-shadow duration-200">
              <div className="flex items-center justify-between px-4 py-6">
                <h2 className="text-xs font-medium  xl:text-sm">
                  Number of Jobs by Experience
                </h2>
                <select 
                  className="border border-gray-300 rounded-md p-1 text-xxxs text-gray-700 bg-[#fff9f7]"
                  onChange={(e) => setSelectedTimeRange(e.target.value)}
                >
                  <option value="totalJobs">All</option>
                  <option value="totalJobs_3D">3 Days</option>
                  <option value="totalJobs_7D">7 Days</option>
                  <option value="totalJobs_15D">15 Days</option>
                  <option value="totalJobs_30D">30 Days</option>
                </select>
              </div>
              <div className="px-4 pb-4 bg-[#fff9f7]">
                <div className="w-full border-b border-gray-300">
                  <div className="grid grid-cols-2 bg-sky-950 text-white rounded-t-lg text-xxs xl:text-xs">
                    <div className="p-3 font-medium text-left">Experience</div>
                    <div className="p-3 font-medium pl-12 text-center">
                      No. of Job Ads
                    </div>
                  </div>
                  <div className="divide-y divide-gray-200">
                    {jobsByExperience.map((item, index) => (
                      <div
                        key={index}
                        className={`grid grid-cols-2 hover:bg-gray-50 ${
                          index % 2 === 0 ? "bg-white" : "bg-[#fff9f7]"
                        }`}
                      >
                        <div className="p-2 text-xxs text-left xl:text-xxs">
                          {item.exp}
                        </div>
                        <div className="p-2 text-xxs pl-12 text-center xl:text-xxs">
                          {item.count}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Jobs by Industry */}
          <div className="flex flex-col bg-fff9f7 rounded-lg shadow-sm border border-gray-300 hover:shadow-md transition-shadow duration-200">
            <div className="flex items-center justify-between p-4">
              <h2 className="text-xs  xl:text-sm font-medium">
                Number of Jobs by Industry
              </h2>
              <select 
                className="border border-gray-300 rounded-md p-1 text-xxxs text-gray-700 bg-[#fff9f7]"
                onChange={(e) => setSelectedTimeRange(e.target.value)}
              >
                <option value="totalJobs">All</option>
                <option value="totalJobs_3D">3 Days</option>
                <option value="totalJobs_7D">7 Days</option>
                <option value="totalJobs_15D">15 Days</option>
                <option value="totalJobs_30D">30 Days</option>
              </select>
            </div>
            <div className="px-4 pb-4 bg-[#fff9f7]">
              <div className="w-full border-b border-gray-300">
                <div className="grid grid-cols-2 bg-sky-950 text-white rounded-t-lg text-xxs xl:text-xs">
                  <div className="p-3 font-medium text-left ">Industry</div>
                  <div className="p-3 font-medium text-center pl-12">
                    No. of Job Ads
                  </div>
                </div>
                <div className="divide-y divide-gray-200">
                  {jobsByIndustry.map((item, index) => (
                    <div
                      key={index}
                      className={`grid grid-cols-2 hover:bg-gray-50 ${
                        index % 2 === 0 ? "bg-white" : "bg-[#fff9f7]"
                      }`}
                    >
                      <div className="p-2.5 text-xxs xl:text-xxs text-left">
                        {item.industry}
                      </div>
                      <div className="p-2.5 text-xxs xl:text-xxs text-center pl-12">
                        {item.count}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Jobs by Function */}
          <div className="flex flex-col bg-fff9f7 rounded-lg shadow-sm border border-gray-300 hover:shadow-md transition-shadow duration-200">
            <div className="flex items-center justify-between p-4">
              <h2 className="text-xs xl:text-sm font-medium ">
                Number of Jobs by Function
              </h2>
              <select
                className="border border-gray-300 rounded-md p-1 text-xxxs text-gray-700 bg-[#fff9f7] text-xxs"
                onChange={(e) => setSelectedTimeRange(e.target.value)}
                >
                  <option value="totalJobs">All</option>
                  <option value="totalJobs_3D">3 Days</option>
                  <option value="totalJobs_7D">7 Days</option>
                  <option value="totalJobs_15D">15 Days</option>
                  <option value="totalJobs_30D">30 Days</option>
              </select>
            </div>
            <div className="px-4 pb-4 bg-[#fff9f7]">
              <div className="w-full border-b border-gray-300">
                <div className="grid grid-cols-2 bg-sky-950 text-white rounded-t-lg text-xxs xl:text-xs">
                  <div className="p-3 font-medium text-left">Function</div>
                  <div className="p-3 font-medium text-center pl-12">
                    No. of Job Ads
                  </div>
                </div>
                {/* Add a scrollable container */}
                <div
                  className="divide-y divide-gray-200 max-h-[300px] overflow-y-auto"
                >
                  {jobsByFunction
                    .sort((a, b) => b.count - a.count) // Sort in descending order by count
                    .slice(0, 20) // Limit to 20 items
                    .map((item, index) => (
                    <div
                      key={index}
                      className={`grid grid-cols-2 hover:bg-gray-50 ${
                        index % 2 === 0 ? "bg-white" : "bg-[#fff9f7]"
                      }`}
                    >
                      <div className="p-2.5 text-xxs xl:text-xxs text-left">
                        {item.function}
                      </div>
                      <div className="p-2.5 text-xxs xl:text-xxs text-center pl-12">
                        {item.count}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsPanel;