// src/context/UserContext.jsx

import React, { createContext, useState, useEffect, useMemo, useCallback } from "react";
// import { useNavigate } from "react-router-dom";

export const UserContext = createContext();

export const UserProvider = React.memo(({ children }) => {
  
    const [state, setState] = useState(() => {
      const token = localStorage.getItem("userToken");
  
    const email = localStorage.getItem("email");
    const role = localStorage.getItem("role") || "user";
    const profileImage = localStorage.getItem("profileImage");
    
    return {
      isLoggedIn: !!token,
      userData: email ? { email, profileImage } : null,
      role,
      loading: true,
    };
  });

  // Simulate async loading on app start
  useEffect(() => {
    const token = localStorage.getItem("userToken");
    const email = localStorage.getItem("email");
    const role = localStorage.getItem("role") || "user";
    const profileImage = localStorage.getItem("profileImage");

    if (token && email) {
      setState((prevState) => ({
        ...prevState,
        isLoggedIn: true,
        userData: { email, profileImage },
        role,
        loading: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoggedIn: false,
        userData: null,
        role: null,
        loading: false,
      }));
    }
  }, []);

  const login = useCallback((token, email, profileImage, role) => {
    localStorage.setItem("userToken", token);
    localStorage.setItem("email", email);
    localStorage.setItem("role", role);
    if (profileImage) {
      localStorage.setItem("profileImage", profileImage);
    }

    setState({
      isLoggedIn: true,
      userData: { email, profileImage },
      role,
      loading: false,
    });
  }, []);

  const logout = useCallback(() => {
    localStorage.clear();
    setState({
      isLoggedIn: false,
      userData: null,
      role: null,
      loading: false,
    });
  }, []);

  const contextValue = useMemo(() => ({ ...state, login, logout }), [state, login, logout]);
  
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
});