import React from 'react';
import { User, AlertCircle, Info } from 'lucide-react';

const PersonalDetails = ({
  editable,
  editData,
  profile,
  handleChange,
  setEditData,
  setErrorMessages,
}) => {
  const fields = [
    { label: 'First Name', field: 'firstName', editable: true, required: true },
    { label: 'Last Name', field: 'lastName', editable: true, required: true },
    { label: 'Year of Birth', field: 'dob', editable: true, required: true },
    { label: 'Age', field: 'age', editable: false },
    { label: 'Email Address', field: 'email', editable: false },
    { label: 'Phone Number', field: 'mobile', editable: false },
    { label: 'Gender', field: 'gender', editable: true },
    { label: 'Marital Status', field: 'maritalStatus', editable: true },
  ];

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-base'>
          <User className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600'>Personal Information</span>
        </h3>
        {/*
          <div>
            <button 
              className='flex items-center gap-2 px-2 py-1 text-white text-xs bg-[#049c64] rounded-md'
              onClick={handleEditToggle}
              >
              <img src={pen} alt='Edit' className='w-2 h-2' />
                {editable ? 'Cancel' : 'Edit'}
            </button>
          </div>
*/}
      </div>

      <div className='py-4 rounded-lg relative sm:p-4'>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-6'>
          {fields.map(({ label, field, editable: isEditable, required }) => (
            <div key={field} className='relative group'>
              <p className='text-xs text-gray-600 mb-1 flex items-center gap-2'>
                {label} {required && <span className="text-red-500">*</span>}
                {field === 'age' && (
                  <span className='relative group'>
                    <Info className='w-4 h-4 text-gray-400 cursor-pointer' />
                    {/* Tooltip */}
                    <span className='absolute hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md -top-8 -right-10 w-56'>
                      Age will be automatically updated once the Year of Birth is updated and saved.
                    </span>
                  </span>
                )}
              </p>
              {isEditable ? (
                field === 'dob' ? (
                  // Dropdown for Year of Birth
                    <select
                      name={field}
                      data-parent-key='personalDetails'
                      value={
                        typeof editData.personalDetails?.dob === 'number'
                          ? editData.personalDetails?.dob
                          : ''
                      }
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (value >= 1960 && value <= new Date().getFullYear()) {
                          setEditData((prevState) => ({
                            ...prevState,
                            personalDetails: {
                              ...prevState.personalDetails,
                              dob: value, // Store as year
                            },
                          }));
                          setErrorMessages([]); // Clear errors if valid
                        } else {
                          setErrorMessages([
                            'Year of Birth must be between 1960 and the current year.',
                          ]);
                        }
                      }}
                      disabled={!editable}
                      className={`font-medium text-xs p-1 border ${
                        editable ? 'border-gray-300' : 'border-transparent'
                      }`}
                    >
                      <option value=''>Select</option>
                      {Array.from(
                        { length: new Date().getFullYear() - 1960 + 1 },
                        (_, i) => 1960 + i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                ) : field === 'gender' ? (
                  // Dropdown for Gender
                  <select
                    name={field}
                    data-parent-key='personalDetails'
                    value={
                      editable
                        ? editData.personalDetails?.[field] || ''
                        : profile.personalDetails?.[field] || ''
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setEditData((prevState) => ({
                        ...prevState,
                        personalDetails: {
                          ...prevState.personalDetails,
                          [field]: value,
                        },
                      }));
                      setErrorMessages([]);
                    }}
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? 'border-gray-300' : 'border-transparent'
                    }`}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Trans'>Trans</option>
                  </select>
                ) : field === 'maritalStatus' ? (
                  // Dropdown for Marital Status
                  <select
                    name={field}
                    data-parent-key='personalDetails'
                    value={
                      editable
                        ? editData.personalDetails?.[field] || ''
                        : profile.personalDetails?.[field] || ''
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setEditData((prevState) => ({
                        ...prevState,
                        personalDetails: {
                          ...prevState.personalDetails,
                          [field]: value,
                        },
                      }));
                      setErrorMessages([]);
                    }}
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? 'border-gray-300' : 'border-transparent'
                    }`}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    <option value='Single'>Single</option>
                    <option value='Married'>Married</option>
                    <option value='Divorced'>Divorced</option>
                    <option value='Widowed'>Widowed</option>
                    <option value='Separated'>Separated</option>
                  </select>
                ) : (
                  // Default input for other editable fields
                  <input
                    type='text'
                    name={field}
                    data-parent-key='personalDetails'
                    value={
                      editable
                        ? editData.personalDetails?.[field] || ''
                        : profile.personalDetails?.[field] || ''
                    }
                    onChange={handleChange}
                    placeholder={`Enter ${label}`}
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? 'border-gray-300' : 'border-transparent'
                    }`}
                  />
                )
              ) : (
                // Display value for non-editable fields
                <p className='font-medium text-xs'>
                  {field === 'dob'
                    ? typeof profile.personalDetails?.dob === 'number'
                      ? new Date().getFullYear() - profile.personalDetails.dob // Calculate Age from Year
                      : profile.personalDetails?.dob
                      ? (() => {
                          const dobDate = new Date(profile.personalDetails.dob);
                          const today = new Date();
                          let age = today.getFullYear() - dobDate.getFullYear();
                          const monthDiff = today.getMonth() - dobDate.getMonth();
                          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
                            age--; // Adjust if birthday hasn't occurred yet
                          }
                          return age;
                        })()
                      : 'Not Available' // Default if dob is invalid or not set
                    : field === 'email'
                    ? profile.email || 'Not Applicable'
                    : field === 'mobile'
                    ? profile.mobile || 'Not Applicable'
                    : profile.personalDetails?.[field] || 'Not Applicable'}

                  {(field === 'email' || field === 'mobile') && (
                    <span className='text-blue-500 ml-2 cursor-pointer relative group'>
                      <AlertCircle className='inline w-4 h-4' />
                      {/* Tooltip */}
                      <span className='absolute bottom-full left-1/2 transform -translate-x-1/2 translate-y-[-8px] bg-gray-800 text-white text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-10'>
                        Please contact support to update this information.
                      </span>
                    </span>
                  )}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;