import React, { useEffect, useRef, useState } from 'react';
import { Briefcase, Info } from 'lucide-react';

const JobPreferences = ({ editable, editData, profile, handleChange, setEditData }) => {
  const shiftOptions = [
    'Day Shift',
    'Night Shift',
    'Rotational Shift',
    'Flexible Timings',
    'Any Shift',
  ];

  const employmentTypeOptions = [
    'Full-time Jobs',
    'Part-time Jobs',
    'Freelancer Jobs',
    'Contract Jobs',
    'Internship',
    'Temporary Jobs',
  ];

  const preferredRolesRef = useRef(null);
  const preferredLocationsRef = useRef(null);

  const adjustHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto'; // Reset to default height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height dynamically
    }
  };

  useEffect(() => {
    adjustHeight(preferredRolesRef.current);
    adjustHeight(preferredLocationsRef.current);
  }, [
    editable,
    editData.jobPreferences?.preferredRoles,
    editData.jobPreferences?.preferredLocations,
  ]);

  const [preferredRolesLength, setPreferredRolesLength] = useState(
    editData.jobPreferences?.preferredRoles?.length || 0
  );

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-base'>
          <Briefcase className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600'>Job Preferences</span>
        </h3>
      </div>

      <div className='py-4 rounded-lg relative sm:p-4'>
        <div 
          className='grid grid-cols-1 sm:grid-cols-[2fr_2fr_2fr_2fr_4fr] gap-4 sm:gap-6'
          // Tailwind's sm:grid-cols-[...] ensures the custom grid applies only for large screens
        >
          {/* Grouped Common Fields */}
            {['employmentType', 'shiftPreference', 'currentCity', 'willingnessToRelocate'].map((field) => (
              <div
                key={field}
                className='col-span-1 sm:col-span-1' // Ensure one field per row on small screens
              >
                <p className='text-xs text-gray-600 mb-1 flex items-center gap-2'>
                {field === 'employmentType'
                    ? 'Employment Type'
                    : field === 'shiftPreference'
                    ? 'Shift Preference'
                    : field === 'currentCity'
                    ? 'Current City'
                    : 'Willingness to Relocate'}
                  {field === 'currentCity' && (
                    <span className='group relative'>
                      <Info className='w-4 h-4 text-gray-400 cursor-pointer' />
                      <span className='absolute hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md -top-8 -right-10 w-56'>
                          Update your current city in "Location" section.
                      </span>
                    </span>
                  )}
                </p>
                {field === 'employmentType' ? (
                  <select
                    name={field}
                    data-parent-key='jobPreferences'
                    value={
                      editable
                        ? editData.jobPreferences?.[field] || ''
                        : profile.jobPreferences?.[field] || ''
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setEditData((prevState) => ({
                        ...prevState,
                        jobPreferences: { ...prevState.jobPreferences, [field]: value },
                      }));
                    }}
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? 'border-gray-300' : 'border-transparent'
                    } bg-none placeholder-gray-400`}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    {employmentTypeOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : field === 'shiftPreference' ? (
                  <select
                    name={field}
                    data-parent-key='jobPreferences'
                    value={
                      editable
                        ? editData.jobPreferences?.[field] || ''
                        : profile.jobPreferences?.[field] || ''
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setEditData((prevState) => ({
                        ...prevState,
                        jobPreferences: { ...prevState.jobPreferences, [field]: value },
                      }));
                    }}
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? 'border-gray-300' : 'border-transparent'
                    } bg-none placeholder-gray-400`}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    {shiftOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : field === 'currentCity' ? (
                  <p className='font-medium text-xs mt-2'>
                    {profile.personalDetails?.currentAddress?.city || 'Not Available'}
                  </p>
                ) : (
                  <select
                    name={field}
                    data-parent-key='jobPreferences'
                    value={
                      editable
                        ? editData.jobPreferences?.[field]
                          ? 'Yes'
                          : 'No'
                        : profile.jobPreferences?.[field]
                        ? 'Yes'
                        : 'No'
                    }
                    onChange={(e) => {
                      const value = e.target.value === 'Yes';
                      setEditData((prevState) => ({
                        ...prevState,
                        jobPreferences: { ...prevState.jobPreferences, [field]: value },
                      }));
                    }}
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? 'border-gray-300' : 'border-transparent'
                    } bg-none placeholder-gray-400`}
                  >
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </select>
                )}
              </div>
            ))}
          
          {/* Preferred Locations */}
          <div className='col-span-1 sm:col-span-1'>
            <p className='text-xs text-gray-600 mb-1 flex items-justify-between gap-2'>
              Preferred Locations
              <span className='group relative'
                data-tooltip="preferred-work-location"
              >
                <Info className='w-4 h-4 text-gray-400 cursor-pointer' />
                <span className='absolute hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md -top-8 -right-10 w-56'>
                  If your preference is only the current location, your current city will be automatically updated once other information is saved. To update a location other than your current city, you need to change 'Willingness to Relocate' to 'Yes'.
                </span>
              </span>
            </p>
            <textarea
              ref={preferredLocationsRef}
              name='preferredLocations'
              data-parent-key='jobPreferences'
              value={
                editable
                  ? editData.jobPreferences?.preferredLocations || ''
                  : profile.jobPreferences?.preferredLocations || ''
              }
              onChange={(e) => {
                const value = e.target.value.slice(0, 60); // Restrict to 60 characters
                setEditData((prevState) => ({
                  ...prevState,
                  jobPreferences: { ...prevState.jobPreferences, preferredLocations: value },
                }));
                adjustHeight(e.target);
              }}
              placeholder='Enter preferred locations (max 60 characters)'
              disabled={
                !editable || !editData.jobPreferences?.willingnessToRelocate // Disable if not editable or willingnessToRelocate is false
              }
              className={`font-medium text-xs p-1 border ${
                editable ? 'border-gray-300' : 'border-transparent'
              } bg-none placeholder-gray-400 w-full resize-none`}
              style={{ overflowY: 'hidden' }}
            />
          </div>

          {/* Preferred Roles */}
          <div className='col-span-1 sm:col-span-5'>
            <p className='text-xs text-gray-600 mb-1 flex items-center gap-2'>
              Preferred Roles
              <span className='group relative'>
                <Info className='w-4 h-4 text-gray-400 cursor-pointer' />
                <span className='absolute hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md -top-8 -right-10 w-64'>
                  Specify roles in 2-3 words max, separated by commas. For example: Frontend Developer, Backend Developer, QA Tester, UI Designer.
                </span>
              </span>
            </p>
              <textarea
                ref={preferredRolesRef}
                name='preferredRoles'
                data-parent-key='jobPreferences'
                value={
                  editable
                    ? editData.jobPreferences?.preferredRoles || ''
                    : profile.jobPreferences?.preferredRoles || ''
                }
                onChange={(e) => {
                  const value = e.target.value.slice(0, 150); // Restrict to 150 characters
                  setPreferredRolesLength(value.length); // Update character count
                  setEditData((prevState) => ({
                    ...prevState,
                    jobPreferences: { ...prevState.jobPreferences, preferredRoles: value },
                  }));
                  adjustHeight(e.target);
                }}
                placeholder='Enter preferred roles (max 150 characters)'
                disabled={!editable}
                className={`font-medium text-xs p-1 border ${
                  editable ? 'border-gray-300' : 'border-transparent'
                } bg-none placeholder-gray-400 w-full resize-none`}
                style={{ overflowY: 'hidden' }}
              />
              {editable && (
                <p className='text-right text-xs text-gray-500 mt-1'>
                  {preferredRolesLength}/150
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPreferences;