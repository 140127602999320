// src/pages/login/RecruiterLoginModal.jsx

import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa'; // For the close (X) icon
import mailIcon from '../../Images/icons/email.png';
import passwordIcon from '../../Images/icons/password.png';

const RecruiterLoginModal = ({ onClose, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    setError(''); // Clear previous errors
    setIsLoading(true); // Show loading indicator

    try {
      // console.log('Login Attempt:', email, password);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/recruiter/recruiter-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      // console.log('Response Data:', response);

      // Check if response is valid
      if (!response.ok) {
        const errorData = await response.json();
        console.error('[ERROR] Login failed:', errorData.error || 'Unknown error');
        setError(errorData.error || 'Invalid email or password');
        setIsLoading(false); // Stop loading indicator
        return;
      }
      
      const { token } = await response.json();
      
      if (token) {
        // console.log('[DEBUG] Token received:', token);
        localStorage.setItem('recruiterToken', token); // Save token in localStorage
        // console.log('Token saved to localStorage:', localStorage.getItem('recruiterToken'));

        onLoginSuccess(token); // Notify parent about successful login
        onClose(); // Close the modal
        setIsLoading(false);
        window.location.reload(); // Reload to apply token globally
      } else {
        console.error('[ERROR] No token found in login response');
        setError('Login failed. No token received. Please try again.');
      }
    } catch (error) {
      console.error('[ERROR] Login API call failed:', error.message);
      setError('An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div
        className='bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative'
        style={{
          background: 'linear-gradient(to right, #E0E1EF 100%, #049D65 100%)',
          overflow: 'hidden',
        }}
      >
        {/* Close Icon */}
        <button
          onClick={() => {
            onClose();
          }}
          className='absolute top-3 right-3 text-gray-500 hover:text-gray-700'
        >
          <FaTimes size={20} />
        </button>

        {/* Header */}
        <div className='text-center mb-4'>
          <h2 className='text-xl font-bold text-gray-700'>Recruiter Login</h2>
          {error && <p className='text-red-500 text-sm mt-2'>{error}</p>}
        </div>

        {/* Form */}
        <form
          className='space-y-4'
          onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission
            handleLogin();
          }}
        >
          {/* Email Field */}
          <div className='flex items-center'>
            <label 
              className='text-sm font-medium text-gray-700 mr-10'
              >Email</label>
            <div className='relative flex-1'>
              <img
                src={mailIcon}
                alt='Email Icon'
                className='absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5'
              />
              <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Enter approved email'
                className='w-full pl-8 pr-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500'
                required
              />
            </div>
          </div>

          {/* Password Field */}
          <div className='flex items-center'>
            <label className='text-sm font-medium text-gray-700 mr-3'>Password</label>
            <div className='relative flex-1'>
              <img
                src={passwordIcon}
                alt='Password Icon'
                className='absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5'
              />
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Enter your password'
                className='w-full pl-8 pr-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500'
                required
              />
            </div>
          </div>

          {/* Login Button */}
          <div className='flex justify-center mt-6'>
            <button
              type='submit'
              disabled={isLoading}
              className={`bg-emerald-800 text-white px-6 py-2 rounded-lg hover:bg-emerald-700 transition ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecruiterLoginModal;