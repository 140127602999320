// src/pages/login/AdminLogin.jsx

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import axios from "axios";
import mailIcon from "../../Images/icons/email.png";
import passwordIcon from "../../Images/icons/password.png";

const AdminLogin = () => {
  const adminContext = useContext(AdminContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
  
    if (!adminContext || !adminContext.adminLogin) {
      console.error("[ERROR] AdminContext or adminLogin is undefined.");
      setError("Unable to login. Please try again later.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/login`, 
        {
        username,
        password,
      });

      adminContext.adminLogin(response.data.token);
      navigate("/admin/dashboard");
    } catch (err) {
      console.error("[ERROR] Admin login failed:", err);
      setError(err.response?.data?.error || "Login failed. Please try again.");
    }
  };

  return (
    <div
      className="min-h-screen w-full p-2 md:p-4 flex items-center justify-center"
      style={{
        background: "linear-gradient(to right, #049D65 0%, #E0E1EF 100%)",
        overflow: "hidden",
      }}
    >
      <form
        onSubmit={handleLogin}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-sm w-full space-y-3"
      >
        <h1 className="text-xl font-bold mb-6 text-center">Admin Login</h1>

        {/* Username Field */}
        <div className="relative">
          <label className="text-gray-600 mb-1 block text-xs md:text-sm">
            Username<span className="text-red-500"> *</span>
          </label>
          <div className="relative">
          <img
            src={mailIcon}
            alt="Mail Icon"
            className="w-4 h-4 absolute left-2 top-2.5"
          />
            <input
              type="text"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full pl-8 pr-3 py-1.5 text-xs md:text-sm placeholder:text-xxs border border-gray-200 border-solid rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              required
            />
          </div>
        </div>

        {/* Password Field */}
        <div className="relative">
          <label className="text-gray-600 mb-1 block text-xxs md:text-sm">
            Password<span className="text-red-500"> *</span>
          </label>
          <div className="relative">
          <img
            src={passwordIcon}
            alt="Password Icon"
            className="w-4 h-4 absolute left-2 top-2.5"
          />
            <input
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-8 pr-3 py-1.5 text-xs md:text-sm placeholder:text-xxs border border-gray-200 border-solid rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              required
            />
          </div>
        </div>

        {/* Error Message */}
        {error && <p className="text-red-500 text-xs italic">{error}</p>}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-emerald-500 text-white py-2 rounded-md text-sm hover:bg-emerald-600 transition-colors"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;