// src/pages/talhunt/ats.jsx

import React, { useState, useEffect } from 'react';
import RecruiterLoginModal from './RecruiterLoginModal';
import { transformProfiles } from './utils/profileUtils';
import './ats.css';

const ATS = ({ 
    setFilteredProfiles, 
    originalProfiles,
    isRecruiterLoggedIn, 
    showLoginModal,
    setShowLoginModal, 
    handleLoginSuccess,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [jobs, setJobs] = useState([]); // Holds job data
  const [jobError, setJobError] = useState(''); // Error for job fetching
  const [jobLoading, setJobLoading] = useState(false); // Loading state for job fetching
  const [profileError, setProfileError] = useState(''); // Error for profile fetching
  const [profileLoading, setProfileLoading] = useState(false); // Loading state for profile fetching
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage, setJobsPerPage] = useState(5); // Jobs per page (default for large screens)

  // Adjust jobs per page based on screen size
  useEffect(() => {
    const updateJobsPerPage = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        setJobsPerPage(1); // Extra Small screens: 1 job
      } else if (screenWidth >= 640 && screenWidth < 768) {
        setJobsPerPage(2); // Small screens: 2 jobs
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setJobsPerPage(3); // Medium screens: 3 jobs
      } else {
        setJobsPerPage(5); // Large screens: 5 jobs
      }
    };
  
    updateJobsPerPage(); // Initial check
    window.addEventListener('resize', updateJobsPerPage);
    return () => window.removeEventListener('resize', updateJobsPerPage);
  }, []);

  const totalPages = Math.ceil(jobs.length / jobsPerPage);
  const displayedJobs = jobs.slice(
    (currentPage - 1) * jobsPerPage,
    currentPage * jobsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Fetch jobs on component mount
  useEffect(() => {
    const fetchJobs = async () => {
      setJobLoading(true);
      setJobError('');
      try {
        // console.log('Fetching jobs from API...');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jobs`);
        if (!response.ok) {
          throw new Error(`Failed to fetch jobs: ${response.status}`);
        }
        const data = await response.json();
        // console.log('Fetched jobs:', data);

        // Transform jobs for consistent structure
        const transformedJobs = data.map((job) => ({
          title: job.Job_Title || 'N/A',
          company: job.Company || 'N/A',
          experience: `${job.Min_Exp || '0'} - ${job.Max_Exp || '0'}`,
          location: job.Location || 'N/A',
          ccid: job.CCID || 'N/A',
          jobId: job.Job_ID || 'N/A',
          keywords: job.Keywords,
        }))
        .sort((a, b) => b.ccid.localeCompare(a.ccid)); // Sort descending by CCID

        setJobs(transformedJobs);
      } catch (err) {
        console.error('[ERROR] Fetching jobs:', err.message);
        setJobError('Failed to fetch jobs. Please try again later.');
      } finally {
        setJobLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleSearch = async () => {
    if (!searchInput.trim()) {
      setFilteredProfiles(originalProfiles); // Show original profiles if search input is empty
      setProfileError('Please enter a valid CCID or Job ID.');
      return;
    }
    
    setProfileLoading(true);
    setProfileError('');
    setFilteredProfiles(originalProfiles); // Show original profiles if search input is empty
    
    try {
      // console.log('Performing search for:', searchInput);

      // Check if the search input is a valid CCID
      const isCCID = searchInput.startsWith('202') && searchInput.length === 15;
   
      // Fetch job details from `/jobs`
      const job = jobs.find((j) => (isCCID ? j.ccid === searchInput : j.jobId === searchInput));
      if (!job || job.length === 0) {
        setProfileError('No matching job found.');
        setFilteredProfiles([originalProfiles]);
        //setFilteredProfiles([]);
        return;
      }

      // console.log('[DEBUG] Fetched Job Details:', job);
      // Logic to handle "NS" experience values
    
      let jobMinExp = job.experience.split('-')[0].trim();
      let jobMaxExp = job.experience.split('-')[1].trim();

      jobMinExp = jobMinExp === "NS" ? 0 : parseFloat(jobMinExp);
      jobMaxExp = jobMaxExp === "NS" ? (jobMinExp + 2) : parseFloat(jobMaxExp);

      // Prepare query for `talview/public-profiles`
      const queryParams = new URLSearchParams({
        keyword: `${job.title},${job.keywords}`,
        location: job.location,
        minOverallExp: jobMinExp,
        maxOverallExp: jobMaxExp,
      });

      // console.log('[DEBUG] Query Params:', queryParams.toString());

      // Call `talview/public-profiles` endpoint
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/talview/public-profiles?${queryParams}`
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch jobs.');
      }

    const data = await response.json();
    // console.log('[DEBUG] Fetched Profiles:', data);
    
      if (data.length === 0) {
        setFilteredProfiles([]); // Show no results if no profiles are matched
        setProfileError('No matching profiles found.');
      } else {
        const transformedProfiles = transformProfiles(data);
        // console.log('[DEBUG] Transformed Profiles:', transformedProfiles);
        setFilteredProfiles(transformedProfiles);
      }
    } catch (err) {
      console.error('[ERROR] ATS Search:', err.message);
      setProfileError(err.message || 'Something went wrong.');
    } finally {
      setProfileLoading(false);
    }
  };

  const handleClear = () => {
    setSearchInput(''); // Reset search input
    setProfileError(''); // Clear error
    setCurrentPage(1); // Reset to the first page
    setFilteredProfiles(originalProfiles);
  };

  const handleJobTitleClick = (ccid) => {
    setSearchInput(ccid); // Set the clicked CCID to the search input
  };

  return (
    <div className="p-4 flex flex-col lg:flex-row gap-4">
      {/* Search Bar - 1/4 Container */}
      <div className="flex md:flex-col w-full lg:w-1/4 gap-2">
        <input
          type="text"
          placeholder="Enter CCID"  //placeholder="Enter CCID or Job ID"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="p-2 border rounded w-full"
        />
                <div className="flex gap-2">
          <button
            onClick={handleSearch}
            className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Search
          </button>
          <button
            onClick={handleClear}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Clear
          </button>
        </div>
    
        {/* Error Message */}
        {profileError && <p className="error-message mt-2">{profileError}</p>}

        {/* Loading Indicator */}
        {profileLoading && <p>Loading Profiles...</p>}

        {/* Login Modal */}
        {!isRecruiterLoggedIn && showLoginModal && (
            <RecruiterLoginModal
            onClose={() => {
                setShowLoginModal(false);
            }}
            onLoginSuccess={handleLoginSuccess}
            />
        )}
        </div>

        {/* 3/4 Container */}
        {/* Job List */}
        <div className="w-full lg:w-3/4">
          {jobLoading ? (
            <p>Loading Jobs...</p>
          ) : jobError ? (
            <p className="error-message">{jobError}</p>
          ) : (
            <div>
              {displayedJobs.length === 0 ? (
                <p>No jobs to display</p>
              ) : (
                <div className="ats-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                  {displayedJobs.map((job, index) => (
                    <div 
                      key={index} 
                      className="ats-card cursor-pointer hover:bg-green-100 transition duration-200 p-4 rounded"
                      onClick={() => handleJobTitleClick(job.ccid)} // Trigger on card click
                    >
                    <h3 className="ats-card-title text-left text-xxs font-semibold">
                      {job.title}
                    </h3>
                    <p className="ats-card-company text-left text-xxs">{job.company}</p>
                    <p className="ats-card-experience text-left text-xxs">Exp: {job.experience} years</p>
                    {/*<p className="ats-card-location">Loc: {job.location}</p>*/}
                    <p className="ats-card-ccid text-left text-xxs">CCID: {job.ccid}</p>
                  </div>
                 ))}
                </div>
              )}
              
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex justify-between items-center mt-2 text-sm">
                  <button
                    onClick={() => handlePageChange('prev')}
                    disabled={currentPage === 1}
                    // className="px-2 py-1 bg-gray-300 rounded disabled:opacity-50 text-sm"
                    className="pagination-button"
                  >
                    Prev
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange('next')}
                    disabled={currentPage === totalPages}
                    // className="px-2 py-1 bg-gray-300 rounded disabled:opacity-50 text-sm"
                    className="pagination-button"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
    </div>
  );
};

export default ATS;