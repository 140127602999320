import React, { useEffect, useState } from 'react';

const TalentDashboard = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalProfiles, setTotalProfiles] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/talent/dashboard`);
        const result = await response.json();
        setData(result);

        // Calculate the total number of profiles
        const total = result.reduce((sum, item) => sum + item.count, 0);
        setTotalProfiles(total);
      } catch (error) {
        console.error('Error fetching experience stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="flex justify-center px-4">
      {/* Dashboard Container */}
    <div className="flex flex-col w-full lg:w-1/2 bg-[#fff9f7] rounded-lg border border-gray-300 shadow-md transition-shadow duration-200">
      {/* Header Section */}
      <div className="px-6 py-4 bg-[#049c64] text-white rounded-t-lg">
        <h1 className="text-xl font-bold text-center">Total Profiles</h1>
        <p className="text-sm text-center mt-1"><span className="font-bold text-lg">{totalProfiles}</span></p>
      </div>

      {/* Table Section */}
      <div className="px-4 py-4 bg-[#fff9f7]">
        {/*<h2 className="text-lg font-medium text-gray-700 mb-4">No. of Profiles by Experience</h2>*/}
        <div className="w-full border-b border-gray-300">
          {/* Table Header */}
          <div className="grid grid-cols-2 bg-sky-950 text-white font-medium rounded-t-lg text-md">
            <div className="p-3 text-left">Experience</div>
            <div className="p-3 text-center">Profiles</div>
          </div>

          {/* Table Body */}
          <div className="divide-y divide-gray-200">
            {data.map((item, index) => (
              <div
                key={index}
                className={`grid grid-cols-2 hover:bg-gray-50 ${
                  index % 2 === 0 ? 'bg-white' : 'bg-[#fff9f7]'
                }`}
              >
                <div className="p-3 text-sm text-gray-600 text-left">
                  {item._id === null ? 'Yet to Initialize' : `${item._id} years`}
                </div>
                <div className="p-3 text-sm text-gray-600 text-center">{item.count}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TalentDashboard;