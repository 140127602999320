// src/AppContent.jsx

import React, { useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./Footer.jsx";
import Header from "./Header.jsx";
import BottomNavBar from "./BottomNavBar.jsx";
import LandingPage from "../pages/Landingpage/LandingPage.jsx";
import Jobs from "../pages/jobs/Jobs.jsx";
import ISearch from "../pages/iSearch/index.jsx";
import AIPS from "../pages/aips/aips.jsx";
import AboutUs from "../pages/about/AboutUs.jsx";
import Investor from "../pages/investor/investor.jsx";
import ContactUs from "../pages/contact/ContactUs.jsx";
import LoginPage from "../pages/login/login.jsx";
import Register from "../pages/register/register.jsx";
import Profile from "../pages/userprofile/index.jsx";
import TalHunt from "../pages/talhunt/talhunt.jsx";
import ProfileViewer from "../pages/talhunt/ProfileViewer.jsx";
import ATS from "../pages/talhunt/ats.jsx";
import ComingSoon from "../pages/ComingSoon.jsx";
import PageNotFound from "../pages/PageNotFound.jsx";
import ProtectedRoute from "./ProtectedRoute.jsx";

const AppContent = React.memo(() => {
  // console.log("[DEBUG] Rendering AppContent");

  const location = useLocation();
  
  // Check if Header and Footer should be displayed
  const showHeaderAndFooter = useMemo(
    () => location.pathname !== "/login" && location.pathname !== "/register",
    [location.pathname]
  );

  return (
    <div>
      {/* Display Header only on larger screens and on non-login/register pages */}
      {showHeaderAndFooter && <Header />}

      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />{" "}
        <Route path="/jobs" element={<Jobs />} />{" "}
        <Route path="/iSearch" element={<ISearch />} />{" "}
        <Route path="/AIPS" element={<AIPS />} />{" "}
        <Route path="/talhunt" element={<TalHunt />} />{" "}
        <Route path="/AboutUs" element={<AboutUs />} />{" "}
        <Route path="/Investor" element={<Investor />} />{" "}
        <Route path="/ContactUs" element={<ContactUs />} />{" "}
        <Route path="/login" element={<LoginPage />} />{" "}
        <Route path="/register" element={<Register />} />{" "}
        <Route path="/avatar" element={<ComingSoon />} />{" "}
        <Route path="/updatePassword" element={<ComingSoon />} />{" "}
        
        {/* Dynamic Route for Recruiter Profile */}
        <Route path="/profileViewer/" element={<ProfileViewer />} />
        <Route path="/ats/" element={<ATS />} />
        
        {/* Protected Routes */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute allowedRoles={["user"]}>
              <Profile />
            </ProtectedRoute>
          }
        />
        
        {/* Catch-all Route for 404 */}
        <Route path="*" element={<PageNotFound />} />{" "}
      </Routes>
      
      {/* Floating Bottom Navigation */}
      <BottomNavBar />

      {/* Display Footer only on non-login/register pages */}
      {showHeaderAndFooter && <Footer />}
    </div>
  );
});

export default AppContent;