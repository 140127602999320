import React from 'react';
import { Globe } from 'lucide-react';

const LanguagesKnown = ({ editable, editData, handleChange }) => {
  const fields = [
    { label: 'Read, Speak, and Write', field: 'readSpeakWrite' },
    { label: 'Read and Speak Only', field: 'readSpeakOnly' },
    { label: 'Speak Only', field: 'speakOnly' },
  ];

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-lg'>
          <Globe className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600 text-base'>Languages Known</span>
        </h3>
      </div>

      <div className='p-4 relative sm:p-4 grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6'>
        {fields.map(({ label, field }, index) => (
          <div key={index}>
            <p className='text-sm font-medium text-gray-600 mb-2'>{label}</p>
            <input
              type='text'
              name={field}
              data-parent-key='personalDetails.languagesKnown'
              value={editData.personalDetails?.languagesKnown?.[field] || ''}
              onChange={(e) => {
                handleChange(e);
                /* console.log(
                  `[DEBUG] ${field} updated to: `,
                  e.target.value
                ); // Debugging log */
              }}
              placeholder='Enter languages (e.g., English, Tamil, Hindi)'
              disabled={!editable}
              className={`w-full font-medium text-xs p-2 border ${
                editable ? 'border-gray-300' : 'border-transparent'
              } placeholder-gray-400`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguagesKnown;