// src/components/AdminContent.jsx

import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import CCAdmin from "../pages/admin/ccAdmin";
import AdminLogin from "../pages/login/AdminLogin";
import PageNotFound from "../pages/PageNotFound";
import EditUserProfile from "../pages/admin/EditUserProfile";

const AdminContent = () => {
  // console.log("[DEBUG] Rendering AdminContent");
  return (
    <Routes>
      {/* Admin Login Route */}
      <Route path="/login" element={<AdminLogin />} />

      {/* Protected Admin Routes */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute isAdmin>
            <CCAdmin />
          </ProtectedRoute>
        }
      />

      {/* Protected Admin Profile Edit Route */}
      <Route
        path="userProfile"
        element={
          <ProtectedRoute isAdmin>
            <EditUserProfile />
          </ProtectedRoute>
        }
      />

      {/* Catch-all Route for Admin 404 */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AdminContent;