// src/App.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import { AdminProvider } from "./context/AdminContext";
import AppContent from "./components/AppContent"; // For user-specific content
import AdminContent from "./components/AdminContent"; // For admin-specific content


function App() {
  return (
      <Routes>
        {/* Admin Routes */}
        <Route
          path="/admin/*"
          element={
            <AdminProvider>
              <AdminContent />
            </AdminProvider>
          }
        />

        {/* User Routes */}
        <Route
          path="/*"
          element={
            <UserProvider>
              <AppContent />
            </UserProvider>
          }
        />
      </Routes>
  );
}

export default App;