// src/pages/admin/ccAdmin.js

import React, { useState, useRef } from "react";
import axios from "axios";

const CCAdmin = () => {
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [recruiterPassword, setRecruiterPassword] = useState(""); // State for the password
  const [message, setMessage] = useState("");
  const [activeTab, setActiveTab] = useState("usage");
  const [data, setData] = useState(null);
  const [selectedDatabase, setSelectedDatabase] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [ccid, setCcid] = useState("");
  const [company, setCompany] = useState(""); // Add this state for company  

  // Add fileInputRef for resetting the file input field
  const fileInputRef = useRef(null);

  // Base URL from .env
  const BASE_URL = process.env.REACT_APP_API_URL;

  // Upload from default path
  const uploadFromEnvPath = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/jobs/upload/env`);
      alert(response.data || "Upload successful from default path!");
    } catch (error) {
      setMessage("Error uploading from default path.");
      console.error(error);
    }
  };

  // Upload manually selected file
  const uploadManualFile = async () => {
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${BASE_URL}/jobs/upload/manual`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert(response.data || "File uploaded successfully!");
        setFile(null); // Clear the file after successful upload
      document.getElementById("fileInput").value = ""; // Reset the file input
    setMessage(""); // Clear any previous message
    } catch (error) {
      setMessage("Error uploading file.");
      console.error(error);
    }
  };

  // Fetch contact us data
  const fetchContactUsData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/contact`);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch database usage stats
  const fetchUsageStats = async () => {
    try {
      const atlasStats = await axios.get(`${BASE_URL}/api/storage/atlas`);
      const localStats = await axios.get(`${BASE_URL}/api/storage/local`);
  
      setData({
        atlas: atlasStats.data,
        local: localStats.data,
      }); 
    } catch (error) {
      console.error('[ERROR] Failed to fetch storage stats:', error.response?.data || error.message);
    }
  };

  // Download CSV
  const downloadCSV = async (dbSource, collectionName) => {
    try {
// If the collection is "Personal Particulars", use the specific endpoint
      if (collectionName === "Personal Particulars") {
        const response = await axios.get(
          `${BASE_URL}/api/admin/downloadProfiles`,
          { responseType: "blob" }
        );

        // Proceed with file download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `user_profiles_.csv`);
        document.body.appendChild(link);
        link.click();
        setMessage(`User profiles CSV downloaded successfully.`);
      } else {
        // For other collections, keep the existing logic
      const response = await axios.get(
        `${BASE_URL}/jobs/download?source=${dbSource}&collection=${collectionName}`,
        { responseType: "blob" }
      );
      
    // Check if the response is an error message (indicating no data)
    if (response.headers['content-type'] === 'text/plain') {
      const reader = new FileReader();
      reader.onload = () => {
        const message = reader.result;
        if (message.includes("No data found")) {
          alert(`No data found in the "${collectionName}" collection of the "${dbSource}" database.`);
        } else {
          // For other plain text errors, instruct the user to check logs
          setMessage("An error occurred. Please check the logs for more details.");
        }
        };
        reader.onerror = () => {
          console.error("Error reading the response as text.");
          alert("An error occurred while processing the response. Please check the logs.");
      };
      reader.readAsText(response.data);
      return;
    }

    // Proceed with file download if data exists
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${dbSource}_${collectionName}_${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      setMessage(`CSV downloaded successfully for "${collectionName}" from "${dbSource}" database.`);
      }
    } catch (error) {
      console.error(
        `Error downloading CSV from ${dbSource} collection ${collectionName}:`,
        error
      );
      alert("An error occurred while downloading the CSV. Please check the logs.");
    }
  };
  
  // Fetch Expiry Dates
    const fetchExpiryDates = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/jobs/fetch-expiry`);
        setData(response.data);
        setMessage("Expiry dates fetched successfully.");
      } catch (error) {
        console.error("Error fetching expiry dates:", error);
        setMessage("Error fetching expiry dates.");
      }
    };
  
    // Delete Single Record
    const deleteSingleRecord = async () => {
      if (!ccid) {
        setMessage("Please provide a CCID.");
        return;
      }
      try {
        const response = await axios.delete(`${BASE_URL}/jobs/delete-single`, {
          data: { ccid },
        });
        alert(response.data || "Record deleted successfully.");
      } catch (error) {
        console.error("Error deleting record:", error);
        alert("No Records Found.");
      }
    };
  
    // Delete Bulk Records
    const deleteBulkRecords = async () => {
      if (!file) {
        alert("Please upload an excel file with CCID.");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
  
      try {
        const response = await axios.post(`${BASE_URL}/jobs/delete-bulk`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert(response.data || "Bulk records deleted successfully.");
        // Clear the file input and reset the file state
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Reset the file input
        }
      } catch (error) {
        console.error("Error deleting bulk records:", error);
        alert("Error deleting bulk records.");
      }
    };

    const approveRecruiter = async () => {
      // const email = document.getElementById('recruiterEmail').value;
      // const mobile = document.getElementById('recruiterMobile').value;
    
      if (!email || !mobile) {
        alert('Email and mobile number are required.');
        return;
      }
    
      try {
        const response = await axios.post(
          `${BASE_URL}/admin/approve-recruiter`,
          { email, mobile },
          { headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } }
        );
        
        const { message, recruiter } = response.data;
    
        // Update the password field with the response
        setRecruiterPassword(
          recruiter.password || "Password unchanged for existing recruiter."
        );

      setMessage(message); // Display any additional messages if needed
      console.log(`[INFO] Approve Recruiter Response:`, response.data);
      } catch (error) {
        console.error('[ERROR] Approving recruiter:', error.response?.data || error.message);
      alert(error.response?.data?.error || 'Failed to approve recruiter.');
      }
    };

    const editRecruiterCompany = async () => {
      if (!email || !company) {
        alert("Email and company name are required.");
        return;
      }
      try {
        const response = await axios.post(`${BASE_URL}/admin/edit-company`, { email, company });
        setMessage(response.data.message);
        console.log("[INFO] Company updated successfully:", response.data);
      } catch (error) {
        console.error("[ERROR] Editing recruiter company:", error.response?.data || error.message);
        alert(error.response?.data?.error || "Failed to update company.");
      }
    };
    
    const regeneratePassword = async () => {
      if (!email) {
        alert("Email is required to regenerate password.");
        return;
      }
      try {
        const response = await axios.post(`${BASE_URL}/admin/recruiter-password`, { email });
        setMessage(response.data.message);
        setRecruiterPassword(response.data.password);
        console.log("[INFO] Password regenerated successfully:", response.data);
      } catch (error) {
        console.error("[ERROR] Regenerating password:", error.response?.data || error.message);
        alert(error.response?.data?.error || "Failed to regenerate password.");
      }
    };
    
    const deleteRecruiter = async () => {
      if (!email) {
        alert("Email is required to delete a recruiter.");
        return;
      }
      try {
        const response = await axios.delete(`${BASE_URL}/admin/delete-recruiter`, { data: { email } });
        setMessage(response.data.message);
        console.log("[INFO] Recruiter deleted successfully:", response.data);
      } catch (error) {
        console.error("[ERROR] Deleting recruiter:", error.response?.data || error.message);
        alert(error.response?.data?.error || "Failed to delete recruiter.");
      }
    };
    
    const fetchRecruitersByCompany = async () => {
      if (!company) {
        alert("Company name is required.");
        return;
      }
      try {
        const response = await axios.post(`${BASE_URL}/admin/fetch-recruiters-by-company`, { company });
        setData(response.data.recruiters);
        console.log("[INFO] Recruiters fetched successfully:", response.data);
      } catch (error) {
        console.error("[ERROR] Fetching recruiters by company:", error.response?.data || error.message);
        alert(error.response?.data?.error || "Failed to fetch recruiters.");
      }
    };
    
    const fetchCompanyByRecruiter = async () => {
      if (!email) {
        alert("Email is required to fetch company.");
        return;
      }
      try {
        const response = await axios.post(`${BASE_URL}/admin/fetch-company-by-recruiter`, { email });
        setData(response.data.company);
        console.log("[INFO] Company fetched successfully:", response.data);
      } catch (error) {
        console.error("[ERROR] Fetching company by recruiter:", error.response?.data || error.message);
        alert(error.response?.data?.error || "Failed to fetch company.");
      }
    };    

    const openUserProfile = async () => {
      try {
        const token = localStorage.getItem('adminToken'); // Fetch token from localStorage
    
        if (!token) {
          alert('Your session has expired. Please log in again.');
          return;
        }
    
        // Make an API call to fetch the profile using the email
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/userProfile?email=${encodeURIComponent(email)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        if (!response.ok) {
          if (response.status === 404) {
            alert('User not found with the provided email.');
          } else if (response.status === 401 || response.status === 403) {
            alert('Unauthorized. Please log in again.');
          } else {
            alert('An error occurred while fetching the user profile.');
          }
          return;
        }
    
        // Parse the response
        const data = await response.json();
    
        if (data.userProfile) {
          // Redirect to the profile viewer with the fetched profile ID
          const profileUrl = `${window.location.origin}/admin/userProfile?email=${encodeURIComponent(email)}`;
          window.open(profileUrl, '_blank'); // Open in a new tab
        } else {
          alert('No profile data found for the given email.');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        alert('An unexpected error occurred. Please try again later.');
      }
    };    

  // Sidebar options with actions
  const adminOptions = [
    { key: "upload", label: "Upload Excel Files", action: null },
    { key: "usage", label: "Check Usage", action: fetchUsageStats },
    { key: "contact", label: "View Contact Us Data", action: fetchContactUsData },
    { key: "download", label: "Download CSV", action: null },
    { key: "fetchExpiry", label: "Fetch Expiry Date", action: fetchExpiryDates },
    { key: "deleteRecords", label: "Delete Records", action: null },
    { key: "approveRecruiter", label: "Approve Recruiter", action: null },
    { key: "updateUserProfile", label: "Update User Profile", action: openUserProfile },
  ];

  return (
    <div className="min-h-screen bg-[#fff9f7] text-gray-800">
        {/* Header Section */}
        <section className="bg-emerald-100 py-10 flex items-center justify-center relative">
        <div className="container mx-auto px-6 absolute bottom-4 text-center">
          <h1 className="text-3xl font-bold text-green-600">
            Admin Dashboard
          </h1>
        </div>
      </section>

      {/* Add admin functionalities here */}
      <p className="text-center mt-4">Welcome to the Admin Panel!</p>
      
      {/* Main Content Layout */}
      <div className="flex min-h-[calc(100vh-120px)]">
      {/* Sidebar */}
      <div className="w-1/4 bg-gray-100 p-4">
      <h2 className="text-lg font-bold mb-4">Admin Features</h2>
        <ul className="space-y-4">
        {adminOptions.map((option) => (
            <li key={option.key}>
              <button
                onClick={() => {
                  setActiveTab(option.key);
                  setMessage("");
                  setData(null);
                  if (option.action) option.action();
                }}
                className={`w-full text-left ${
                  activeTab === option.key ? "font-bold text-green-600" : ""
                }`}
              >
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Dynamic Main Section */}
      <div className="w-3/4 p-6">
        {activeTab === "upload" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Upload Excel Files</h2>
            <button
              onClick={uploadFromEnvPath}
              className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 mr-4"
            >
              Upload from Default Path
            </button>

          {/* Manual file upload */}
          <div className="mt-14">
            <label htmlFor="fileInput" className="block mb-2">
              Select Excel File:
            </label>
            <input
              type="file"
              id="fileInput"
              accept=".xlsx"
              onChange={(e) => setFile(e.target.files[0])}
              className="block mb-4"
            />
            <button
              onClick={uploadManualFile}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Upload Selected File
            </button>
          </div>

          {/* Message */}
          {message && <p className="mt-4 text-green-700">{message}</p>}
        </div>
        )}

        {activeTab === "usage" && data && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Database Usage</h2>
            <div>
              <h3 className="font-bold text-green-600">MongoDB Atlas</h3>
              <p>Cluster: {data.atlas.clusterName}</p>
              <p>Total Storage: {data.atlas.totalStorageGB} GB</p>
              <p>Used Storage: {data.atlas.usedStorageGB} GB</p>
              <p>Available Storage: {data.atlas.availableStorageGB} GB</p>
            </div>
            <div className="mt-4">
              <h3 className="font-bold text-green-600">Local MongoDB</h3>
              <p>Database: {data.local.database}</p>
              <p>Collections: {data.local.collections}</p>
              <p>Objects: {data.local.objects}</p>
              <p>Storage: {data.local.totalStorageMB} MB</p>
              <p>Indexes Storage: {data.local.indexesStorageMB} MB</p>
              <p>Total Size: {data.local.totalSizeMB} MB</p>
            </div>
          </div>
        )}

        {activeTab === "contact" && data && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Contact Us Submissions</h2>
            <pre className="bg-gray-200 p-4 rounded">{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}

        {/* Download CSV */}
        {activeTab === "download" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Download CSV</h2>
            <div>
              <p className="mb-2">Choose Database:</p>
              <select
                className="border border-gray-300 rounded px-4 py-2 mb-4"
                onChange={(e) => {
                  setSelectedDatabase(e.target.value);
                  setSelectedCollection(""); // Reset collection when database changes
                }}
                defaultValue=""
              >
                <option value="" disabled>
                  Select Database
                </option>
                <option value="atlas">MongoDB Atlas</option>
                <option value="local">MongoDB Local</option>
              </select>

              {selectedDatabase && (
                <>
                  <p className="mb-2">Choose Collection:</p>
                  <select
                    className="border border-gray-300 rounded px-4 py-2 mb-4"
                    onChange={(e) => setSelectedCollection(e.target.value)}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select Collection
                    </option>
                    {selectedDatabase === "atlas" ? (
                      <>
                        <option value="Admin Credentials">Admin Credentials</option>
                        <option value="User Records">User Records</option>
                        <option value="Aggregated Jobs">Aggregated Jobs</option>
                        <option value="ContactUs Form">Contact Us Form</option>
                      </>
                    ) : (
                      <>
                        <option value="Keywords">Keywords</option>
                        <option value="Personal Particulars">User Profiles</option>
                      </>
                    )}
                  </select>
                </>
              )}

              {selectedCollection && (
                <button
                  onClick={() => downloadCSV(selectedDatabase, selectedCollection)}
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  Download CSV
                </button> 
              )}
              </div>
            </div>
          )}

          {activeTab === "fetchExpiry" && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Fetch Expiry Date</h2>
              <label htmlFor="ccidInput" className="block mb-2">
                Enter CCID:
              </label>
              <input
                type="text"
                id="ccidInput"
                value={ccid}
                onChange={(e) => setCcid(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 mb-4 w-full"
                placeholder="Enter CCID"
              />
              <button
                onClick={async () => {
                  if (!ccid) {
                    setMessage("Please provide a CCID.");
                    return;
                  }
                  try {
                    const response = await axios.get(`${BASE_URL}/jobs/fetch-expiry?ccid=${ccid}`);
                    setData(response.data);
                    setMessage("Expiry date fetched successfully.");
                  } catch (error) {
                    console.error("Error fetching expiry date:", error);
                    setMessage("Error fetching expiry date. Please check if the CCID is correct.");
                  }
                }}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Fetch Expiry Date
              </button>
              {message && <p className="mt-4 text-green-700">{message}</p>}
              {data && (
                <pre className="bg-gray-200 p-4 rounded mt-4">{JSON.stringify(data, null, 2)}</pre>
              )}
            </div>
          )}

          {activeTab === "approveRecruiter" && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Approve Recruiter</h2>
              
              {/* Approve Recruiter Section */}
              <div className="mb-8">
                <label htmlFor="recruiterEmail" className="block mb-2">Recruiter Email:</label>
                <input
                  type="email"
                  id="recruiterEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 rounded px-4 py-2 mb-4 w-full"
                  placeholder="Enter recruiter's email"
                />
                <label htmlFor="recruiterMobile" className="block mb-2">Recruiter Mobile:</label>
                <input
                  type="text"
                  id="recruiterMobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  className="border border-gray-300 rounded px-4 py-2 mb-4 w-full"
                  placeholder="Enter recruiter's mobile number"
                />
                
                {/* Recruiter Password */}
                <label htmlFor="recruiterPassword" className="block mb-2">
                  Recruiter Password:
                </label>
                <input
                  type="text"
                  id="recruiterPassword"
                  value={recruiterPassword}
                  readOnly
                  className="border border-gray-300 rounded px-4 py-2 mb-4 w-full bg-gray-100"
                  placeholder="Password will be displayed here after approval"
                />
                <button
                  onClick={approveRecruiter}
                  className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                  Approve Recruiter
                </button>
              </div>
          
              {/* Edit Recruiter Company Section */}
              <div className="mb-8">
              <h3 className="font-semibold mb-2">Edit Recruiter Company</h3>
              <label htmlFor="recruiterCompany" className="block mb-2">Company:</label>
              <input
                type="text"
                id="recruiterCompany"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 mb-4 w-full"
                placeholder="Enter company name"
              />
              <button
                onClick={editRecruiterCompany}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Update Company
              </button>
            </div>

            {/* Regenerate Password Section */}
            <div className="mb-8">
              <h3 className="font-semibold mb-2">Regenerate Password</h3>
              <button
                onClick={regeneratePassword}
                className="bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-700"
              >
                Regenerate Password
              </button>
              {recruiterPassword && (
                <p className="mt-2 text-blue-600">New Password: {recruiterPassword}</p>
              )}
            </div>

            {/* Delete Recruiter Section */}
            <div className="mb-8">
              <h3 className="font-semibold mb-2">Delete Recruiter</h3>
              <button
                onClick={deleteRecruiter}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Delete Recruiter
              </button>
            </div>

            {/* Fetch Recruiters by Company Section */}
            <div className="mb-8">
              <h3 className="font-semibold mb-2">Fetch Recruiters by Company</h3>
              <label htmlFor="companyName" className="block mb-2">Company Name:</label>
              <input
                type="text"
                id="companyName"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 mb-4 w-full"
                placeholder="Enter company name"
              />
              <button
                onClick={fetchRecruitersByCompany}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Fetch Recruiters
              </button>
              {data && (
                <div className="mt-4">
                  <h3 className="font-bold">Recruiters:</h3>
                  <pre className="bg-gray-200 p-4 rounded">{JSON.stringify(data, null, 2)}</pre>
                </div>
              )}
            </div>

            {/* Fetch Company by Recruiter Section */}
            <div className="mb-8">
              <h3 className="font-semibold mb-2">Fetch Company by Recruiter</h3>
              <button
                onClick={fetchCompanyByRecruiter}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Fetch Company
              </button>
              {data && (
                <div className="mt-4">
                  <h3 className="font-bold">Company:</h3>
                  <pre className="bg-gray-200 p-4 rounded">{JSON.stringify(data, null, 2)}</pre>
                </div>
              )}
            </div>

            {/* Display Messages */}
            {message && <p className="mt-4 text-green-700">{message}</p>}
          </div>
          )}

          {activeTab === "deleteRecords" && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Delete Records</h2>
              <div className="mb-4">
                <label htmlFor="ccid" className="block mb-2">
                  Enter CCID for Single Record Deletion:
                </label>
                <input
                  type="text"
                  id="ccid"
                  value={ccid}
                  onChange={(e) => setCcid(e.target.value)}
                  className="border border-gray-300 rounded px-4 py-2 w-full mb-4"
                />
                <button
                  onClick={() => deleteSingleRecord()}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                  Delete Single Record
                </button>
              </div>

              <div>
                <label htmlFor="bulkFile" className="block mb-2">
                  Upload Excel File for Bulk Deletion (CCID Column):
                </label>
                <input
                  type="file"
                  id="bulkFile"
                  ref={fileInputRef}
                  accept=".xlsx"
                  onChange={(e) => setFile(e.target.files[0])}
                  className="block mb-4"
                />
                <button
                  onClick={deleteBulkRecords}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                  Delete Bulk Records
                </button>
              </div>

              {message && <p className="mt-4 text-green-700">{message}</p>}
            </div>
          )}

          {activeTab === "updateUserProfile" && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Update User Profile</h2>
              <label htmlFor="email" className="block mb-2">
                Enter User Email:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 mb-4 w-full"
                placeholder="Enter user's email"
              />
              <button
                onClick={openUserProfile}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Edit Profile
              </button>
              {message && <p className="mt-4 text-green-700">{message}</p>}
            </div>
          )}

          {/* Add other activeTab sections as needed */}
        </div>
    </div>
  </div>
  );
};

export default CCAdmin;