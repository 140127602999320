// src/components/Header.jsx

import React, { useEffect, useState, useRef, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";
// import Male from "../../Images/Male.png";
// import Female from "../../Images/Female.png";
import Unisex from "../Images/Unisex.png";
import logo from "../Images/logo.png";

const Header = () => {
  const { isLoggedIn, userData, logout } = useContext(UserContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const activePath = location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.pageYOffset > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Close dropdown on outside click
    const handleOutsideClick = (e) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(e.target)) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const isActive = (path) => {
    if (path === "/") {
      return activePath === path;
    }
    return activePath.startsWith(path);
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 w-full flex justify-between items-center py-3 px-4 transition-all duration-300
        ${isScrolled ? "bg-white shadow-md" : "bg-transparent"}`}
    >
      <div className="pl-4 pt-1 cursor-pointer" onClick={() => navigate("/")}>
        <img src={logo} alt="Logo" className="h-8" />
      </div>

      <nav className="relative flex items-center">
        <button
          className="lg:hidden bg-transparent border-none absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-black p-2"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <X className="w-5 h-5" />
          ) : (
            <Menu className="w-5 h-5" />
          )}
        </button>

        <ul
          className={`list-none flex lg:gap-6 m-0 p-0 lg:text-xs xl:text-sm transition-all duration-300
          ${isMenuOpen ? "flex" : "hidden"} 
          lg:flex flex-col lg:flex-row items-start lg:items-center 
          absolute lg:relative top-12 lg:top-0 right-0 
          bg-white lg:bg-transparent p-6 lg:p-0 
          rounded-lg shadow-lg lg:shadow-none 
          min-w-[200px] max-w-full lg:min-w-0
          ${isScrolled ? "text-gray-800" : "text-black"}`}
        >
          <li className="w-full lg:w-auto">
            <a
              href="/"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/") ? "text-emerald-600" : ""}`}
            >
              Home
            </a>
          </li>
          <li className="w-full lg:w-auto">
            <a
              href="/jobs"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/jobs") ? "text-emerald-600" : ""}`}
            >
              Jobs
            </a>
          </li>
          <li className="w-full lg:w-auto">
            <a
              href="/iSearch"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/iSearch") ? "text-emerald-600" : ""}`}
            >
              iSearch
            </a>
          </li>
          <li className="w-full lg:w-auto">
            <a
              href="/aips"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/aips") ? "text-emerald-600" : ""}`}
            >
              AIPS
            </a>
          </li>
          <li className="w-full lg:w-auto">
            <a
              href="/talhunt"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/talhunt") ? "text-emerald-600" : ""}`}
            >
              TalHunt
            </a>
          </li>
{/*
          <li className="w-full lg:w-auto">
            <a
              href="/AboutUs"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/AboutUs") ? "text-emerald-600" : ""}`}
            >
              About Us
            </a>
          </li>

          <li className="w-full lg:w-auto">
            <a
              href="/Investor"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/Investor") ? "text-emerald-600" : ""}`}
            >
              Investor
            </a>
          </li>
*/}
          <li className="w-full lg:w-auto">
            <a
              href="/ContactUs"
              className={`block w-full lg:w-auto px-3 py-1 font-normal hover:text-emerald-600 transition-colors duration-300
                ${isActive("/ContactUs") ? "text-emerald-600" : ""}`}
            >
              Contact Us
            </a>
          </li>
          {!isLoggedIn ? (
          <li className="w-full lg:w-auto mt-3 lg:mt-0">
            <a
              href="/login"
              className={`block w-full lg:w-auto text-center lg:text-left bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-1 rounded transition-colors duration-300`}
            >
              Login
            </a>
          </li>
          ) : (
            <li className="relative w-full lg:w-auto">
              <button 
                onClick={toggleProfileMenu}
                className="flex items-center space-x-2 focus:outline-none"
                style={{ paddingLeft: '10px' }} /* Optional padding adjustment */
              >
                <img
                  src={userData?.profileImage || Unisex}
                  alt="Profile"
                  className="w-8 h-8 rounded-full border"
                />
              </button>
              {isProfileMenuOpen && (
              <ul 
                ref={profileMenuRef}
                className="absolute right-0 mt-2 w-40 bg-purple-100 shadow-lg rounded-lg py-2"
              >
                <li>
                  <a
                    href="/profile?tab=profile"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    View Profile
                  </a>
                </li>
                <li>
                  <a
                    href="/profile?tab=savedJobs"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Saved Jobs
                  </a>
                </li>
                <li>
                  <a
                    href="/avatar"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Change Avatar
                  </a>
                </li>
                <li>
                  <a
                    href="/updatePassword"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Update Password
                  </a>
                </li>
                <li>
                  <button
                    onClick={logout}
                    className="block w-full text-center px-4 py-2 text-sm text-red-500 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </li>
              </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;