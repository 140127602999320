import React from 'react';
import { Share2 } from 'lucide-react';

const SocialProfiles = ({ editable, editData, handleChange, setEditData }) => {
  const availableProfiles = [
    { key: 'linkedin', display: 'LinkedIn' },
    { key: 'google', display: 'Google' },
    { key: 'github', display: 'Github' },
    { key: 'youtube', display: 'Youtube' },
    { key: 'instagram', display: 'Instagram' },
    { key: 'facebook', display: 'Facebook' },
    { key: 'twitter', display: 'Twitter' },
    { key: 'others', display: 'Others' },
  ];

  const handleAddProfile = (e) => {
    const selectedProfile = e.target.value.toLowerCase();
    if (selectedProfile && !(editData.socialProfiles || {})[selectedProfile]) {
      handleChange({
        target: {
          name: selectedProfile,
          value: '',
          dataset: { parentKey: 'socialProfiles' },
        },
      });
    } else if (selectedProfile) {
      alert('This social profile is already added.');
    }
    e.target.value = ''; // Reset dropdown
  };

  const handleRemoveProfile = (profileField) => {
    setEditData((prevState) => {
      // Create a deep copy of the socialProfiles to ensure immutability
      const updatedProfiles = { ...prevState.socialProfiles };
      delete updatedProfiles[profileField]; // Remove the selected profile
  
      return {
        ...prevState, // Retain other parts of the state
        socialProfiles: updatedProfiles, // Update socialProfiles with the modified version
      };
    }); 
    
  // No immediate backend save here
  // console.log(`[DEBUG] Removed profile: ${profileField}`);
  };

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-lg'>
          <Share2 className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600 text-base'>Social Profiles</span>
        </h3>
      </div>

      <div className='p-4 relative sm:p-4'>
        {/* Dropdown to Add Social Profiles */}
        {editable && (
          <div className='mb-4'>
            <label className='text-sm text-gray-600'>Add a Social Profile</label>
            <select
              onChange={handleAddProfile}
              className='w-full text-sm border border-gray-300 rounded-md p-1 mt-1'
              disabled={!editable}
            >
              <option value=''>Select Social Profile</option>
              {availableProfiles
                .filter((profile) => !(editData.socialProfiles || {})[profile.key])
                .map((profile) => (
                  <option key={profile.key} value={profile.key}>
                    {profile.display}
                  </option>
                ))}
            </select>
          </div>
        )}

        {/* Render Added Profiles */}
        {editData.socialProfiles && Object.keys(editData.socialProfiles).length > 0 ? (
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
            {Object.entries(editData.socialProfiles).map(([profileField, value]) => {
              const displayName =
                availableProfiles.find((profile) => profile.key === profileField)?.display ||
                profileField.charAt(0).toUpperCase() + profileField.slice(1);

              return (
              <div key={profileField} className='mb-2'>
                <p className='text-xs text-gray-600 mb-1'>{displayName}</p>
                {editable ? (
                  <div>
                    <input
                      type='text'
                      name={profileField}
                      data-parent-key='socialProfiles'
                      value={value || ''}
                      onChange={(e) => {
                        const updatedValue = e.target.value.slice(0, 150); // Restrict to 150 characters
                        handleChange({
                          target: {
                            name: profileField,
                            value: updatedValue,
                            dataset: { parentKey: 'socialProfiles' },
                          },
                        });
                      }}
                      placeholder={`Enter ${displayName} URL`}
                      disabled={!editable}
                      maxLength={150}
                      className={`w-full font-medium text-xs p-1 border ${
                        editable ? 'border-gray-300' : 'border-transparent'
                      }`}
                    />
                    {/* Character Counter */}
                    <p className='text-right text-xs text-gray-500 mt-1'>
                      {value?.length || 0}/150
                    </p>
                  </div>
                ) : (
                  // Render as hyperlink if not editable
                  <a
                    href={value || '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-600 hover:underline break-all text-xs'
                  >
                    {value || 'No URL provided'}
                  </a>
                )}
                {editable && (
                  <button
                    type='button'
                    onClick={() => handleRemoveProfile(profileField)}
                    className='text-red-500 text-xs mt-1'
                  >
                    Remove
                  </button>
                )}
              </div>
            );
            })}
          </div>
        ) : (
          <p className='text-sm text-gray-500'>Please update your social profiles. Upload your CV to Google Drive and provide the shared URL under the 'Google' profile field.</p>
        )}
      </div>
    </div>
  );
};

export default SocialProfiles;