import React from 'react';
import { MapPin } from 'lucide-react';

const LocationDetails = ({ editable, editData, handleChange, setEditData, profile }) => {
  const fields = ['address', 'city', 'state', 'country', 'zipCode', 'apartment'];

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const updatedCurrentAddress = isChecked
      ? { ...editData.personalDetails.permanentAddress }
      : { address: '', city: '', state: '', country: 'India', zipCode: '', apartment: '' };

      setEditData((prevData) => ({
        ...prevData,
        personalDetails: {
          ...prevData.personalDetails,
        isCurrentAddressSame: isChecked,
        currentAddress: updatedCurrentAddress,
      },
    }));
  };

  const renderInputField = (field, addressType) => {
    const addressData =
      addressType === 'permanent'
        ? editData.personalDetails?.permanentAddress
        : editData.personalDetails?.currentAddress;

        return (
          <div key={`${addressType}-${field}`}>
            <p className='text-xs text-gray-600 mb-1'>
              {field === 'city' ? (
                <>
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                  <span className="text-red-500"> *</span> {/* Add red asterisk for mandatory city */}
                </>
              ) : (
                field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')
              )}
            </p>
            <input
              type='text'
              name={field}
              data-parent-key={`personalDetails.${addressType}Address`}
              value={editable ? addressData?.[field] || '' : profile.personalDetails[`${addressType}Address`]?.[field] || ''}
              onChange={handleChange}
              placeholder={`Enter ${field}`}
              disabled={!editable || (addressType === 'current' && editData.personalDetails.isCurrentAddressSame)}
              className={`font-medium text-xs p-1 border ${
                editable ? 'border-gray-300' : 'border-transparent'
              } w-full`}
            />
          </div>
        );
      };
    
  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-lg'>
          <MapPin className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600 text-base'>Location</span>
        </h3>
      </div>

      <div className='p-4 relative sm:p-4'>
        {/* Permanent Address */}
        <h4 className='text-sm font-medium text-gray-700 mb-2'>Permanent Address</h4>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-6'>
          {fields.map((field) => renderInputField(field, 'permanent'))}
        </div>

        {/* Current Address */}
        <h4 className='text-sm font-medium text-gray-700 mt-4 mb-2'>Current Address</h4>
        <div className='flex items-center mb-2'>
          <input
            type='checkbox'
            name='isCurrentAddressSame'
            checked={
              editable
                ? editData.personalDetails.isCurrentAddressSame
                : profile.personalDetails.isCurrentAddressSame
            }
            onChange={handleCheckboxChange}
            disabled={!editable}
          />
          <label className='ml-2 text-xs text-gray-700'>Same as Permanent Address</label>
        </div>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-6'>
          {fields.map((field) => renderInputField(field, 'current'))}
        </div>
      </div>
    </div>
  );
};

export default LocationDetails;