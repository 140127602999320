// src/pages/talhunt/talhunt.jsx

import React, { useState, useEffect } from 'react';
import { validateRecruiterToken } from './validateRecruiterToken';
import ManualSearch from './ManualSearch';
import ATS from './ats';
import TalentDashboard from './TalentDashboard';
import ComingSoon from '../ComingSoon';
import RecruiterLoginModal from './RecruiterLoginModal';
import { transformProfiles } from './utils/profileUtils';

// console.log('[DEBUG] Transform Profiles:', transformProfiles);

const TalHunt = () => {
  const [activeTab, setActiveTab] = useState('manualSearch');
  const [originalProfiles, setOriginalProfiles] = useState([]); // Store original profiles
  const [filteredProfiles, setFilteredProfiles] = useState([]); // Filtered profiles for display
  const [fetchError, setFetchError] = useState(null); // Error state
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isRecruiterLoggedIn, setIsRecruiterLoggedIn] = useState(false);
  const [searchParams, setSearchParams] = useState({
    keyword: '',
    customRole: '',
    minCustomExp: '',
    maxCustomExp: '',
    location: '',
    minOverallExp: '',
    maxOverallExp: '',
    maxNoticePeriod: '',
    shiftPreference: '',
    education: '',
    cutoffGradYear: '',
    language: '',
  });
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const profilesPerPage = 30; // Number of profiles per page
  const maxVisiblePages = 5; // Number of visible page numbers
  
  // Handle tab switching
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const validateToken = async () => {
    const { isValid } = await validateRecruiterToken();
      setIsRecruiterLoggedIn(isValid);
      if (!isValid) {
        setShowLoginModal(true);
      }
    };
  
    validateToken();
  }, []);  

  // Handle Recruiter Login
  const handleLoginSuccess = (responseToken) => {
    if (responseToken) {
      localStorage.setItem('recruiterToken', responseToken);
      setIsRecruiterLoggedIn(true);
      setShowLoginModal(false);
      alert('Login successful. You can now view profiles.');
    } else {
      alert('Login failed. Please try again.');
    }
  };

  // Fetch all profiles from the database
  useEffect(() => {
  const fetchProfiles = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/talview/public-profiles`);
      if (!response.ok) {
        throw new Error(`Failed to fetch profiles: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      // console.log('[DEBUG] Fetched Profiles:', data);
        
      if (!Array.isArray(data) || data.length === 0) {
        throw new Error('No profiles found.');
      }

      const transformedProfiles = transformProfiles(data);
        
      // Sort profiles (e.g., by experience descending)
      const sortedProfiles = transformedProfiles.sort((a, b) => {
      const expA = a.experience ? parseFloat(a.experience.split(' ')[0]) || 0 : 0;
      const expB = b.experience ? parseFloat(b.experience.split(' ')[0]) || 0 : 0;
      return expB - expA;
    });
    // console.log('[DEBUG] Sorted Profiles:', sortedProfiles);

    setOriginalProfiles(sortedProfiles);
    setFilteredProfiles(sortedProfiles); // Default filtered profiles to all profiles
    setFetchError(null); // Clear errors
    } catch (error) {
      console.error('[ERROR] Error in fetchProfiles:', error.message);
      setFetchError(error.message);
    } finally {
      setIsLoading(false);
      // console.log('[DEBUG] Fetch Profiles Completed');
    }
  };

  fetchProfiles();
}, []);

// Handle Search Logic
const handleSearch = async () => {
// console.log('[DEBUG] Starting Search with Parameters:', searchParams);

  try {
    // Build the query string from searchParams
    const queryString = Object.entries(searchParams)
      .filter(([_, value]) => value) // Filter out empty values
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    // console.log('[DEBUG] Query String:', queryString);

    // Make the API call to fetch profiles
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/talview/public-profiles?${queryString}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch profiles: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    // console.log('[DEBUG] Fetched Profiles from API:', data);
    
    if (!Array.isArray(data) || data.length === 0) {
      setFilteredProfiles([]);
      setFetchError('No profiles found for the given criteria.');
      return;
    }

    // Use the fetched data directly
    const transformedProfiles = transformProfiles(data);
    setFilteredProfiles(transformedProfiles);
    setFetchError(null); // Clear errors
  } catch (error) {
    console.error('[ERROR] Error fetching profiles:', error.message);
    setFetchError('Error fetching profiles. Please try again.');
  }
};

const viewProfile = async (profileId) => {
  const { isValid } = await validateRecruiterToken();
  if (!isValid) {
    alert('You need to login to view profiles. Write to contact@careerscentral.in for new user access');
    setShowLoginModal(true);
    return;
  }
    // If token is valid, open profile in a new tab
    window.open(`/profileViewer?profileId=${profileId}`, '_blank');
  };
    
  const toggleSearchMode = () => {
    setIsAdvancedSearch(!isAdvancedSearch);
  };
  
  if (isLoading) {
    return <p className='text-center text-gray-500'>Loading profiles...</p>;
  }

  const totalPages = Math.ceil(filteredProfiles.length / profilesPerPage);
  
  // Paginate profiles
  const paginatedProfiles = filteredProfiles.slice(
    (currentPage - 1) * profilesPerPage,
    currentPage * profilesPerPage
  );
  
  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  return (
    <div className='min-h-screen bg-[#fff9f7] text-gray-800'>
      {/* Hero Section */}
      <section className='bg-emerald-100 py-8 flex items-center justify-center relative'>
        <div className='container mx-auto px-6 absolute bottom-4 text-center'></div>
      </section>

      <div className='w-6xl mx-auto px-6'>
        {/* Tabs */}
        <div className='flex justify-center items-center'>
          <div className='max-w-4xl w-full'>
            <div className='flex gap-4 justify-center'>
              <button
                onClick={() => handleTabClick('dashboard')}
                className={`px-6 py-2 text-sm font-medium border-b-4 ${
                  activeTab === 'dashboard'
                    ? 'border-emerald-500 text-[#049c64] bg-emerald-100'
                    : 'hover:bg-emerald-200'
                }`}
              >
                {/* Abbreviation for small screens and full text for larger screens */}
                <span className='block sm:hidden'>Dashboard</span>
                <span className='hidden sm:block'>Talent Dashboard</span>
              </button>
              <button
                onClick={() => handleTabClick('manualSearch')}
                className={`px-6 py-2 text-sm font-medium border-b-4 ${
                  activeTab === 'manualSearch'
                    ? 'border-emerald-500 text-[#049c64] bg-emerald-100'
                    : 'hover:bg-emerald-200'
                }`}
              >
                {/* Abbreviation for small screens and full text for larger screens */}
                <span className='block sm:hidden'>Manual</span>
                <span className='hidden sm:block'>Manual Search</span>
              </button>
              <button
                onClick={() => handleTabClick('ats')}
                className={`px-6 py-2 text-sm font-medium border-b-4 ${
                  activeTab === 'ats'
                    ? 'border-emerald-500 text-[#049c64] bg-emerald-100'
                    : 'hover:bg-emerald-200'
                }`}
              >
                {/* Abbreviation for small screens and full text for larger screens */}
                <span className='block sm:hidden'>ATS</span>
                <span className='hidden sm:block'>
                  Automated Talent Search (ATS)
                </span>
              </button>
              <button
                onClick={() => handleTabClick('aipse')}
                className={`px-6 py-2 text-sm font-medium border-b-4 ${
                  activeTab === 'aipse'
                    ? 'border-emerald-500 text-[#049c64] bg-emerald-100'
                    : 'hover:bg-emerald-200'
                }`}
              >
                {/* Abbreviation for small screens and full text for larger screens */}
                <span className='block sm:hidden'>AIPSE</span>
                <span className='hidden sm:block'>
                  AIPS for Employers (AIPSE)
                </span>
              </button>
            </div>
          </div>
        </div>

        <hr className='relative left-1/2 right-1/2 -mx-[49vw] w-[98vw] border-t-2 border-gray-200' />

        {/* Tab Content */}
        <div className='mt-4'>
          {activeTab === 'dashboard' && (
            <div className="bg- shadow-md rounded-lg p-6">
              <TalentDashboard />
            </div>
          )}
          {activeTab === 'manualSearch' && (
            <ManualSearch 
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              handleSearch={handleSearch}
              toggleSearchMode={toggleSearchMode}
              isAdvancedSearch={isAdvancedSearch}
              setFilteredProfiles={setFilteredProfiles}
              originalProfiles={originalProfiles}
            />
          )}
          {activeTab === 'ats' && (
            <ATS
              setFilteredProfiles={setFilteredProfiles}
              originalProfiles={originalProfiles}
              isRecruiterLoggedIn={isRecruiterLoggedIn}
              setShowLoginModal={setShowLoginModal}
              handleLoginSuccess={handleLoginSuccess}
            />
          )}
          {activeTab === 'aipse' && <ComingSoon />}
        </div>

        {/* Profiles Section */}
        {(activeTab === 'manualSearch' || activeTab === 'ats') && (
        <div className='mt-2 mb-20'>
          <div className='flex justify-between items-center mb-2'>
          <p className='text-xs text-gray-600 ml-auto'>{filteredProfiles.length} Profiles Found</p>
          </div>

          {fetchError ? (
            <p className='text-center text-red-500'>{fetchError}</p>
          ) : (
          <div>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {paginatedProfiles.map((profile) => (
              <div
                key={profile.id}
                className='border rounded-lg p-4 bg-white shadow-md hover:shadow-lg'
              >
                {/* Profile Card Details */}
                <div className='flex justify-between items-center mb-4'>
                <h3 className='font-bold text-base text-[#049c64]'>
                  {isRecruiterLoggedIn ? profile.name : 'Name Hidden'}
                </h3>
                {/* View Profile Button */}
                <button
                  className='px-3 py-1 text-white text-xs bg-[#049c64] rounded-md hover:bg-emerald-700'
                  onClick={() => viewProfile(profile.id)}
                >
                  View Profile
                </button>
                </div>

                {showLoginModal && (
                  <RecruiterLoginModal
                    onClose={() => setShowLoginModal(false)}
                    onLoginSuccess={handleLoginSuccess}
                  />
                )}

                <p className='text-xs text-gray-500'>
                  {profile.overallExperience} years of Overall Work Experience
                </p>

                {/* Employment Type */}
                <p className='text-xs text-gray-500'>
                  <strong>Emp. Type:</strong> Looking for {profile.employmentType}
                </p>

                {/* Preferred Locations */}
                <p className='text-xs text-gray-500'>
                  <strong>Preferred:</strong> {profile.preferredLocations}
                </p>

                {/* Age */}
                <p className='text-xs text-gray-500'>
                  <strong>Age:</strong> {profile.age}
                </p>

                {/* Notice Period */}
                <p className='text-xs text-gray-500'>
                  <strong>NP:</strong> {profile.noticePeriod} days
                </p>

                {/* Education */}
                <p className='text-xs text-gray-500'>
                  <strong>Education:</strong> {profile.education}
                </p>

                {/* Technical Skills */}
                <p className='text-xs text-gray-500'>
                  <strong>Skills:</strong> {profile.technicalSkills}
                </p>
              
                {/* Profile Summary */}
                <p className='text-xs text-gray-500 mt-2 truncate-lines'
                  title={profile.profileSummary} // Tooltip to show the full summary on hover
                >
                  {profile.profileSummary}
                </p>
              </div>
            ))}
          </div>
          
        {/* Pagination Controls */}
        <div className='flex items-center justify-center mt-6'>
        {/* Prev Button */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className={`px-3 py-1 mx-1 text-sm ${
            currentPage === 1
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-[#049c64] hover:bg-emerald-200'
          }`}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        {/* Page Numbers */}
        {[...Array(totalPages).keys()]
          .map((page) => page + 1) // Page numbers start from 1
          .filter((page) => page >= currentPage && page < currentPage + maxVisiblePages) // Show limited pages
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 mx-1 text-sm ${
                page === currentPage
                  ? 'bg-[#049c64] text-white'
                  : 'text-gray-600 hover:bg-emerald-200'
              }`}
            >
              {page}
            </button>
          ))}

        {/* Next Button */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className={`px-3 py-1 mx-1 text-sm ${
            currentPage === totalPages
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-[#049c64] hover:bg-emerald-200'
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        </div>
        </div>
        )}
        </div>
        )}
      </div>
    </div>
  );
};

export default TalHunt;