// src/components/iSearchBar.js

import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import { searchJobs } from '../api/keywordApi';
import { getWebSocketClient } from '../api/webSocketClient';

const resultsPerPage = 40; // 5 Colms * 8 Rows Refer App.css - .results-grid {

const iSearchBar = forwardRef((props, ref) => {
  const [keyword, setKeyword] = useState('');
  const [broadcastResults, setBroadcastResults] = useState([]); // Store results from WebSocket broadcasts
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const ws = getWebSocketClient();

    ws.onopen = () => {
      // console.log('[WebSocket] Connection opened.');
    };

    ws.onmessage = (event) => {
      try {
        const parsedResult = JSON.parse(event.data);
    
        if (parsedResult && parsedResult.companyName && parsedResult.searchURL) {
          setBroadcastResults((prevResults) => [...prevResults,{company: parsedResult.companyName,url: parsedResult.searchURL}]);
        } else {
          // console.warn('[WebSocket] Received invalid data:', parsedResult);
        }
      } catch (error) {
        console.error('[WebSocket] Failed to parse message:', error, event.data);
      }
    };    

    ws.onclose = () => {
      // console.log('[WebSocket] Connection closed.');
    };

    ws.onerror = (error) => {
      console.error('[WebSocket] Error:', error);
    };
}, []);
  
  const handleSearch = async (searchKeyword = null) => {
    const searchTerm = searchKeyword || keyword;
    if (searchTerm.trim()) {
      setLoading(true);
      
    // Clear existing results before initiating a new search
    setBroadcastResults([]);
    setMessage(''); // Clear any previous messages

      try {
        // Fetch search results
        const searchResults = await searchJobs(searchTerm);
        setBroadcastResults(searchResults || []); // Set to an empty array if searchResults is undefined
        setCurrentPage(1); // Reset to the first page on new search
      } catch (error) {
        console.error('[ERROR] Error during search:', error);
        setMessage('Error occurred during search');
        setBroadcastResults([]); // Ensure results are reset to an empty array on error
      }
      setLoading(false);
      setKeyword('');
    }
  };

  useImperativeHandle(ref, () => ({
    handleTriggerSearch: (keyword) => {
      setKeyword(keyword);
      handleSearch(keyword);
    },
  }));

  const paginatedResults = broadcastResults.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

  const handleNextPage = () => {
      if (currentPage < Math.ceil(broadcastResults.length / resultsPerPage)) {
          setCurrentPage(currentPage + 1);
      }
  };

  const handlePrevPage = () => {
      if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
      }
  };

  return (
    <div className='w-full'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
        className='sm:grid sm:grid-cols-12 gap-4 mt-6'
      >
        {/* Search Bar */}
        <div className='sm:col-span-10 relative'>
          <div className='absolute inset-y-0 left-3 flex items-center'>
            <svg
              className='h-5 w-5 text-[#049c64]'
              fill='currentColor'
              viewBox='0 0 20 20'
            >
              <path
                fillRule='evenodd'
                d='M12.9 14.32a8 8 0 1 1 1.42-1.42l5.38 5.37-1.42 1.42-5.38-5.37Zm-5.9-.32a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z'
                clipRule='evenodd'
              />
            </svg>
          </div>
        <input
          type='text'
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder='Search By Keywords (Role, Skill, JobTitle, Occupation ...)'
          className='w-full pl-10 pr-3 py-2 bg-emerald-100 border-0 rounded-md focus:ring-2 focus:ring-emerald-500 placeholder-[#049c64] font-medium'
        />
        </div>
        
        {/* Search Button */}
        <div className='sm:col-span-2 mt-2 sm:mt-0'>
          <button className='w-full bg-white border border-[#049c64] border-solid text-[#049c64] px-4 py-2 rounded-md hover:bg-emerald-50'>
            Search
          </button>
        </div>
      </form>

      {loading && (
        <div className='w-full h-1 bg-green-500 mt-3 animate-pulse'></div>
      )}

      {message && <p className='text-red-500 text-center mt-3'>{message}</p>}

      <div className='mt-5'>
        <h3 className='text-xl font-bold text-gray-800 mb-4'>Search Results</h3>
        {paginatedResults.length > 0 ? (
          <div className='grid grid-cols-3 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-y-2.5 gap-x-4'>
            {paginatedResults.map((result, index) => (
              <div
                className='py-0.5 text-xs bg-gray-100 hover:bg-emerald-100 transition cursor-pointer'
                key={index}
              >
                <a
                  href={result.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-gray-700 font-medium text-xs pl-2'
                >
                  {result.company}
                </a>
              </div>
            ))}
          </div>
        ) : (
          <p className='text-center text-gray-500'>No jobs found</p>
        )}

        <div className='flex justify-between items-center mt-5'>
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className='px-4 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-emerald-500 transition'
          >
            Prev
          </button>
          <span className='text-gray-600'>
            Page {currentPage} of{' '}
            {broadcastResults.length > 0
              ? Math.ceil(broadcastResults.length / resultsPerPage)
              : 1}
          </span>
          <button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(broadcastResults.length / resultsPerPage)
            }
            className='px-4 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-emerald-500 transition'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
});

export default iSearchBar;