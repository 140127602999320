import React from 'react';
import { Book } from 'lucide-react';

const EducationDetails = ({ editable, editData, handleChange, setEditData }) => {
  const handleAddEducation = () => {
    if ((editData.educationDetails.records || []).length >= 2) return;
    const updatedRecords = [...(editData.educationDetails.records || [])];
    updatedRecords.push({
      highestQualification: '',
      specialization: '',
      grade: '',
      university: '',
      graduationYear: '',
    });
    setEditData((prevState) => ({
      ...prevState,
      educationDetails: { ...prevState.educationDetails, records: updatedRecords },
    }));
  };

  const handleAddCertification = () => {
    if ((editData.educationDetails.certifications || []).length >= 2) return;
    const updatedCertifications = [...(editData.educationDetails.certifications || [])];
    updatedCertifications.push({
      name: '',
      issuedBy: '',
      issueYear: '',
    });
    setEditData((prevState) => ({
      ...prevState,
      educationDetails: { ...prevState.educationDetails, certifications: updatedCertifications },
    }));
  };

  const handleRemoveEducation = (index) => {
    const updatedRecords = [...editData.educationDetails.records];
    updatedRecords.splice(index, 1);
    setEditData((prevState) => ({
      ...prevState,
      educationDetails: { ...prevState.educationDetails, records: updatedRecords },
    }));
  };

  const handleRemoveCertification = (index) => {
    const updatedCertifications = [...editData.educationDetails.certifications];
    updatedCertifications.splice(index, 1);
    setEditData((prevState) => ({
      ...prevState,
      educationDetails: { ...prevState.educationDetails, certifications: updatedCertifications },
    }));
  };

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-lg'>
          <Book className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600'>Education Details</span>
        </h3>
        {editable && (
          <div className='flex space-x-4'>
            <button
              onClick={handleAddEducation}
              className={`px-3 py-1 text-xs rounded-md ${
                (editData.educationDetails.records || []).length >= 2
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-[#049c64] text-white'
              }`}
              disabled={(editData.educationDetails.records || []).length >= 2}
            >
              Add Education
            </button>
            <button
              onClick={handleAddCertification}
              className={`px-3 py-1 text-xs rounded-md ${
                (editData.educationDetails.certifications || []).length >= 2
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-[#049c64] text-white'
              }`}
              disabled={(editData.educationDetails.certifications || []).length >= 2}
            >
              Add Certification
            </button>
          </div>
        )}
      </div>

      <div className='p-4 relative sm:p-4'>
        {/* Education Records */}
        {editData.educationDetails?.records?.length > 0 ? (
          editData.educationDetails.records.map((record, index) => (
          <div key={index} className='border border-gray-200 p-3 mt-2 mb-2 rounded-md'>
            <h4 className='text-sm font-medium text-gray-800 mb-2'>
              Education {index + 1}
            </h4>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6'>
              {[
                { label: 'Highest Qualification', field: 'highestQualification' },
                { label: 'Specialization', field: 'specialization' },
                { label: 'Percentage / Grade', field: 'grade' },
                { label: 'College / Institute', field: 'university' },
                { label: 'Graduation Year', field: 'graduationYear' },
              ].map(({ label, field }) => (
                <div key={field}>
                  <p className='text-xs text-gray-600 mb-1'>
                    {label} <span className='text-red-500'> *</span>
                  </p>
                  <input
                    type='text'
                    name={field}
                    data-parent-key='educationDetails.records'
                    data-array-index={index}
                    value={record[field] || ''}
                    onChange={handleChange}
                    placeholder={`Enter ${label}`}
                    disabled={!editable}
                    className='font-medium text-xs p-1 border border-gray-300 bg-none placeholder-gray-400 w-full'
                  />
                </div>
              ))}
            </div>
            {editable && (
              <div className='col-span-4 flex justify-end'>
                <button
                  onClick={() => handleRemoveEducation(index)}
                  className='text-xs text-red-600 hover:text-red-800 mt-2 flex justify-end'
                >
                  Remove Education
                </button>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className='col-span-4'>
          <p className='text-sm text-gray-600 mt-3'>
            No education records found. You can add up to 2 educational qualifications.
          </p>
        </div>
      )}

      {/* Certifications */}
      {editData.educationDetails?.certifications?.length > 0 ? (
      editData.educationDetails.certifications.map((cert, index) => (
        <div key={index} className='border border-gray-200 p-3 mt-2 mb-2 rounded-md'>
          <h4 className='text-sm font-medium text-gray-800 mb-2'>
            Certification {index + 1}
          </h4>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6'>
            {[
              { label: 'Course', field: 'name' },
              { label: 'Issued By', field: 'issuedBy' },
              { label: 'Issue Year', field: 'issueYear' },
            ].map(({ label, field }) => (
              <div key={field}>
                <p className='text-xs text-gray-600 mb-1'>
                  {label} <span className='text-red-500'> *</span>
                </p>
                <input
                  type='text'
                  name={field}
                  data-parent-key='educationDetails.certifications'
                  data-array-index={index}
                  value={cert[field] || ''}
                  onChange={handleChange}
                  placeholder={`Enter ${label}`}
                  disabled={!editable}
                  className='font-medium text-xs p-1 border border-gray-300 bg-none placeholder-gray-400'
                />
              </div>
            ))}
          </div>

          {/* Remove Certification Button */}
          {editable && (
            <div className='col-span-4 flex justify-end mt-4'>
              <button
                onClick={() => handleRemoveCertification(index)}
                className='text-xs text-red-600 hover:text-red-800 mt-2 flex justify-end'
              >
                Remove Certification
              </button>
            </div>
          )}
        </div>
      ))
    ) : null /* No fallback message for certifications */}
      </div>
    </div>
  );
};

export default EducationDetails;