// src/pages/register/register.jsx

import { Phone, CheckCircle, XCircle } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import topRight from '../../Images/Ellipse 3.png';
import bottomImage from '../../Images/RightBottom2.png';
import topImage from '../../Images/TopRight2.png';
// import apple from '../../Images/apple.png';
import bottomRight from '../../Images/bottomRight.png';
// import facebook from '../../Images/facebook.png';
// import google from '../../Images/google.png';
import mailIcon from '../../Images/icons/email.png';
import passwordIcon from '../../Images/icons/password.png';
import logo from '../../Images/logo.png';
import './register.css';

const passwordCriteria = [
  { label: 'Min. 1 uppercase letter', regex: /[A-Z]/ },
  { label: 'Min. 1 lowercase letter', regex: /[a-z]/ },
  { label: 'Min. 1 number', regex: /\d/ },
  { label: 'Min. 8 characters', regex: /^.{8,}$/ },
  { label: 'Min. 1 special character', regex: /[!@#$%^&*]/ },
];

const validateEmail = (email) => {
  // const emailRegex = /^\S+@\S+\.\S+$/; // Simple email format validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|outlook\.com|yahoo\.com)$/i; // Restrict to gmail.com or outlook.com or yahoo.com
  return emailRegex.test(email);
};

const validateMobile = (mobile) => {
  const mobileRegex = /^[0-9]{10}$/; // Regex for exactly 10 digits
  return mobileRegex.test(mobile);
};

const Index = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [criteriaMet, setCriteriaMet] = useState({});
  
    useEffect(() => {
      const validationResults = {};
      passwordCriteria.forEach((criterion) => {
        validationResults[criterion.label] = criterion.regex.test(password);
      });
      setCriteriaMet(validationResults);
    }, [password]);
  
    const isAllCriteriaMet = Object.values(criteriaMet).every((met) => met);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setResponseMessage('');
    setIsPopupVisible(false);
    
    console.clear(); // Clear previous logs for fresh output
    // console.log('[INFO] Initiating registration process...');
    // console.log('[DEBUG] Input Data:', { email, password, confirmPassword, mobile });

    // Frontend Validations
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!validateMobile(mobile)) {
      setError('Mobile number must be exactly 10 digits and numerical only.');
      return;
    }

    if (!isAllCriteriaMet) {
      setError('Please meet all password criteria before proceeding.');
      return;
    }

    if (password !== confirmPassword) {
      console.error('[ERROR] Passwords do not match.');
      setError('Passwords do not match.');
      return;
    }

    if (!agreedToTerms) {
      console.error('[ERROR] Terms not agreed.');
      setError('You must agree to the Terms and Conditions.');
      return;
    }  

    // Extract numeric part of mobile number
    const sanitizedMobileNumber = mobile.replace(/^(\+91\s?)/, '').trim();
    // console.log('[DEBUG] Sanitized Mobile Number:', sanitizedMobileNumber);

    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/auth/register`;
      const data = {
        email: email.trim(),
        password: password.trim(),
        mobile: sanitizedMobileNumber,
        agreedToTerms,
      };

      // console.log('[DEBUG] API Endpoint:', endpoint);
      // console.log('[DEBUG] Sending Registration Data:', data);

      // const response = await axios.post(endpoint, data);
      await axios.post(endpoint, data);
      // await axios.post(endpoint, data);
      
      // Show popup with success message
      setResponseMessage('Registration Successful. Please login to access your account.');
      setIsPopupVisible(true);
      // console.log('[INFO] Registration successful. Response:', response.data);

      // Redirect to login page after a short delay
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    } catch (err) {
      console.error('[ERROR] Registration failed:', err);
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
    }
  };

  return (
    <div
      className='min-h-screen w-full p-2 md:p-4 flex items-center justify-center'
      style={{
        background: 'linear-gradient(to right, #049D65 50%, #E0E1EF 50%)',
        overflow: 'hidden', // Prevent scrolling at higher zoom levels
      }}
    >
      {/* Top right background image */}
      <img
        src={topRight}
        alt='Top Right Background'
        className='absolute top-0 right-0'
        style={{
          width: '16%', // Reduced size for better fit
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      {/* Bottom right background image */}
      <img
        src={bottomRight}
        alt='Bottom Right Background'
        className='absolute bottom-0 right-0'
        style={{
          width: '18%', // Reduced size for better fit
          height: 'auto',
          objectFit: 'cover',
        }}
      />

      <div
        className='w-full lg:max-w-[900px] lg:max-h-[600px] mx-auto rounded-2xl overflow-hidden'
        style={{
          boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <div className='flex flex-col md:flex-row'>
          {/* Left Section */}
          <div
            className='relative p-3 md:p-5 md:w-1/2 overflow-hidden'
            style={{
              background: '#45AE89',
            }}
          >
            <img
              src={topImage}
              alt=''
              className='w-2/3 hidden md:block h-auto object-cover absolute left-1/2 -translate-x-1/2 sm:left-auto sm:right-4 sm:top-4 sm:translate-x-0'
            />
            <img
              src={bottomImage}
              alt=''
              className='w-2/3 h-auto hidden md:block object-cover absolute bottom-0 left-8 xl:ml-4 sm:left-4 sm:bottom-4'
            />

            <div className='relative h-full flex flex-col justify-center py-3 lg:px-[15%] xl:pl-[15%] md:py-0 sm:px-[15%]'>
              <h1
                className='md:text-2xl lg:text-4xl sm:text-2xl text-white font-bold leading-tight mb-2 text-center md:text-left'
                style={{
                  textShadow: '0 3px 6px rgba(0,0,0,0.1)',
                }}
              >
                Dream Big,
                <br />
                Aim High
              </h1>
              <p className='text-white text-sm md:text-md text-center md:text-left opacity-80'>
                Every Journey Starts with a First Click!
              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className='relative p-3 md:p-5 md:w-1/2 bg-[#f5f6fa]'>
            <div className='max-w-sm mx-auto relative'>
              <div className='text-center mb-4 md:mb-5'>
                <div className='flex flex-col items-center'>
                  <img
                    src={logo}
                    alt='Careers Central'
                    className='w-auto h-auto max-w-[180px]' // Reduced max width for smaller view
                  />
                </div>
              </div>

              <h2 className='text-sm md:text-md font-semibold text-center mb-3 md:mb-4'>
                Your Career Adventure Begins Now!
              </h2>

              <form onSubmit={handleRegister} className='space-y-3'>
                <div className='relative'>
                  <label className='text-gray-600 mb-1 block text-xs md:text-sm'>
                  Email Address<span className='text-red-500'> *</span>
                  </label>
                  <div className='relative'>
                    <img
                      src={mailIcon}
                      alt='Mail Icon'
                      className='w-4 h-4 absolute left-2 top-2.5'
                    />
                    <input
                      type='email'
                      placeholder='Enter Email Address'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='w-full pl-8 pr-3 py-1.5 text-xs md:text-sm placeholder:text-xxs border border-gray-200 border-solid rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent'
                      required
                    />
                  </div>
                </div>
                
                <div className='relative'>
                  <label className='mb-2 block lg:text-sm'>
                    Mobile Number <span className='text-red-500'>*</span>
                  </label>
                  <div className='flex gap-1'>
                    <select className='lg:text-sm border border-gray-200 pl-2 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent w-20'>
                      <option className='#979797 '>+91</option>
                    </select>
                    <div className='relative flex-1'>
                      <Phone className='w-4 h-4 absolute left-3 top-1.5 icon-green' />
                      <input
                        type='tel'
                        placeholder='Enter your Mobile Number'
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        className='w-full pl-10 pr-4 py-1 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent text-sm placeholder:text-xs'
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className='relative'>
                  <label className='text-gray-600 mb-1 block text-xxs md:text-sm'>
                    Password <span className='text-red-500'>*</span>
                  </label>
                  <div className='relative'>
                    <img
                      src={passwordIcon}
                      alt='Password Icon'
                      className='w-4 h-4 absolute left-2 top-2.5'
                    />
                    <input
                      type='password'
                      placeholder='******************'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='w-full pl-8 pr-3 py-2 text-xs md:text-sm border border-gray-200 border-solid rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent'
                      required
                    />
                  </div>
                  <div className='mt-2 password-validation'>
                    {passwordCriteria.map((criterion) => (
                      <div key={criterion.label} className='validation-item'>
                        {criteriaMet[criterion.label] ? (
                          <CheckCircle className='text-green-500 w-2 h-2' />
                        ) : (
                          <XCircle className='text-red-500 w-2 h-2' />
                        )}
                        <span className='text-xs'>{criterion.label}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className='relative'>
                  <label className='mb-2 block lg:text-sm'>
                    Confirm Password <span className='text-red-500'>*</span>
                  </label>
                  <div className='relative'>
                    <img
                      src={passwordIcon}
                      alt='User Icon'
                      className='w-4 h-4 absolute left-3 top-1.5 '
                    />
                    <input
                      type='password'
                      placeholder='Confirm Password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className='w-full pl-10 pr-4 py-1 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent text-sm placeholder:text-xs'
                      required
                    />
                  </div>
                </div>
                
                <div className='flex items-start gap-2 [&>input]:accent-[#049c64]'>
                  <input
                    type='checkbox'
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                    className='w-3 h-3 checked:bg-[#049c64] checked:border-[#049c64] focus:ring-[#049c64] focus:ring-offset-0 mt-0.5'
                    style={{ accentColor: '#049c64' }}
                  />
                  <span className='text-xxs'>
                    I agree to{' '}
                    <a href='/' className='text-[#049c64] hover:underline'>
                      Terms and Conditions
                    </a>{' '}
                    &{' '}
                    <a href='/' className='text-[#049c64] hover:underline'>
                      Privacy Policy
                    </a>{' '}
                    governing the use
                  </span>
                </div>

                {error && <p className='text-red-500 text-xs'>{error}</p>}

                <button
                  type='submit'
                  className='w-full bg-[#049c64] text-white py-2 rounded-md text-sm hover:bg-emerald-500 transition-colors'
                  disabled={!isAllCriteriaMet}
                >
                  Create Account
                </button>

                <div className='relative text-center'>
                  <div className='absolute top-1/2 w-full h-px bg-gray-200' />
                  <span className='relative bg-[#f5f6fa] px-3 text-xs text-gray-500'>
                    or
                  </span>
                </div>
{/*
                <div className='flex justify-center space-x-3'>
                  <button className='p-1.5 border border-gray-200 rounded-md hover:bg-emerald-100'>
                    <img src={google} alt='Google' className='h-4 w-4' />
                  </button>
                  <button className='p-1.5 border border-gray-200 rounded-md hover:bg-emerald-100'>
                    <img src={facebook} alt='Facebook' className='h-4 w-4' />
                  </button>
                  <button className='p-1.5 border border-gray-200 rounded-md hover:bg-emerald-100'>
                    <img src={apple} alt='Apple' className='h-4 w-4' />
                  </button>
                </div>
*/}
                <div className='text-center text-gray-600 text-xs'>
                  Already have an account?{' '}
                  <a
                    href='/login'
                    className='text-[#049c64] hover:underline'
                  >
                    Login Here
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Notification */}
      {isPopupVisible && (
        <div
          className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
          onClick={() => setIsPopupVisible(false)}
        >
          <div className='bg-white rounded-lg p-6 shadow-lg'>
            <h2 className='text-green-500 font-bold'>Success!</h2>
            <p>{responseMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;