import React, { useContext, useRef, useState, useEffect } from "react";
import { 
  Home, Briefcase, Search, User, Menu, Info, Mail, Layers, LogIn, LogOut, UserCheck,  //Bookmark,
} from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import "./BottomNavBar.css";

const BottomNavBar = () => {
  const { isLoggedIn, logout } = useContext(UserContext); // Get login state and logout function
  const navigate = useNavigate();
  const location = useLocation();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const moreMenuRef = useRef(null);

  const isActive = (path) => location.pathname === path;

  const toggleMoreMenu = () => {
    setIsMoreMenuOpen(!isMoreMenuOpen);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setIsMoreMenuOpen(false); // Close the menu immediately after clicking
  };

  const handleOutsideClick = (e) => {
    if (moreMenuRef.current && !moreMenuRef.current.contains(e.target)) {
      setIsMoreMenuOpen(false);
    }
  };

  // Log changes to isLoggedIn
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isLoggedIn]);

  return (
    <div className="fixed-bottom-nav">
      <button
        onClick={() => navigate("/")}
        className={isActive("/") ? "text-emerald-600" : "text-gray-600"}
      >
        <Home className="w-6 h-6 mx-auto" />
        <span>Home</span>
      </button>

      <button
        onClick={() => navigate("/jobs")}
        className={isActive("/jobs") ? "text-emerald-600" : "text-gray-600"}
      >
        <Briefcase className="w-6 h-6 mx-auto" />
        <span>Jobs</span>
      </button>

      <button
        onClick={() => navigate("/iSearch")}
        className={isActive("/iSearch") ? "text-emerald-600" : "text-gray-600"}
      >
        <Search className="w-6 h-6 mx-auto" />
        <span>iSearch</span>
      </button>

      <button
        onClick={() => navigate("/talhunt")}
        className={isActive("/talhunt") ? "text-emerald-600" : "text-gray-600"}
      >
        <User className="w-6 h-6 mx-auto" />
        <span>TalHunt</span>
      </button>

      <div className="relative" ref={moreMenuRef}>
        <button
          onClick={toggleMoreMenu}
          className={isMoreMenuOpen ? "text-emerald-600" : "text-gray-600"}
        >
        <Menu className="w-6 h-6 mx-auto" />
        <span>More</span>
      </button>
        {isMoreMenuOpen && (
          <div className="absolute bottom-full right-0 mb-2 w-16 bg-white rounded-lg shadow-lg">
            <ul className="list-none m-0 p-2 flex flex-col items-center gap-2">
              <li>
                <button 
                  className="flex flex-col items-center justify-center w-full hover:bg-gray-100"
                  onClick={() => handleMenuItemClick("/aboutus")}
                >
                  <Info className="w-6 h-6" />
                  <span>About</span>
                </button>
              </li>
              <li>
                <button 
                  className="flex flex-col items-center justify-center w-full hover:bg-gray-100"
                  onClick={() => handleMenuItemClick("/contactus")}
                >
                  <Mail className="w-6 h-6" />
                  <span>Contact</span>
                </button>
              </li>
              <li>
                <button 
                  className="flex flex-col items-center justify-center w-full hover:bg-gray-100"
                  onClick={() => handleMenuItemClick("/aips")}
                >
                  <Layers className="w-6 h-6" />
                  <span>AIPS</span>
                </button>
              </li>
              {isLoggedIn ? (
                <>
                  <li>
                    <button 
                      className="flex flex-col items-center justify-center w-full hover:bg-gray-100"
                      onClick={() => handleMenuItemClick("/profile")}
                    >
                      <UserCheck className="w-6 h-6" />
                      <span>Profile</span>
                    </button>
                  </li>
                  {/*
                  <li>
                    <button 
                      className="flex flex-col items-center justify-center w-full hover:bg-gray-100"
                      onClick={() => handleMenuItemClick("/savedJobs")}
                    >
                      <Bookmark className="w-6 h-6" />
                      <span>Saved</span>
                    </button>
                  </li>
                  */}
                  <li>
                    <button
                      className="flex flex-col items-center justify-center w-full hover:bg-gray-100 text-red-500"
                        onClick={() => {
                          logout();
                          setIsMoreMenuOpen(false); // Ensure menu closes after logout
                        }}
                      >
                      <LogOut className="w-6 h-6 text-red-600" />
                      <span>Logout</span>
                    </button>
                  </li>
                </>
              ) : (
                <li>
                  <button 
                    className="flex flex-col items-center justify-center w-full hover:bg-gray-100 text-emerald-600"
                    onClick={() => handleMenuItemClick("/login")}
                  >
                    <LogIn className="w-6 h-6 text-emerald-600" />
                    <span>Login</span>
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomNavBar;