import React, { useEffect, useRef } from 'react';
import { Clipboard, Info } from 'lucide-react';

const ProfileSummary = ({ editable, editData, profile, handleChange }) => {
const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust height dynamically
    }
  }, [editable, editData.profileSummary, profile.profileSummary]); // Adjust height whenever the content or mode changes

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-lg'>
          <Clipboard className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600 text-base'>Profile Summary</span>
          {/* Tooltip Icon */}
          <span className='group relative'>
            <Info className='w-4 h-4 text-gray-400 cursor-pointer' />
            <span className='absolute hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md w-64 -top-10 left-1/2 transform -translate-x-1/2 z-10'>
            Update your key professional skills and experience. Use AI tools to extract key skills from your CV and update this section. For freshers, type 'Fresher, 0-2 Years, Graduation Year: 20xx'.
            </span>
          </span>
        </h3>
      </div>

      <div className='p-4 relative sm:p-4'>
        <textarea
        ref={textareaRef}
          name='profileSummary'
          value={editable ? editData.profileSummary || '' : profile.profileSummary || ''}
          onChange={(e) => {
            if (e.target.value.length <= 500) {
              handleChange(e);
            }
          }}
          placeholder="Update your key professional skills and experience. Use AI tools to extract key skills from your CV and update this section. For freshers, type 'Fresher, 0-2 Years, Graduation Year: 20xx'."
          maxLength={500} // Restricts input to 500 characters
          disabled={!editable}
          onInput={(e) => {
            e.target.style.height = 'auto'; // Reset height
            e.target.style.height = `${e.target.scrollHeight}px`; // Set height dynamically based on content
          }}
          className={`w-full font-medium text-xs p-1 border ${
            editable ? 'border-gray-300' : 'border-transparent'
          } bg-none placeholder-gray-400 resize-none`} // Ensure users cannot manually resize
          style={{ overflowY: 'hidden' }} // Hide scrollbars
        />
        {/* Character Counter */}
        <div className='text-right text-xs text-gray-500 mt-1'>
          {editable ? `${editData.profileSummary?.length || 0}/500` : ''}
        </div>
      </div>
    </div>
  );
};

export default ProfileSummary;