// src/pages/jobs/Jobs.jsx

import { MapPin, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import LeftJobList from "./LeftJobList";

const Jobs = () => {
  // const [selectedJob, setSelectedJob] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [jobs, setJobs] = useState([]); // All jobs fetched from the backend
  const [filteredJobs, setFilteredJobs] = useState([]); // Filtered jobs based on search
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const jobsPerPage = 30; // Number of jobs per page
  const maxVisiblePages = 5; // Maximum number of visible pages
  const [searchKeyword, setSearchKeyword] = useState(""); // Input for keyword search
  const [searchLocation, setSearchLocation] = useState(""); // Input for Location search
  const [fetchError, setFetchError] = useState(false);
  const [minExp, setMinExp] = useState(""); // Input for minimum experience

  // Handle responsive behavior
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // 1024px is the lg breakpoint
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch jobs from the backend
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jobs`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        // console.log("Fetched jobs:", data);

        const transformedJobs = data.map((job) => ({
          id: job.Job_ID || "N/A",
          ccid: job.CCID || "N/A",
          title: job.Job_Title || "N/A",
          company: job.Company || "N/A",
          experience: `${
            job.Min_Exp !== null && job.Min_Exp !== undefined ? job.Min_Exp : "NS"
          } - ${
            job.Max_Exp !== null && job.Max_Exp !== undefined ? job.Max_Exp : "NS"
          } Yrs`,
          location: job.Location || "N/A",
          minSalary: job.Min_Salary ? Number(job.Min_Salary) : "NS",
          maxSalary: job.Max_Salary ? Number(job.Max_Salary) : "NS",
          minExp: job.Min_Exp !== null && job.Min_Exp !== undefined ? job.Min_Exp : 0,
          maxExp: job.Max_Exp !== null && job.Max_Exp !== undefined ? job.Max_Exp : 0,
          department: job.Function || "N/A",
          postedOn: job.Posted_On
            ? new Date(job.Posted_On)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "2-digit",
              })
              .replace(/ /g, "-") // Replace spaces with "-"
            : "N/A",            
          ccUpdateDate: job.CC_Updated_On
            ? new Date(job.CC_Updated_On).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "2-digit",
                })
                .replace(/ /g, "-") // Replace spaces with "-"
            : "N/A",
            summary: job.Job_Summary || "No Summary Available", // Use for display in card
          keyword: job.Keywords || "No Keywords Available", // Use for display in card
          searchParams: job.Search_Params || "No Search Data Available", // Use for search functionality
          url: job.URL || "URL Not Found",
        }));
        // Sort jobs by CCID in descending order
        const sortedJobs = transformedJobs.sort((a, b) => b.ccid.localeCompare(a.ccid));

        setJobs(sortedJobs); // Save sorted jobs
        setFilteredJobs(sortedJobs); // Initialize filtered jobs with sorted jobs
        setFetchError(false); // Reset error
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setFetchError(true); // Set error state
      }
    };

    fetchJobs();
  }, []); // Runs only once when the component mounts

  // Calculate total pages
  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

  // Get jobs for the current page
  const paginatedJobs = filteredJobs.slice(
    (currentPage - 1) * jobsPerPage,
    currentPage * jobsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle search functionality for Keyword, CCID, and Location
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      let filtered = jobs;

      // Reset to all jobs when no search criteria are provided
      if (
        !searchKeyword.trim() && 
        !searchLocation.trim() &&
        !minExp
      ) {
        setFilteredJobs(jobs);
        return; // Exit early
      }
      
      // Filter by multiple keywords
      if (searchKeyword.trim()) {
        const keywords = searchKeyword
          .split(",") // Split by commas
          .map((keyword) => keyword.trim().toLowerCase()) // Trim and normalize
          .filter((keyword) => keyword.length > 0); // Remove empty strings

        filtered = filtered.filter((job) =>
          keywords.some((keyword) =>
            job.searchParams?.toLowerCase().includes(keyword)
          )
        );
      }

      // Filter by location (multiple cities) including partial matches
      if (searchLocation.trim()) {
        const cities = searchLocation
        .split(",") // Split by comma
        .map((city) => city.trim().toLowerCase()) // Trim and normalize
        .filter((city) => city.length > 0); // Remove empty strings

        filtered = filtered.filter((job) => {
          const jobLocation = job.location?.toLowerCase(); // Normalize job location
          return cities.some((city) => jobLocation.includes(city)); // Partial match anywhere
        });
      }

      // Filter by minimum and maximum experience
      if (minExp !== "") {
        filtered = filtered.filter((job) => {
          // Handle NS values: Treat minExp as 0 and maxExp as Infinity if they are "NS"
          let jobMinExp = job.minExp === "NS" ? 0 : parseFloat(job.minExp);
          let jobMaxExp = job.maxExp === "NS" ? jobMinExp : parseFloat(job.maxExp);
      
          // Default search values: minExp = 0, maxExp = Infinity
          const searchExp = minExp === "" ? 0 : parseFloat(minExp);
          
          // Check if the job range overlaps with the search range
          const isMatch =
          jobMinExp <= searchExp && jobMaxExp >= searchExp;
      
          return isMatch;
        });
      }
      setFilteredJobs(filtered);
    }, 300); // Debounce for 300ms

    return () => clearTimeout(delayDebounce); // Cleanup debounce
  }, [searchKeyword, searchLocation, minExp, jobs]);

  return (
    <div className="max-w-full mt-[16%] sm:mt-[8%]  md:mt-[8%] lg:mt-[6%] xl:mt-[5%]">
      <hr className="relative left-1/2 right-1/2 -mx-[49vw] w-[98vw] border-t-2 border-gray-200 mt-4" />
      
      {/* Display fetch error */}
      {fetchError && (
          <div className="text-red-500 text-center">Failed to load jobs. Please try again later.</div>
      )}

      {/* Search Section */}
        <div className="flex flex-wrap gap-3 py-3 px-[5%] mt-1">
        {/* Search Keywords */}
        <div className="w-full relative">
          <div className="absolute inset-y-0 left-3 flex items-center">
            <Search className="h-5 w-5 text-[#049c64]" />
          </div>
          <input
            type="text"
            placeholder="Search by Keywords, Skills, Job Title, Role..."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            className="text-xs w-full pl-10 pr-3 py-2 bg-emerald-100 border-0 rounded-md focus:ring-2 focus:ring-emerald-500 placeholder-[#049c64] font-medium"
          />
        </div>
      
        {/* Work Experience */}
        <div className="w-full flex gap-2">
          <div className="relative w-[28%]">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <span className="text-[#049c64] font-bold text-xs">Exp</span>
            </div>
            <input
              type="number"
              placeholder="Yrs"
              min='0'
              value={minExp}
              onChange={(e) => setMinExp(e.target.value)}
              className="text-xs w-full pl-10 pr-3 py-2 bg-emerald-100 border-0 rounded-md focus:ring-2 focus:ring-emerald-500 placeholder-[#049c64] font-medium"
            />
          </div>
          
        {/* Search By Location */}
        <div className="relative flex-grow w-[28%]">
          <div className="absolute inset-y-0 left-3 flex items-center">
            <MapPin className="h-5 w-5 text-[#049c64]" />
          </div>
          <input
            type="text"
            placeholder="Location"
            value={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
            className="text-xs w-full pl-10 pr-3 py-2 bg-emerald-100 border-0 rounded-md focus:ring-2 focus:ring-emerald-500 placeholder-[#049c64] font-medium"
          />
          </div>
        </div>

{/*
      <hr className="relative left-1/2 right-1/2 -mx-[49vw] w-[98vw] border-t-2 border-gray-200 mt-1" />
*/}
        {/* Search Results */}
        <div className="w-full flex justify-end text-gray-700 font-medium text-xs md:text-xs lg:text-sm xl:text-base whitespace-nowrap">
          {filteredJobs.length} Jobs Found
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row w-full gap-4 px-[5%] min-h-screen">
        {/* Left Panel */}
        <div
          className={`${
            isMobile ? "w-full" : "w-full"
          } flex-grow h-full overflow-hidden`}
        >
          <div className="sticky top-0 h-full">
            {/* Pass filteredJobs to LeftJobList */}
            <LeftJobList jobs={paginatedJobs} />
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex flex-col sm:flex-row items-center justify-between mt-6 mb-2 px-[5%] gap-4">
        <span className="text-xs text-gray-600">
          {`${(currentPage - 1) * jobsPerPage + 1} - ${
            Math.min(currentPage * jobsPerPage, filteredJobs.length)
          } of ${filteredJobs.length}`}
        </span>
        
        <div className="flex items-center">
          {/* Prev Button */}
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className={`px-3 py-1 mx-1 text-sm ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : "text-[#049c64] hover:bg-emerald-200"
            }`}
            disabled={currentPage === 1}
          >
            Prev
          </button>

          {/* Visible Pages */}
          {[...Array(totalPages).keys()]
            .map((page) => page + 1)
            .filter(
              (page) =>
                page >= currentPage &&
                page < currentPage + maxVisiblePages &&
                page <= totalPages
            )
            .map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`px-3 py-1 mx-1 text-sm ${
                  page === currentPage
                    ? "bg-[#049c64] text-white"
                    : "text-gray-600 hover:bg-emerald-200"
                }`}
              >
                {page}
              </button>
            ))}

          {/* Next Button */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className={`px-3 py-1 mx-1 text-sm ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : "text-[#049c64] hover:bg-emerald-200"
            }`}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Jobs;