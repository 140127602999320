import React, { useEffect, useRef } from 'react';
import { Code, Info } from 'lucide-react';

const TechnicalSkills = ({ editable, editData, profile, handleChange }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust height dynamically
    }
  }, [editable, editData.technicalSkills, profile.technicalSkills]); // Adjust height whenever the content or mode changes

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-lg'>
          <Code className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600 text-base'>Technical Skills</span>
          {/* Tooltip Icon */}
          <span className='group relative'>
            <Info className='w-4 h-4 text-gray-400 cursor-pointer' />
            <span className='absolute hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md w-64 -top-10 left-1/2 transform -translate-x-1/2 z-10'>
              Add your technical expertise and key skills such as programming languages, tools, frameworks, etc.
            </span>
          </span>
        </h3>
      </div>

      <div className='p-4 relative sm:p-4'>
        <textarea
          ref={textareaRef}
          name='technicalSkills'
          value={editable ? editData.technicalSkills || '' : profile.technicalSkills || ''}
          onChange={(e) => {
            if (e.target.value.length <= 200) {
              handleChange(e);
            }
          }}
          placeholder="Add your technical expertise and key skills such as programming languages, tools, frameworks, etc."
          maxLength={200} // Restrict input to 200 characters
          disabled={!editable}
          onInput={(e) => {
            e.target.style.height = 'auto'; // Reset height
            e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height dynamically
          }}
          className={`w-full font-medium text-xs p-1 border ${
            editable ? 'border-gray-300' : 'border-transparent'
          } bg-none placeholder-gray-400 resize-none`} // Ensure users cannot manually resize
          style={{ overflowY: 'hidden' }} // Hide scrollbars
        />
        {/* Character Counter */}
        <div className='text-right text-xs text-gray-500 mt-1'>
          {editable ? `${editData.technicalSkills?.length || 0}/200` : ''} {/* Show character count only when editable */}
        </div>
      </div>
    </div>
  );
};

export default TechnicalSkills;