// src/pages/talhunt/validateRecruiterToken.jsx

export const validateRecruiterToken = async () => {
  const token = localStorage.getItem('recruiterToken');
  
  // console.log('[DEBUG] Retrieved token from localStorage:', token);
  
  // Check if token or expiry is missing
  if (!token) {
    // console.warn('[WARNING] No token or expiry found in localStorage.');
    return { isValid: false, token: null };
  }
  try {
    // console.log('[DEBUG] Validating token with API:', `${process.env.REACT_APP_API_URL}/recruiter/validate-recruiter-token`);
      
    const response = await fetch(`${process.env.REACT_APP_API_URL}/recruiter/validate-recruiter-token`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // console.log('[DEBUG] Token validation response status:', response.status);
  
    if (response.ok) {
      console.log('[DEBUG] Token validation successful.');
      return { isValid: true, token };
    } else if (response.status === 401 || response.status === 403) {
      // console.warn('[WARNING] Token invalid or expired. Removing token from localStorage.');
      localStorage.removeItem('recruiterToken'); // Only remove for 401/403
      return { isValid: false, token: null };
    } else {
      // console.error('[ERROR] Unexpected error during token validation. Response status:', response.status);
      return { isValid: false, token }; // Don't remove token for other errors
    }
  } catch (error) {
    console.error('[ERROR] Exception during token validation:', error.message);
    return { isValid: false, token: null };
  }
};