// src/pages/userprofile/ProfileDetails.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// import visa from '../../Images/Visa.png';
// import Payment from '../../Images/paymnet.png';
import pen from '../../Images/pen.png';
import save from '../../Images/save.png';
// import Male from '../../Images/Male.png';
// import Female from '../../Images/Female.png';
import Unisex from '../../Images/Unisex.png';

import ProfileSummary from './components/ProfileDetails/ProfileSummary';
import PersonalDetails from './components/ProfileDetails/PersonalDetails';
import JobPreferences from './components/ProfileDetails/JobPreferences';
import TechnicalSkills from './components/ProfileDetails/TechnicalSkills';
import ProfessionalDetails from './components/ProfileDetails/ProfessionalDetails';
import EducationDetails from './components/ProfileDetails/EducationDetails';
import LocationDetails from './components/ProfileDetails/LocationDetails';
import LanguagesKnown from './components/ProfileDetails/LanguagesKnown';
import SocialProfiles from './components/ProfileDetails/SocialProfiles';

const ProfileDetails = () => {
  const [profile, setProfile] = useState({
    lastLoginTime: '',
      profileSummary: '',
      personalDetails: {
        firstName: '',
        lastName: '',
        gender: '',
        dob: '',
        maritalStatus: '',
        languagesKnown: {
          readSpeakWrite: '',
          readSpeakOnly: '',
          speakOnly: '',
        },
        permanentAddress: {
          address: '',
          city: '',
          state: '',
          country: 'India',
          zipCode: '',
          apartment: '',
        },
        currentAddress: {
          address: '',
          city: '',
          state: '',
          country: 'India',
          zipCode: '',
          apartment: '',
        },
        isCurrentAddressSame: false,
      },
      jobPreferences: {
        preferredRoles: '',
        willingnessToRelocate: false,
        preferredLocations: '',
        shiftPreference: '',
      },
      technicalSkills: '',
      professionalDetails: {
        overallExp: '0',
        noticePeriod: '',
        currentSalary: '',
        expectedSalary: '',
        experienceRecords: [],
      },
      educationDetails: {
        records: [],
        certifications: [],
      },
      socialProfiles: {},
    });
  
  const [editable, setEditable] = useState(false);
  const [editData, setEditData] = useState({
      ...profile,
      personalDetails: {
        ...profile.personalDetails,
        dob: profile.personalDetails.dob || '',
        age: profile.personalDetails.age || 'Not Available',
      },
      professionalDetails: {
        ...profile.professionalDetails,
        overallExp: profile.professionalDetails.overallExp || '0',
      },
    });

  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch Profile Data Function
      const fetchProfile = useCallback(async () => {
        const token = localStorage.getItem('userToken');
        // console.log('[DEBUG] Profile Details - Token sent in Authorization header:', token);
  
        if (!token) {
          setErrorMessages(['Your session has expired. Please log in again.']);
          setLoading(false);
          navigate('/login');
          return;
        }
  
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          
          if (!response.ok) {
            if (response.status === 401) {
              setErrorMessages(['Your session has expired. Please log in again.']);
              localStorage.removeItem('userToken');
              setLoading(false);
              navigate('/login');
              return;
            }
            const errorData = await response.json();
            setErrorMessages([errorData.message || 'Failed to fetch profile data.']);
            setLoading(false);
            return;
          }
  
          const data = await response.json();
          
        // Include age explicitly in the profile state
        const updatedProfile = {
          ...data.userProfile,
          personalDetails: {
            ...data.userProfile.personalDetails,
            age: data.userProfile.personalDetails.age || 'Not Available', // Ensure age is present
          },
        };

        // console.log('[INFO] Profile Details fetched successfully:', updatedProfile);

        setProfile(updatedProfile);

        // Ensure age is also included in editData
        setEditData({
          ...updatedProfile,
          personalDetails: {
            ...updatedProfile.personalDetails,
            age: updatedProfile.personalDetails.age || 'Not Available',
          },
        });

          setErrorMessages([]);
          setLoading(false);
        } catch (err) {
          console.error('[ERROR] Failed to fetch profile:', err.message);
          setErrorMessages([err.message || 'Failed to fetch profile.']);
          setLoading(false);
        }
      }, [navigate]);
  
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const validateInputs = (data) => {
    const errors = [];

    const validateField = (value, fieldName, errorMessage) => {
      // Explicitly check for undefined or null, allow 0 as a valid value
      if (value === undefined || value === null || value === '') {
        errors.push(`${fieldName}: ${errorMessage}`);
      }
    };
  
    // Validate First Name
    validateField(data.personalDetails.firstName, 'Profile Information', 'First Name required.');
    
    // Validate Last Name
    validateField(data.personalDetails.lastName, 'Profile Information', 'Last Name required.');

    // Validate DOB
    validateField(data.personalDetails.dob, 'Profile Information', 'Year of Birth is required to determine age.');
    if (data.personalDetails.dob) {
      // console.log('[DEBUG] Validating DOB:', data.personalDetails.dob); // Debug log
      const dobValue = data.personalDetails.dob;
    
      // Check if dob is a number (year) or a date string
      if (typeof dobValue === 'number') {
        const currentYear = new Date().getFullYear();
        if (dobValue > currentYear || currentYear - dobValue < 17) {
          errors.push('Profile Information: User should be at least 17 years old.');
        }
      } else {
        const dobDate = new Date(dobValue);
        if (isNaN(dobDate.getTime()) || dobDate > new Date()) {
          errors.push('Profile Information: User should be at least 17 years old.');
        }
      }
    }

    // Validate Overall Experience
    if (data.professionalDetails.overallExp === undefined || data.professionalDetails.overallExp === null) {
      errors.push('Professional Experience: Overall Experience must be valid or 0.');
    } else if (
      isNaN(Number(data.professionalDetails.overallExp)) ||
      Number(data.professionalDetails.overallExp) < 0
    ) {
      errors.push('Professional Experience: Overall Experience must be a valid number.');
    }

    // Validate Notice Period
    if (data.professionalDetails.noticePeriod === undefined || data.professionalDetails.noticePeriod === null) {
      errors.push('Professional Experience: Notice Period must be between 0 and 180 days.');
    } else if (
      isNaN(Number(data.professionalDetails.noticePeriod)) ||
      Number(data.professionalDetails.noticePeriod) < 0 ||
      Number(data.professionalDetails.noticePeriod) > 180
    ) {
      errors.push('Professional Experience: Notice Period must be between 0 and 180 days.');
    }

    if (data.professionalDetails.experienceRecords) {
      data.professionalDetails.experienceRecords.forEach((record, index) => {
        const prefix = `Professional Experience ${index + 1}`;
        validateField(record.jobTitle, prefix, 'Job Title is required.');
        validateField(record.employer, prefix, 'Employer is required.');
        validateField(record.startDate, prefix, 'Start Date (MM/YYYY) is required.');
        validateField(record.endDate, prefix, 'End Date (MM/YYYY or Till Date) is required.');
        validateField(record.role, prefix, 'Role is required.');
    
        // Optional: Validate Start/End Date format
        if (record.startDate && !/^\d{2}\/\d{4}$/.test(record.startDate)) {
          errors.push(`${prefix}: Start Date must be in MM/YYYY format.`);
        }
        if (
          record.endDate &&
          record.endDate !== 'Till Date' &&
          !/^\d{2}\/\d{4}$/.test(record.endDate)
        ) {
          errors.push(`${prefix}: End Date must be in MM/YYYY format or 'Till Date'.`);
        }
      });
    }

    // Validate Permanent Address City
    validateField(data.personalDetails.permanentAddress.city, 'Location', 'City required in Permanent Address.');

    // Validate Current Address if different
    if (!data.personalDetails.isCurrentAddressSame && !data.personalDetails.currentAddress.city) {
      validateField(data.personalDetails.currentAddress.city, 'Location', 'City required in Current Address.');
    }

    // Validate Education Details
    if (data.educationDetails.records) {
      data.educationDetails.records.forEach((record, index) => {
        validateField(record.highestQualification, `Education ${index + 1}`, 'Update Highest Qualification or Remove record.');
        validateField(record.specialization, `Education ${index + 1}`, 'Update Specialization or Remove record.');
        validateField(record.grade, `Education ${index + 1}`, 'Update Grade or Remove record.');
        validateField(record.university, `Education ${index + 1}`, 'Update University or Remove record.');
        validateField(record.graduationYear, `Education ${index + 1}`, 'Update Graduation Year or Remove record.');
        if (record.graduationYear && !/^\d{4}$/.test(record.graduationYear)) {
          errors.push(`Education ${index + 1}: Graduation Year must be a valid 4-digit year.`);
        }
      });
    }

    // Validate Certifications
    if (data.educationDetails.certifications) {
      data.educationDetails.certifications.forEach((cert, index) => {
        validateField(cert.name, `Certification ${index + 1}`, 'Update Certification Name or Remove record.');
        validateField(cert.issuedBy, `Certification ${index + 1}`, 'Update Issued By or Remove Record.');
        validateField(cert.issueYear, `Certification ${index + 1}`, 'Update Issue Year or Remove Record.');
        if (cert.issueYear && !/^\d{4}$/.test(cert.issueYear) && cert.issueYear !== 'N/A') {
          errors.push(`Certification ${index + 1}: Issue Year must be a valid 4-digit year or 'N/A'.`);
        }
      });
    }

    return errors;
  };

  const handleEditToggle = () => {
    if (!editable) {
      setEditData(profile);
    }
    setEditable(!editable);
    setErrorMessages([]);
  };

  const handleChange = (e) => {
    const { name, value, dataset } = e.target;
    const parentKey = dataset.parentKey; // For nested fields
    const arrayIndex = dataset.arrayIndex !== undefined ? parseInt(dataset.arrayIndex, 10) : null; // Ensure array index is parsed
  
    setEditData((prevState) => {
      // Create a deep copy of the state to avoid accidental references
      const updatedState = JSON.parse(JSON.stringify(prevState));
  
      if (parentKey) {
        const keys = parentKey.split('.'); // Split nested keys (e.g., 'personalDetails.currentAddress')
        let nestedObject = updatedState;

        // Traverse the object to get the target parent object
        keys.forEach((key, index) => {
          if (!nestedObject[key]) {
            nestedObject[key] = index === keys.length - 1 ? {} : {}; // Ensure the key exists in the structure
          }
          if (index < keys.length - 1) {
            nestedObject = nestedObject[key];
          }
        });

        if (parentKey === 'personalDetails.languagesKnown') {
          // Directly update the field within languagesKnown
          updatedState.personalDetails.languagesKnown = {
            ...updatedState.personalDetails.languagesKnown,
            [name]: value || '',
          };
        } else if (parentKey === 'socialProfiles') {
          // Merge the existing socialProfiles with the new value
          updatedState.socialProfiles = {
            ...updatedState.socialProfiles,
            [name]: value || '',
          };
        }
      
        // Special handling for `jobPreferences`
        else if (parentKey === 'jobPreferences') {
          // Special handling for `willingnessToRelocate` and `preferredLocations`
          if (name === 'willingnessToRelocate') {
            // Update willingnessToRelocate as a boolean
            nestedObject[name] = value === 'Yes';

            // If willingnessToRelocate is 'No', set preferredLocations to currentCity
            if (!nestedObject[name]) {
              nestedObject['preferredLocations'] = updatedState.personalDetails?.currentAddress?.city || '';
            }
          } else if (name === 'preferredLocations') {
            // Update preferredLocations only if willingnessToRelocate is true
            if (nestedObject['willingnessToRelocate']) {
              nestedObject[name] = value;
            }
          } else {
            // Default handling for other fields under `jobPreferences`
            nestedObject[name] = value || '';
          }
        } else if (arrayIndex !== null) {
          // Update specific array element
          if (!nestedObject[keys[keys.length - 1]][arrayIndex]) {
            nestedObject[keys[keys.length - 1]][arrayIndex] = {};
          }
          nestedObject[keys[keys.length - 1]][arrayIndex][name] = value || '';
        } else {
        // Update a regular nested field
        nestedObject[keys[keys.length - 1]][name] = value || '';
      } 
    } else if (name.includes('.')) {
      // Handle dot notation for custom roles
      const [roleKey, roleField] = name.split('.');
      updatedState.professionalDetails[roleKey] = {
        ...updatedState.professionalDetails[roleKey],
        [roleField]: value || '',
      };
    } else {
      // Handle regular top-level fields
      updatedState[name] = value || '';
    }
  
    // console.log('Updated State:', updatedState); // Debugging updated state
    return updatedState;
  });
  
    setErrorMessages([]); // Clear any previous errors
    // console.log('Current editData:', editData); // Debugging current editData
  };

  const handleSave = async () => {
    const errors = validateInputs(editData);
    // console.log('[DEBUG] Validation Errors:', errors);

    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    try {
    const token = localStorage.getItem('userToken');
    // console.log('[DEBUG] Token for save:', token);

    if (!token) {
      setErrorMessages(['Your session has expired. Please log in again.']);
      return;
    }

    // Log `editData` to capture the current state before sending
    // console.log('[DEBUG] Current editData:', JSON.stringify(editData, null, 2));

      // Process `dob` field
      const processedDob =
      typeof editData.personalDetails?.dob === 'number'
        ? editData.personalDetails.dob // Keep as year if it's a number
        : editData.personalDetails?.dob &&
          editData.personalDetails.dob !== '1970-01-01T00:00:00.000Z'
        ? new Date(editData.personalDetails.dob).toISOString()
        : null;    

      // Validate Overall Experience
      if (editData.professionalDetails?.overallExp) {
        const overallExp = parseFloat(editData.professionalDetails.overallExp);
        if (isNaN(overallExp) || overallExp < 0) {
          setErrorMessages(['Overall Experience must be a valid positive number or 0.']);
          return;
        }
      }
         
      // Prepare the payload
      const payload = {
        profileSummary: editData.profileSummary || '',
        personalDetails: {
          ...editData.personalDetails,
          dob: processedDob,
          languagesKnown: {
            readSpeakWrite: editData.personalDetails.languagesKnown?.readSpeakWrite || '',
            readSpeakOnly: editData.personalDetails.languagesKnown?.readSpeakOnly || '',
            speakOnly: editData.personalDetails.languagesKnown?.speakOnly || '',
          },
          permanentAddress: {
            ...editData.personalDetails.permanentAddress,
            country: editData.personalDetails.permanentAddress?.country || 'India',
          },
          currentAddress: {
            ...editData.personalDetails.currentAddress,
            country: editData.personalDetails.currentAddress?.country || 'India',
          },
        },
        jobPreferences: {
          ...editData.jobPreferences,
          willingnessToRelocate: Boolean(editData.jobPreferences?.willingnessToRelocate),
        },
        technicalSkills: editData.technicalSkills || '',
        professionalDetails: {
          ...editData.professionalDetails,
          overallExp: editData.professionalDetails?.overallExp || '0', // Ensure default value is '0'
        },
        educationDetails: {
          ...editData.educationDetails,
          records: editData.educationDetails?.records || [],
          certifications: editData.educationDetails?.certifications || [],
        },
        socialProfiles: {
          ...editData.socialProfiles,
        },
      };
      
      // Log payload to ensure it is structured as expected
      // console.log('[DEBUG] Payload for save:', JSON.stringify(payload, null, 2));

      // Make the API call to update the profile
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      // Log the response for debugging
      // console.log('[DEBUG] API Response:', response);

      if (!response.ok) {
        const errorResponse = await response.json();
        setErrorMessages([errorResponse.message || 'Failed to save changes.']);
        return;
      }

      const data = await response.json();
      // console.log('[INFO] Profile updated successfully:', data);

      // Update the local state with the new profile data
        setProfile(data.personalParticulars);
        setEditData(data.personalParticulars);
        setEditable(false);
        setErrorMessages([]); // Clear errors on successful save
      } catch (err) {
        console.error('[ERROR] Error updating profile:', err.message);
        setErrorMessages([err.message || 'Failed to save changes.']);
      }
  };
  
  if (loading) {
    return <div className='text-center mt-10'>Loading...</div>;
  }

  return (
    <div>
      {/* Profile Overview */}
      {/*<h1 className='text-xl font-bold text-gray-700'>Profile Details</h1>*/}
      {errorMessages.length > 0 && (
        <div className='bg-red-100 text-red-700 border border-red-400 rounded-md p-3 mb-4'>
          <ul>
            {errorMessages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        </div>
      )}
      <div className='p-4 border border-[#049c64] rounded-lg mb-4 sm:p-6 sm:mb-6 sm:flex sm:items-center sm:justify-between'>
        {/* First Part: Image and Name */}
        <div className='flex items-center gap-4 mb-4 sm:w-1/4 sm:mb-0'>
          <img
            src={Unisex}
            alt='Profile'
            className='w-10 h-10 rounded-full sm:w-12 sm:h-12'
          />
          <h2 className='text-sm font-semibold sm:text-sm'>
            {profile.personalDetails.firstName} {profile.personalDetails.lastName}
          </h2>
        </div>

        {/* Second Part: Overall Experience */}
        <div className='text-center sm:w-1/4'>
          <span className='font-medium'>Overall Experience</span>
            <p>{profile.professionalDetails.overallExp || '0'} Years</p>
        </div>

        {/* Third Part: Last Login */}
        <div className='text-center sm:w-1/4'>
          <span className='font-medium'>Last Login</span>
          <p>
            {profile.lastLoginTime
              ? (() => {
              const [datePart, timePart] = profile.lastLoginTime.split('T');
              const [year, month, day] = datePart.split('-');
              const [hour, minute, second] = timePart.split(':');
              const formattedTime = `${day}/${month}/${year}, ${
                +hour % 12 || 12
              }:${minute}:${second.substring(0, 2)} ${+hour >= 12 ? 'PM' : 'AM'}`;    
              return formattedTime;
            })()
            : 'Not Available'}
          </p>
        </div>

        {/* Final Part: Edit Button */}

          {editable && (
            <button
              className='flex items-center gap-2 px-4 py-2 bg-[#049c64] text-white rounded-md'
              onClick={handleSave}
            >
              <img src={save} alt='Save' className='w-2 h-2' />
              Save
            </button>
          )}

          <button
            className='flex items-center gap-2 px-4 py-2 bg-[#049c64] text-white rounded-md'
            onClick={handleEditToggle}
          >
            <img src={editable ? save : pen} alt={editable ? 'Save' : 'Edit'} className='w-2 h-2' />
            {editable ? 'Cancel' : 'Edit'}
          </button>
      </div>

      <ProfileSummary
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
      />
      <JobPreferences
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
        setEditData={setEditData}
      />
      <TechnicalSkills
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
        setEditData={setEditData}
      />
      <ProfessionalDetails
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
        setEditData={setEditData}
      />
      <EducationDetails
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
        setEditData={setEditData}
      />
      <SocialProfiles
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
        setEditData={setEditData}
      />
      <PersonalDetails
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
        setEditData={setEditData}
        setErrorMessages={setErrorMessages}
      />
      <LocationDetails
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
        setEditData={setEditData}
      />
      <LanguagesKnown
        editable={editable}
        editData={editData}
        profile={profile}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ProfileDetails;