// src/components/ProtectedRoute.jsx

import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AdminContext } from "../context/AdminContext";
import { UserContext } from "../context/UserContext";

const ProtectedRoute = ({ children, allowedRoles = [], isAdmin = false }) => {
  // console.log("[DEBUG] Rendering ProtectedRoute");
  const location = useLocation();
    
  // Parse query parameters only if needed for admin routes
  const queryParams = isAdmin ? new URLSearchParams(location.search) : null;
  const tokenFromQuery = isAdmin ? queryParams.get('token') : null;
  
  // Call both hooks unconditionally
  const adminContext = useContext(AdminContext);
  const userContext = useContext(UserContext);

  if (isAdmin) {
    // console.log("Admin Context:", adminContext);
  
    // Use AdminContext only when `isAdmin` is true
    if (!adminContext) {
      console.error("[ERROR] AdminContext is not provided. Ensure AdminProvider wraps admin routes.");
      return <Navigate to="/admin/login" />;
    }
  
    const { isLoggedIn, role, loading, token: adminToken } = adminContext;
    
    // Determine token for admin
    const token = tokenFromQuery || adminToken;
    // console.log("[DEBUG] Admin Token Received:", token);
    
    if (loading) return <div>Loading...</div>;
    
    if (!isLoggedIn || !token) {
    // console.warn("[WARN] Admin not logged in. Redirecting to login.");
      return <Navigate to="/admin/login" />;
    }
  
    // Check if admin has required roles
    if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
      console.warn("[WARN] Admin role not authorized. Redirecting to /admin/login.");
      return <Navigate to="/admin/login" />;
    }

    // console.log("[DEBUG] ProtectedRoute - Admin access granted.");
      return children;
    }
    
    // console.log("User Context:", userContext);
  
    if (!userContext) {
      // console.error("[ERROR] UserContext is not provided. Ensure UserProvider is wrapping the app.");
      return <Navigate to="/login" />;
    }

    const { isLoggedIn, role, loading } = userContext;
    
    if (loading) {
      // Show a loading spinner while the auth state is being determined
      return <div>Loading...</div>;
    }
  
    if (!isLoggedIn) {
      // console.warn("[WARN] ProtectedRoute - User is not authorized. Redirecting to /login.");
      return <Navigate to="/login" />;
    }
    
  // Check if the user role is authorized
  if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
    console.warn("[WARN] User role not authorized. Redirecting to /login.");
    return <Navigate to="/login" />;
  }
    
  // console.log("[DEBUG] ProtectedRoute - User access granted.");
  return children;
};

export default React.memo(ProtectedRoute);