// src/pages/Landingpage/LandingPage.jsx

// import { Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import frameImage from "../../Images/Frame.png";
//import backimgr from "../../Images/LRight.png";
import backimgr from "../../Images/RFrameNew.png";
// import frameImage2 from "../../Images/Vector.png";
import myImage from "../../Images/heroBackground.png";
import Header from "../../components/Header";
import "./LandingPage.css";
import Review from "./Review";
import StatsPanel from "./StatsPanel";
import Testimonial from "./Testimonial";
import WaveDesign from "./Wavedesign";

function LandingPage() {
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
  const navigate = useNavigate();
  const [rollingText, setRollingText] = useState("Hiring");
  
    useEffect(() => {
      const interval = setInterval(() => {
        setRollingText((prev) => (prev === "Hiring" ? "Job Search" : "Hiring"));
      }, 3000); // Switch every second
      return () => clearInterval(interval); // Cleanup on component unmount
    }, []);
  
  const handleClick = () => {
    navigate("/login");
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsHeaderScrolled(window.pageYOffset > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="min-h-screen">
      {/* SEO Meta Tags */}
            <Helmet>
              <title>Careers Central - Unlock Your Next Career Move</title>
              <meta
                name="description"
                content="Careers Central is India's finest job search platform. Discover tailored opportunities across top companies and industries to advance your career goals."
              />
              <meta
                name="keywords"
                content="Careers Central, job search, careers in India, top companies hiring, find jobs, career opportunities, job portal India"
              />
              <meta property="og:title" content="Careers Central - Unlock Your Next Career Move" />
              <meta
                property="og:description"
                content="Simplify your job search with Careers Central. Find job listings directly from top companies and industries."
              />
              <meta property="og:url" content="https://careerscentral.in" />
              <meta property="og:image" content="https://careerscentral.in/logo192.png" />
            </Helmet>
      
      {/* Header */}
      <Header isScrolled={isHeaderScrolled} />

      {/* Hero Section */}
      <section className="relative w-full overflow-hidden">
        {/* Background Image */}
        <div className="absolute top-0 left-0 w-full lg:w-[88%] h-[160vh] -z-10">  {/* Width is changed from 83% to 100%. Included lg to overwrite for large screens */}
          <img
            src={myImage}
            alt="Careers Central Hero Background"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Main Content */}
        <div className="container mx-auto pl-4 pt-[5%] pb-[15%] lg:pl-[8%] pr-[-5%]">
          <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-12 pl-[-2%] pr-[4%]">
            {/* Left Content */}
            <div className="w-full lg:w-1/2 relative">
              <img
                src={frameImage}
                alt="star"
                className="w-12 md:w-12 relative left-[65%] -top-10"
              />
              <img
                src={frameImage}
                alt="star"
                className="w-12 md:w-12 relative left-[90%] -top-10"
              />

              <img
                src={frameImage}
                alt="star"
                className="w-12 md:w-12 relative left-[80%] -top-5"
              />

              <div className="relative -mt-20">
                <h1 className="font-bold leading-tight mb-3">
                  <span className="block text-2xl sm:text-3xl md:text-4xl lg:text-4xl mb-2 lg:mb-2.5 xl-mb-3">
                    One Platform.
                  </span>
                  <span className="block text-2xl sm:text-3xl md:text-4xl lg:text-4xl mb-2 lg:mb-3 xl-mb-3">
                    Endless Opportunities.
                  </span>
                  <span className="block text-2xl sm:text-3xl md:text-4xl lg:text-4xl lg:mb-3 xl-mb-3">
                    Seamless{" "}
                    <span className="text-[#049c64] relative z-10">{rollingText}.</span>
                    </span>
                  </h1>
                  {/*
                  <img
                    src={frameImage2}
                    alt="shadow"
                    // className="absolute -bottom-1 -left-10 w-32 md:w-30 -z-10"
                    className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-32 md:w-30 -z-10"
                  />
                  */}
                </div>

                <p className="text-md text-gray-700 max-w-2xl mt-7">
                  India's Premium Platform For Tailored Hiring And Seamless Job Search Solutions.
                </p>
                <div className="pt-6 sm:pt-8">
                  <button
                    onClick={handleClick}
                    className="flex items-center space-x-3 bg-[#049c64] hover:bg-emerald-500 text-white px-8 py-2.5 rounded-full text-base transition-colors"
                  >
                    <span>Start Your Job Search</span>
                    <div className="flex items-center justify-center w-4 h-4 rounded-full border-2 border-white">
                      <svg
                        className="w-4 h-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
              
              {/* Right Content (Violet Box) */}
              <div
                className="w-full lg:w-1/3 xl:w-1/4 mg:w-1/5 bg-cover bg-center bg-no-repeat h-[280px] sm:h-[350px] shadow-lg rounded-2xl lg:ml-16 xl:ml-12"
                style={{
                  backgroundImage: `url(${backimgr})`,
                  marginTop: "20px",
                }}
              >
              <div className="h-full flex flex-col justify-end lg:pb-1.5 sm:p-4">
                <div className="sm:space-y-4">
                  {/* Header Content */}
                  <div className="text-center px-2">
                    <h2 className="text-lg lg:text-lg font-bold leading-tight">
                      Employers: Call To Action  
                    </h2>
                    <p className="text-center py-1 text-sm lg:text-md font-medium mt-4">
                      Partner with Careers Central to transform your hiring needs.
                    </p>
                    <p className="text-left py-1 text-gray-700 text-sm lg:text-md font-medium mt-4">
                      List your job openings, reach a broader audience, and streamline your 
                      recruitment process with Careers Central.
                    </p>
                  </div>
{/*
                  /* Search Form - Now in single row 
                  <div className="bg-[#fff9f7] rounded-lg shadow-md py-0.5 px-2 max-sm:m-1 flex items-center gap-1">
                    /* Search By Keyword 
                    <div className="flex items-center flex-1 min-w-0 sm:flex-[3]"> {/* Reduced flex proportion 
                      <Search className="w-3 h-3 sm:w-4 sm:h-4 text-gray-400 mr-1 flex-shrink-0" /> /* Slightly increased icon size 
                      <input
                        type="text"
                        placeholder="Search by Keywords"
                        className="w-full bg-transparent outline-none text-gray-600 text-xs sm:text-[6px] xl:text-[12px] placeholder:text-xxxl sm:placeholder:text-[12px]" // {/* Increased text size
                      />
                    </div>
*/}

                    <ul className="text-sm text-gray-700 mt-4 list-disc list-inside lg:text-left lg:pr-4  md:text-right md:pr-4  sm:text-right sm:pr-4  xs:text-right xs:pr-4">
                      <li><strong>Free Job Listings</strong></li>
                      <li><strong>1 Exclusive Recruiter Access</strong><span className="text-red-500">*</span></li>
                    </ul>
                    <div
                      className="flex items-center justify-center bg-cover bg-center bg-no-repeat rounded-2xl"
                      >
                    {/* Search Button */}
                    <button
                      onClick={() => window.open('https://careerscentral.in/documents/Brochure/Careers_Central_Brochure.pdf', '_blank')}
                      className="flex items-center space-x-3 bg-[#049c64] hover:bg-emerald-500 text-white px-8 py-2 mb-2 sm:mb-2 rounded-full text-base transition-colors"
                    >
                      <span>Open Brochure</span>
                      <div className="flex items-center justify-center w-4 h-4 rounded-full border-2 border-white">
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 7l5 5m0 0l-5 5m5-5H6"
                          />
                        </svg>
                      </div>
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Wave Design Section */}
      <div className="-mt-[19%] sm:-mt-[16%]">
        <WaveDesign />
      </div>

      {/* Stats Panel */}
      <div className="md:mt-1">
        <StatsPanel />
      </div>

      {/* Testimonial and Review Sections */}
      <Testimonial />
      <Review />
    </div>
  );
}

export default LandingPage;