// src/pages/talhunt/ProfileViewer.jsx

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { User, Clipboard, Briefcase, Book, Award, Globe, Share2 } from 'lucide-react';
// import Unisex from '../../Images/Unisex.png';
import { validateRecruiterToken } from './validateRecruiterToken';
import RecruiterLoginModal from './RecruiterLoginModal';

const ProfileViewer = () => {
  const [profile, setProfile] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isRecruiterLoggedIn, setIsRecruiterLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Use location to fetch the query parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const profileId = queryParams.get('profileId');
  
  const fetchProfile = async (id) => {
    const token = localStorage.getItem('recruiterToken'); // Fetch token from localStorage
    // console.log('[DEBUG] Token before sending:', token);

    // If no token, show login modal
    if (!token) {
      console.error('[ERROR] No token found for profile viewer.');
      setShowLoginModal(true); // Show login modal
      return;
    }

    try {
      setLoading(true);
      
      // console.log('[DEBUG] Fetching profile with ID:', id); // Log profile ID
      // console.log('[DEBUG] Token:', token); // Log the token
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/recruiter/profileViewer/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // console.log('[DEBUG] Fetch Response:', response); // Log the raw response

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          console.warn('[WARNING] Token expired or invalid. Prompting for login.');
          setShowLoginModal(true);
          return;
        }
        throw new Error(`Failed to fetch profile: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      // console.log('[DEBUG] Fetched Recruiter Profile:', data);
      setProfile(data);
      
      // Open profile in a new tab
      // const profileViewerUrl = `${window.location.origin}/recruiter-profiles/${id}`;
      // window.open(profileViewerUrl, '_blank'); // Open in a new tab
  } catch (err) {
      console.error('[ERROR] Error fetching profile:', err.message);
      // alert('Unable to view profile. Please try again later.');
      setError(err.message || 'Unable to view profile. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

useEffect(() => {
  const validateAndFetchProfile = async () => {
    const { isValid } = await validateRecruiterToken();

    if (!isValid) {
      console.warn('[WARNING] Token invalid or missing. Showing login modal.');
      setShowLoginModal(true);
      return;
    }

    setIsRecruiterLoggedIn(true);
    // console.log('[DEBUG] Validating Profile ID:', profileId);
    if (profileId) {
      // console.log('[DEBUG] Profile ID Passed:', profileId);
      fetchProfile(profileId);
    }
  };
  
  validateAndFetchProfile();
}, [profileId]);

  if (loading) {
    return <div className='text-center mt-10'>Loading profile...</div>;
  }

  if (error) {
    return <div className='text-red-500'>{error}</div>;
  }

  if (!profile) {
    return <div className='text-gray-500'>No profile data available.</div>;
  }
  
  // Handle Login Success
  const handleLoginSuccess = async () => {
    setShowLoginModal(false);
    // console.log('[DEBUG] Fetching Profile ID after login:', profileId);
    const { isValid } = await validateRecruiterToken();
    if (isValid) {
      setIsRecruiterLoggedIn(true);
      if (profileId) {
        fetchProfile(profileId);
      }
      // console.log('[DEBUG] Profile details fetched for:', profileId);
    } else {
      console.error('[ERROR] Token validation failed after login.');
    }
  };

  // Safe access to nested properties with default fallback
  const {
    profileSummary = {},
    jobPreferences = {},
    technicalSkills = '',
    professionalDetails = {},
    educationDetails = {},
    socialProfiles = {},
    personalDetails = {},
    email = '',
    mobile = '',
    lastLoginTime = '',
  } = profile;

  return (
    <div className='container mx-auto px-4 lg:px-8'>
      {/* Profile Display */}
      <div className='p-4 mt-20 border border-[#049c64] rounded-lg mb-4 sm:p-6 sm:mb-6 sm:flex sm:items-center sm:justify-between'>
        {/* Profile Header */}
        <div className='flex items-center gap-4 mb-4 sm:w-1/4 sm:mb-0'>
          <User className='w-10 h-10 rounded-full sm:w-12 sm:h-12' />
          <div>
          <h2 className='text-lg font-semibold sm:text-sm'>
            {isRecruiterLoggedIn
              ? `${personalDetails?.firstName || ''} ${personalDetails?.lastName || ''}`
            : 'Name Hidden'}
          </h2>
          <p className='text-sm text-gray-600'>
            {email || 'Contact Support Team'}
          </p>
          <p className='text-sm text-gray-600'>
            {mobile || 'Contact Support Team'}
          </p>
        <p className='text-sm text-gray-600'>
            <strong>Age:</strong> {personalDetails?.age || 'Not specified'}
          </p>
          <p className='text-sm text-gray-600'>
            <strong>Year of Birth:</strong> {personalDetails?.dob || 'Not specified'}
          </p>
          <p className='text-sm text-gray-600'>
            <strong>Marital Status:</strong> {personalDetails?.maritalStatus || 'Not specified'}
          </p>
          <p className='text-sm text-gray-600'>
            <strong>Last Login: </strong>
            {lastLoginTime
              ? new Date(lastLoginTime).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })
              : 'Not specified'}
          </p>
        </div>
      </div>
    </div>

    {/* Profile Summary */}
    <div className='p-4 border border-gray-300 rounded-lg mb-4'>
      <h3 className='flex items-center gap-2 text-lg font-semibold mb-2'>
        <Clipboard className='text-gray-600 w-5 h-5' />
          Profile Summary
        </h3>
      <p className='text-sm text-gray-700'>{profileSummary || 'No summary available.'}</p>
    </div>

    {/* Job Preferences */}
    <div className='p-4 border border-gray-300 rounded-lg mb-4'>
      <h3 className='flex items-center gap-2 text-lg font-semibold mb-2'>
        <Briefcase className='text-gray-600 w-5 h-5' />
        Job Preferences
      </h3>
      <div className='grid grid-cols-1 sm:grid-cols-[2fr_2fr_2fr_2fr_4fr] gap-4 sm:gap-6'>
      {/* Grouped Common Fields */}
      <div className='sm:col-span-4 grid grid-cols-1 sm:grid-cols-4 gap-4'>
        {/* Employment Type */}
        <div className='col-span-1 sm:col-span-1'>
          <h4 className='text-sm font-medium text-gray-600 mb-1'>
            <strong>Employment Type</strong>
          </h4>
          <p className='text-sm text-gray-700'>
            {jobPreferences?.employmentType || 'Open Jobs'}
          </p>
        </div>

        {/* Shift Preference */}
        <div className='col-span-1 sm:col-span-1'>
          <h4 className='text-sm font-medium text-gray-600 mb-1'>
            <strong>Shift Preference</strong>
          </h4>
          <p className='text-sm text-gray-700'>
            {jobPreferences?.shiftPreference || 'Not specified'}
          </p>
        </div>

        {/* Current City */}
        <div className='col-span-1 sm:col-span-1'>
          <h4 className='text-sm font-medium text-gray-600 mb-1'>
            <strong>Current City</strong>
          </h4>
          <p className='text-sm text-gray-700'>
            {personalDetails?.currentAddress?.city || 'Not specified'}
          </p>
        </div>

          {/* Willingness to Relocate */}
          <div className='col-span-1 sm:col-span-1'>
            <h4 className='text-sm font-medium text-gray-600 mb-1'>
              <strong>Willingness to Relocate</strong>
              </h4>
            <p className='text-sm text-gray-700'>
              {jobPreferences?.willingnessToRelocate ? 'Yes' : 'No'}
            </p>
            </div>
          </div>

          {/* Preferred Locations */}
          <div className='col-span-1 sm:col-span-1'>
            <h4 className='text-sm font-medium text-gray-600 mb-1'>
              <strong> Preferred Locations </strong>
            </h4>
            <p className='text-sm text-gray-700'>
              {jobPreferences?.preferredLocations || 'Not specified'}
            </p>
          </div>

          {/* Second Row: Preferred Roles */}
          <div className='col-span-1 sm:col-span-5'>
            <h4 className='text-sm font-medium text-gray-600 mb-1'>
              <strong> Preferred Roles </strong>
              </h4>
            <p className='text-sm text-gray-700'>
              {jobPreferences?.preferredRoles || 'Not specified'}
            </p>
            </div>
          </div>
        </div>

      {/* Technical Skills */}
      <div className='p-4 border border-gray-300 rounded-lg mb-4'>
        <h3 className='flex items-center gap-2 text-lg font-semibold mb-2'>
          <Clipboard className='text-gray-600 w-5 h-5' />
          Technical Skills
        </h3>
        <p className='text-sm text-gray-700'>{technicalSkills || 'No technical skills specified.'}</p>
      </div>
        
      {/* Professional Experience */}
      <div className='p-4 border border-gray-300 rounded-lg mb-4'>
      <h3 className='flex items-center gap-2 text-lg font-semibold mb-4'>
        <Award className='text-gray-600 w-5 h-5' />
        Professional Experience
      </h3>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6'>
          <div>
            <p className='text-xs text-gray-600 mb-1'>
              <strong> Overall Exp. (In Years) </strong>
            </p>
            <p className='text-sm text-gray-700'>{professionalDetails.overallExp || 'Not specified'}</p>
          </div>
          <div>
            <p className='text-xs text-gray-600 mb-1'>
              <strong> Notice Period (In Days) </strong>
            </p>
            <p className='text-sm text-gray-700'>{professionalDetails.noticePeriod || 'Not specified'}</p>
          </div>
          <div>
            <p className='text-xs text-gray-600 mb-1'>
              <strong> Current Salary (Annual) </strong>
            </p>
            <p className='text-sm text-gray-700'>
              {new Intl.NumberFormat('en-IN').format(professionalDetails.currentSalary || 0)}
            </p>
          </div>
          <div>
            <p className='text-xs text-gray-600 mb-1'>
              <strong> Expected Salary (Annual) </strong>
            </p>
            <p className='text-sm text-gray-700'>
              {new Intl.NumberFormat('en-IN').format(professionalDetails.expectedSalary || 0)}
            </p>
          </div>
        </div>

        {/* Custom Roles */}
        <div className='mt-6'>
          <h4 className='text-sm font-medium text-gray-800 mb-2'>
            <strong> Custom Roles </strong>
          </h4>
          <div className='grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4 sm:gap-6'>
            {['customRole1', 'customRole2', 'customRole3'].map((role, index) => (
              <div key={role} className='border p-3 rounded-md'>
                <p className='text-xs text-gray-600 mb-1'>
                  <strong> Role {index + 1} </strong>
                </p>
                <p className='text-sm text-gray-700'>
                  {professionalDetails[role]?.roleName || 'Not specified'}
                </p>
                <p className='text-sm text-gray-700'>
                  Experience: {professionalDetails[role]?.years || 0} Years
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Experience Records */}
        <div className='mt-6'>
          <h4 className='text-sm font-medium text-gray-800 mb-2'>
            <strong> Work Experience </strong>
          </h4>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {professionalDetails.experienceRecords?.length > 0 ? (
            professionalDetails.experienceRecords.map((record, index) => (
              <div key={index} className='border p-3 rounded-md mb-4'>
                <h4 className='text-sm font-medium text-gray-800 mb-2'>
                  <strong> Work Experience {index + 1} </strong>
                </h4>
                <p className='text-sm text-gray-700'>
                  <strong>Job Title:</strong> {record.jobTitle || 'Not specified'}
                </p>
                <p className='text-sm text-gray-700'>
                  <strong>Employer:</strong> {record.employer || 'Not specified'}
                </p>
                <p className='text-sm text-gray-700'>
                  <strong>Start Date:</strong> {record.startDate || 'Not specified'}
                </p>
                <p className='text-sm text-gray-700'>
                  <strong>End Date:</strong> {record.endDate || 'Not specified'}
                </p>
                <p className='text-sm text-gray-700'>
                  <strong>Role:</strong> {record.role || 'Not specified'}
                </p>
              </div>
            ))
          ) : (
            <p className='text-sm text-gray-600 mt-3'>
              No professional experience records found.
            </p>
          )}
        </div>
        </div>
      </div>

      {/* Education Details */}
      <div className='p-4 border border-gray-300 rounded-lg mb-4'>
        <h3 className='flex items-center gap-2 text-lg font-semibold mb-2'>
          <Book className='text-gray-600 w-5 h-5' />
          Education Details
        </h3>
        <div className='grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-4 gap-4'>
        {/* Education Records */}
        {educationDetails?.records?.length > 0 ? (
          educationDetails.records.map((record, index) => (
            <div key={index} className='border border-gray-200 p-3 mt-2 mb-2 rounded-md lg:col-span-2'>
              <h4 className='text-sm font-medium text-gray-800 mb-2'>
                <strong> Education {index + 1} </strong>
              </h4>
              <p className='text-sm text-gray-700'>
                <strong>Qualification:</strong> {record.highestQualification || 'Not specified'}
              </p>
              <p className='text-sm text-gray-700'>
                <strong>Specialization:</strong> {record.specialization || 'Not specified'}
              </p>
              <p className='text-sm text-gray-700'>
                <strong>Grade:</strong> {record.grade || 'Not specified'}
              </p>
              <p className='text-sm text-gray-700'>
                <strong>University:</strong> {record.university || 'Not specified'}
              </p>
              <p className='text-sm text-gray-700'>
                <strong>Graduation Year:</strong> {record.graduationYear || 'Not specified'}
              </p>
            </div>
          ))
        ) : (
          <p className='text-sm text-gray-700 lg:col-span-4'>No education records available.</p>
        )}

        {/* Certifications */}
        {educationDetails?.certifications?.length > 0 ? (
          educationDetails.certifications.map((cert, index) => (
            <div key={index} className='border border-gray-200 p-3 mt-2 mb-2 rounded-md lg:col-span-2'>
              <h4 className='text-sm font-medium text-gray-800 mb-2'>
                <strong> Certification {index + 1} </strong>
              </h4>
              <p className='text-sm text-gray-700'>
                <strong>Course:</strong> {cert.name || 'Not specified'}
              </p>
              <p className='text-sm text-gray-700'>
                <strong>Issued By:</strong> {cert.issuedBy || 'Not specified'}
              </p>
              <p className='text-sm text-gray-700'>
                <strong>Issue Year:</strong> {cert.issueYear || 'Not specified'}
              </p>
            </div>
          ))
        ) : (
          <p className='text-sm text-gray-700 lg:col-span-4'>No certifications available.</p>
        )}
        </div>
      </div>

      {/* Social Profiles */}
      <div className='p-4 border border-gray-300 rounded-lg mb-4'>
        <h3 className='flex items-center gap-2 text-lg font-semibold mb-2'>
          <Share2 className='text-gray-600 w-5 h-5' />
          Social Profiles
        </h3>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {Object.entries(socialProfiles).length > 0 ? (
            Object.entries(socialProfiles).map(([key, value]) => (
              <div key={key} className='border p-3 rounded-md'>
                <h4 className='text-sm font-medium text-gray-800 mb-2'>
                  <strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong>
                </h4>
                <a
                  href={value || '#'}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-600 hover:underline break-all text-sm'
                >
                  {value || 'No URL provided'}
                </a>
              </div>
            ))
          ) : (
            <p className='text-sm text-gray-500'>
              No social profiles added. Please update your profile.
            </p>
          )}
        </div>
      </div>

      {/* Languages Known */}
      <div className='p-4 border border-gray-300 rounded-lg mb-4'>
        <h3 className='flex items-center gap-2 text-lg font-semibold mb-2'>
          <Globe className='text-gray-600 w-5 h-5' />
          Languages Known
        </h3>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {['readSpeakWrite', 'readSpeakOnly', 'speakOnly'].map((key) => (
            <div key={key}>
              <h4 className='text-sm font-medium text-gray-600 mb-1'>
              <strong>
                {key === 'readSpeakWrite'
                  ? 'Read, Speak, and Write'
                  : key === 'readSpeakOnly'
                  ? 'Read and Speak Only'
                  : 'Speak Only'}
                </strong>
              </h4>
              <p className='text-sm text-gray-700'>
                {personalDetails?.languagesKnown?.[key] || 'Not specified'}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Recruiter Login Modal */}
      {showLoginModal && (
        <RecruiterLoginModal
          onClose={() => setShowLoginModal(false)}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
    </div>
  );
};

export default ProfileViewer;