// src/pages/talhunt/utils/profileUtils.js

// Helper function to calculate age
export const calculateAge = (dob) => {
    console.log('[DEBUG] DOB Value:', dob);
    if (!dob) return 'N/A'; // Handle missing `dob`

    const today = new Date();
    if (typeof dob === 'number') {
      return today.getFullYear() - dob;
    } else {
      const dobDate = new Date(dob);
      if (isNaN(dobDate.getTime())) return 'Invalid DOB'; // Check if it's a valid date

      let age = today.getFullYear() - dobDate.getFullYear();
      const monthDiff = today.getMonth() - dobDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
        age--;
      }
      return age;
    }
  };
  
  // Function to transform profiles
  export const transformProfiles = (data) =>
    data.map((profile) => ({
      id: profile._id || 'N/A',
      name: `${profile.personalDetails?.firstName || ''} ${profile.personalDetails?.lastName || ''}`.trim(),
      age: calculateAge(profile.personalDetails?.dob),
      noticePeriod: profile.professionalDetails?.noticePeriod || 0,
      education: profile.educationConcat || 'No education details available',
      // certifications: profile.educationConcat || 'No certifications available',
      profileSummary: profile.profileSummary || 'No summary available',
      technicalSkills: profile.technicalSkills || 'No skills available',
      overallExperience: profile.professionalDetails?.overallExp || 0,
      preferredLocations: profile.jobPreferences?.preferredLocations || 'No preferred location',
      employmentType: profile.jobPreferences?.employmentType || 'Open jobs',
    }));