import React from 'react';
import { Award, Info } from 'lucide-react';

const ProfessionalExperience = ({ editable, editData, profile, handleChange, setEditData }) => {
  const handleAddExperience = () => {
    if ((editData.professionalDetails.experienceRecords || []).length >= 3) return;
    setEditData((prevState) => {
      const updatedRecords = [...(prevState.professionalDetails.experienceRecords || [])];
      updatedRecords.push({
        jobTitle: '',
        employer: '',
        startDate: '',
        endDate: '',
        role: '',
      });

      return {
        ...prevState,
        professionalDetails: {
          ...prevState.professionalDetails,
          experienceRecords: updatedRecords,
        },
      };
    });
  };

  const handleRemoveExperience = (index) => {
    const updatedRecords = [...editData.professionalDetails.experienceRecords];
    updatedRecords.splice(index, 1); // Remove record at the current index
    setEditData((prevState) => ({
      ...prevState,
      professionalDetails: { ...prevState.professionalDetails, experienceRecords: updatedRecords },
    }));
  };

  return (
    <div className='mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3'>
      <div className='flex items-center justify-between'>
        <h3 className='flex items-center gap-2 text-base font-medium sm:text-base'>
          <Award className='text-gray-600 w-5 h-5' />
          <span className='text-gray-600'>Professional Experience</span>
        </h3>
        {editable && (
          <button
            onClick={handleAddExperience}
            className={`px-3 py-1 text-xs rounded-md ${
              (editData.professionalDetails.experienceRecords || []).length >= 3
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-[#049c64] text-white'
            }`}
            disabled={(editData.professionalDetails.experienceRecords || []).length >= 3}
          >
            Add Experience
          </button>
        )}
      </div>

      <div className='p-4 relative sm:p-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6'>
          {/* Overall Experience */}
          <div>
            <p className='text-xs text-gray-600 mb-1'>
              Overall Exp. (In Years) <span className='text-red-500'> *</span>
            </p>
            <input
              type='number'
              name='overallExp'
              data-parent-key='professionalDetails'
              value={
                editable
                ? editData.professionalDetails?.overallExp !== undefined
                ? editData.professionalDetails.overallExp
                : ''
              : profile.professionalDetails?.overallExp !== undefined
              ? profile.professionalDetails.overallExp
              : ''
              }
              onChange={handleChange}
              disabled={!editable}
              placeholder='Enter Overall Experience or 0'
              min='0'
              className={`font-medium text-xs p-1 border ${
                editable ? 'border-gray-300' : 'border-transparent'
              } bg-none placeholder-gray-400 w-full`}
            />
          </div>

          {/* Notice Period */}
          <div>
            <p className='text-xs text-gray-600 mb-1'>
              Notice Period (In Days) <span className='text-red-500'> *</span>
            </p>
            <input
              type='number'
              name='noticePeriod'
              data-parent-key='professionalDetails'
              value={
                editable
                ? editData.professionalDetails?.noticePeriod !== undefined
                ? editData.professionalDetails.noticePeriod
                : ''
              : profile.professionalDetails?.noticePeriod !== undefined
              ? profile.professionalDetails.noticePeriod
              : ''
              }
              onChange={handleChange}
              disabled={!editable}
              placeholder='Enter Notice Period'
              min='0'
              className={`font-medium text-xs p-1 border ${
                editable ? 'border-gray-300' : 'border-transparent'
              } bg-none placeholder-gray-400 w-full`}
            />
          </div>

          {/* Current Salary */}
          <div>
            <p className='text-xs text-gray-600 mb-1'>Current Salary (Annual)</p>
            {editable ? (
            <input
              type='number'
              name='currentSalary'
              data-parent-key='professionalDetails'
              value={
                editable
                ? editData.professionalDetails?.currentSalary !== undefined
                  ? editData.professionalDetails?.currentSalary 
                  : ''
                : profile.professionalDetails?.currentSalary !== undefined
                  ? profile.professionalDetails?.currentSalary 
                  : 0
              }
              onChange={handleChange}
              disabled={!editable}
              placeholder='Enter Current Salary'
              min='0'
              className={`font-medium text-xs p-1 border ${
                editable ? 'border-gray-300' : 'border-transparent'
              } bg-none placeholder-gray-400 w-full`}
            />
            ) : (
              <p className='font-medium text-xs'>
                {new Intl.NumberFormat('en-IN').format(profile.professionalDetails?.currentSalary || 0)}
              </p>
            )}
          </div>

          {/* Expected Salary */}
          <div>
            <p className='text-xs text-gray-600 mb-1'>Expected Salary (Annual)</p>
            {editable ? (
            <input
              type='number'
              name='expectedSalary'
              data-parent-key='professionalDetails'
              value={
                editable
                  ? editData.professionalDetails?.expectedSalary !== undefined
                    ? editData.professionalDetails?.expectedSalary 
                    : ''
                  : profile.professionalDetails?.expectedSalary !== undefined
                    ? profile.professionalDetails?.expectedSalary 
                    : ''
              }
              onChange={handleChange}
              disabled={!editable}
              placeholder='Enter Expected Salary'
              min='0'
              className={`font-medium text-xs p-1 border ${
                editable ? 'border-gray-300' : 'border-transparent'
              } bg-none placeholder-gray-400 w-full`}
            />
          ) : (
            <p className='font-medium text-xs'>
              {new Intl.NumberFormat('en-IN').format(profile.professionalDetails?.expectedSalary || 0)}
            </p>
          )}
          </div>
        </div>
        
        {/* Custom Roles Section */}
        <div className='mt-6 relative'>
          <div className='flex items-center justify-between'>
            <h4 className='text-sm font-medium text-gray-800 mb-2 flex items-center gap-2'>
              Custom Roles
            {/* Tooltip Icon */}
            <span className='group relative'>
              <Info className='w-4 h-4 text-gray-400 cursor-pointer' />
              <span
                className='absolute hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md w-64 -top-10 left-1/2 transform -translate-x-1/2 z-10'
              >
                For example, if you have worked as a Manager for 2 years, Team Leader for 3 years, and Developer for 5 years, you can specify these roles separately. Mention "Manager" in the Role field and "2" in the Exp. (In Years) field.
              </span>
            </span>
            </h4>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4 sm:gap-6'>
          {['customRole1', 'customRole2', 'customRole3'].map((role, index) => (
            <div key={role} className='flex flex-col sm:flex-row items-start sm:items-center gap-2 border p-3'>
              {/* Role Name */}
              <div className='w-full sm:w-2/3'>
                <p className='text-xs text-gray-600 mb-1'>Role {index + 1}</p>
                <input
                  type='text'
                  name={`${role}.roleName`}
                  placeholder={`(e.g., Developer ${index + 1})`}
                  value={editData.professionalDetails?.[role]?.roleName || ''}
                  onChange={handleChange}
                  disabled={!editable}
                  className={`font-medium text-xs p-1 border ${
                    editable ? 'border-gray-300' : 'border-transparent'
                  } bg-none placeholder-gray-400 w-full`}
                />
              </div>
              
              {/* Years of Experience */}
              <div className='w-full sm:w-1/3'>
                <p className='text-xs text-gray-600 mb-1'>Exp. (In Years)</p>
                <input
                  type='number'
                  name={`${role}.years`}
                  placeholder='Years (e.g., 3)'
                  min='0'
                  value={editData.professionalDetails?.[role]?.years || ''}
                  onChange={handleChange}
                  disabled={!editable}
                  className={`font-medium text-xs p-1 border ${
                    editable ? 'border-gray-300' : 'border-transparent'
                  } bg-none placeholder-gray-400 w-full`}
                />
              </div>
            </div>
          ))}
        </div>
        </div>
        
        {/* Experience Records */}
        {editData.professionalDetails?.experienceRecords?.length > 0 ? (
          editData.professionalDetails.experienceRecords.map((record, index) => (
            <div key={index} className='border border-gray-200 p-3 mt-2 mb-2'>
              <h4 className='text-sm font-medium text-gray-800 mb-2'>
                Work Experience {index + 1}
              </h4>
              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6'>
                {/* Map through fields dynamically */}
                {[
                  { label: 'Job Title', name: 'jobTitle' },
                  { label: 'Employer', name: 'employer' },
                  { label: 'Start Date (MM/YYYY)', name: 'startDate' },
                  { 
                    label: index === 0 
                      ? 'End Date (MM/YYYY or Till Date)'
                      : 'End Date (MM/YYYY)',
                    name: 'endDate',
                    placeholder: index === 0 ? 'MM/YYYY or Till Date' : 'MM/YYYY',
                  },
                ].map(({ label, name, placeholder }) => (
                  <div key={name} className='col-span-1'>
                    <p className='text-xs text-gray-600 mb-1'>
                      {label} <span className='text-red-500'> *</span>
                    </p>
                    <input
                      type='text'
                      name={name}
                      data-parent-key='professionalDetails.experienceRecords'
                      data-array-index={index}
                      value={
                        name === 'endDate' && index === 0 && record[name] === 'Till Date'
                          ? 'Till Date'
                          : record[name] || ''
                        }
                      onChange={handleChange}
                      disabled={!editable}
                      placeholder={placeholder || `Enter ${label}`}
                      className={`font-medium text-xs p-1 border ${
                        editable ? 'border-gray-300' : 'border-transparent'
                      } bg-none placeholder-gray-400 w-full`}
                    />
                  </div>
                ))}
              </div>

              {/* Role */}
              <div className='col-span-1 sm:col-span-4 mt-3'>
                <p className='text-xs text-gray-600 mb-1'>
                  Role <span className='text-red-500'> *</span>
                </p>
                <textarea
                  name='role'
                  data-parent-key='professionalDetails.experienceRecords'
                  data-array-index={index}
                  value={record.role || ''}
                  onChange={(e) => {
                    if (e.target.value.length <= 300) {
                      handleChange(e);
                    }
                  }}
                  placeholder='Enter Role (Max 300 characters)'
                  disabled={!editable}
                  onInput={(e) => {
                    e.target.style.height = 'auto'; // Reset height to auto
                    e.target.style.height = `${e.target.scrollHeight}px`; // Set height dynamically based on content
                  }}
                  className={`font-medium text-xs p-2 border ${
                    editable ? 'border-gray-300' : 'border-transparent'
                  } bg-none placeholder-gray-400 w-full resize-none`}
                />
                {editable && (
                    <p className='text-right text-xs text-gray-500 mt-1'>
                      {`${record.role?.length || 0}/300`}
                    </p>
                  )}
              </div>

              {/* Remove Experience */}
              {editable && (
                <div className='mt-3 text-right'>
                  <button
                    onClick={() => handleRemoveExperience(index)}
                    className='text-xs text-red-600 hover:text-red-800'
                  >
                    Remove Record
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className='text-sm text-gray-600 mt-3'>
            No professional experience records found. Add up to 3 most recent work experiences.
          </p>
        )}
      </div>
    </div>
  );
};

export default ProfessionalExperience;