// src/pages/jobs/LeftJobList.jsx

import { Bookmark, Briefcase, MapPin, MessageSquare } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../Images/Salary.png";
import ImageCalender from "../../Images/Screenshot_5.png";
import refreshToken from "../../api/refreshApi";

const LeftJobList = ({ jobs, source }) => {
  // State to manage "Read More" toggle for each job
  const [expandedJobs, setExpandedJobs] = useState({});
  // State to track bookmarked jobs
  const [bookmarkedJobs, setBookmarkedJobs] = useState({});
  const navigate = useNavigate();

    // Fetch already bookmarked jobs with token refresh logic
    const fetchBookmarkedJobs = async () => {
      let token = localStorage.getItem("userToken");
      if (!token) return;

      try {
        let response = await fetch(`${process.env.REACT_APP_API_URL}/user/savedJobs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Handle token expiration
      if (response.status === 401) {
        token = await refreshToken();
        if (token) {
          localStorage.setItem("userToken", token);
          response = await fetch(`${process.env.REACT_APP_API_URL}/user/savedJobs`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }
      }

      if (response.ok) {
        const data = await response.json();
        const bookmarked = {};
        data.bookmarkedJobs.forEach((job) => {
          bookmarked[job.ccid] = true;
        });
        setBookmarkedJobs(bookmarked);
      } else {
        throw new Error("Failed to fetch bookmarked jobs");
      }
      } catch (error) {
        console.error("Error fetching bookmarked jobs:", error);
      }
    };

  // Call fetchBookmarkedJobs on component mount
  useEffect(() => {
    fetchBookmarkedJobs();
  }, []);

  const toggleExpand = (jobId) => {
    // Prevent expansion for saved jobs
    if (source === "savedJobs") return;
    setExpandedJobs((prev) => ({
      ...prev,
      [jobId]: !prev[jobId],
    }));
  };

  // Handle bookmark action
  const handleBookmark = async (e, job) => {
    e.stopPropagation(); // Prevent triggering "read more" action
    const token = localStorage.getItem("userToken");

    if (!token) {
      alert("Please log in to bookmark jobs.");
      navigate("/login"); // Redirect to login page if not logged in
      return;
    }

    try {
      if (bookmarkedJobs[job.ccid]) {
        // Remove the job if already bookmarked
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/savedJobs/${job.ccid}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to remove bookmark.");
        }
  
        // Update the state to remove the job from bookmarked jobs
        setBookmarkedJobs((prev) => {
          const updatedBookmarks = { ...prev };
          delete updatedBookmarks[job.ccid];
          return updatedBookmarks;
        });
      } else {
        // Add the job if not already bookmarked
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/savedJobs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ccid: job.ccid }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to bookmark job.");
      }

      // alert("Job bookmarked successfully!");
      // Update the state to add the job to bookmarked jobs
      setBookmarkedJobs((prev) => ({ ...prev, [job.ccid]: true }));
      }
    } catch (error) {
      console.error("Error updating bookmark:", error);
      alert(error.message || "Failed to update bookmark.");
    }
  };

  // Function to format numbers in Indian numbering format with rupee symbol
  const formatSalary = (amount) => {
    if (amount === "NS" || isNaN(amount)) return "NS";
    return `₹${new Intl.NumberFormat("en-IN").format(amount)}`;
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-h-[calc(90vh)] lg:max-h-[calc(95vh)] xl:max-h-[calc(95vh)] md:max-h-[calc(86vh)] sm:max-h-[calc(80vh)] overflow-y-auto border rounded-lg custom-scrollbar">
      {/* Style for custom scrollbar */}
      <style>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 4px;
        }
        
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #049c64;
          border-radius: 4px;
          opacity: 0.7;
        }
        
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #037c50;
        }
  
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #979797 #f1f1f1;
        }
      `}</style>

      {/* Conditional Rendering for No Jobs */}
      {jobs.length === 0 ? (
        <div className="text-gray-500 text-center py-4">No jobs found</div>
      ) : (
      // {/* Map over the jobs to display them */}
      jobs.map((job, index) => (
        <div
          key={`${job.id}-${index}`} // Composite key to ensure uniqueness
          className={`bg-[#fff9f7] border border-gray-200 border-solid px-2 py-2 cursor-pointer hover:bg-[#f1fff9] hover:border-l-4 hover:border-l-[#049c64] relative group ${
            expandedJobs[job.id] ? "max-h-none" : "overflow-hidden"
        }`}
          onClick={() => toggleExpand(job.id)} // Toggle expand on card click
        >
          {/* Job Title and Company */}
          <div className="flex justify-between items-start">
            <div className="space-y-0.5">
              <h3 className="font-medium text-sm text-gray-900">
                {job.title}
              </h3>
              <p className="font-medium text-xxs text-gray-600">
                {job.company}
              </p>
            </div>
            <div className="flex flex-col items-end gap-1.5">
              <button 
                className={`${
                  bookmarkedJobs[job.ccid] ? "text-green-500" : "text-gray-400"
                } hover:text-[#049c64]`}
                onClick={(e) => handleBookmark(e, job)}
              >
                {bookmarkedJobs[job.ccid] ? (
                  <Bookmark
                    className="h-3.5 w-3.5 sm:h-4 sm:w-4 md:h-3.5 md:w-3.5"
                    fill="#049c64" // Green fill for bookmarked jobs
                    stroke="#049c64"
                  />
                ) : (
                  <Bookmark
                    className="h-3.5 w-3.5 sm:h-4 sm:w-4 md:h-3.5 md:w-3.5"
                    fill="none" // No fill for unbookmarked jobs
                    stroke="currentColor"
                  />
                )}
              </button>
              <span className="px-2 py-0.5 text-xxxs text-white bg-[#049c64] rounded-md">
                {job.department}
              </span>
            </div>
          </div>

          {/* Job Details */}
          <div className="mt-2 sm:mt-3 md:mt-2 space-y-2">
            <div className="flex items-center text-xs gap-3 text-[#979797]">
              <div className="flex items-center gap-1">
                <Briefcase className="h-3 w-3 sm:h-3.5 sm:w-3.5 md:h-3 md:w-3" />
                <span className="text-xxs">{job.experience}</span>
              </div>
              <div className="flex items-center gap-1">
                <MapPin className="h-3 w-3 sm:h-3.5 sm:w-3.5 md:h-3 md:w-3" />
                <span className="text-xxs">{job.location}</span>
              </div>
            </div>

            <div className="mt-2 flex items-start gap-1">
                <MessageSquare className="h-3 w-3 sm:h-3.5 sm:w-3.5 md:h-3 md:w-3 mt-0.5 flex-shrink-0 text-[#979797]" />
                <div 
                  className={`text-xxs flex justify-between w-full ${
                    expandedJobs[job.id] ? "" : "line-clamp-2"
                    }`}
                    >
                  {/* Display either truncated or full text */}
                  <p 
                    className={`${
                      expandedJobs[job.id] ? "" : "line-clamp-2"
                    } overflow-hidden text-ellipsis`}
                  >
                    {job.summary}
                    </p>
                </div>
            </div>

            <div className="flex items-center justify-between mt-2">
            <div className="flex items-center gap-1">
              <img
                src={UserIcon}
                alt="User Icon"
                className="h-2.5 w-2.5 sm:h-4 sm:w-4 md:h-3.5 md:w-3.5"
              />
              <span className="text-xs text-gray-600">
                {formatSalary(job.minSalary)} - {formatSalary(job.maxSalary)}
              </span>
            </div>
            <a
              href={job.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#049c64] hover:underline text-xs font-medium"
              onClick={(e) => e.stopPropagation()} // Prevent card toggle on "View Job" click
            >
              View Job
            </a>
            </div>
          </div>

          {/* Job Metadata */}
          <div className="mt-2 pt-2 border-t border-gray-200">
            <div className="flex justify-between text-[10px]">
              <div className="flex gap-1">
                <span className="text-xxs text-[#979797]">
                  Job ID: #{job.id}
                </span>
                <span className="mx-0.5 text-[#979797]">|</span>
                <span className="text-xxs text-[#979797]">
                  CCID: #{job.ccid}
                </span>
              </div>
            </div>
            <div className="flex justify-between items-center w-full text-[10px] md:text-[9px] lg:text-[9px] mt-2">
              <div className="flex items-center gap-1">
                <span>Posted On: {job.postedOn}</span>
              </div>

              <div className="flex items-center gap-1">
                <span>CC Updated On</span>
                <div className="flex items-center gap-1 px-1.5 py-0.5 rounded text-[#049c64] border border-[#049c64] border-solid lg:text-[9px]">
                  <img
                    src={ImageCalender}
                    alt="icon"
                    className="h-3 w-3 md:h-2.5 md:w-2.5"
                  />
                  <span>{job.ccUpdateDate}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        ))
      )}
    </div>
  );
};

export default LeftJobList;