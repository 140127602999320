// src/context/AdminContext.jsx

import React, { createContext, useState, useCallback, useMemo } from "react";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [adminState, setAdminState] = useState(() => {
    const token = localStorage.getItem("adminToken");
    return {
      isLoggedIn: !!token,
      token,
      loading: false,
    };
  });

  const adminLogin = useCallback((token) => {
    localStorage.setItem("adminToken", token);
    setAdminState({ isLoggedIn: true, token, loading: false });
  }, []);

  const adminLogout = useCallback(() => {
    localStorage.removeItem("adminToken");
    setAdminState({ isLoggedIn: false, token: null, loading: false });
  }, []);

  const contextValue = useMemo(
    () => ({ ...adminState, adminLogin, adminLogout }),
    [adminState, adminLogin, adminLogout]
  );

  return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
};